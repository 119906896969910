import React from 'react'
import Button from '@mui/material/Button'
import CustomTabs from '../Tabs/CustomTabs'
import ModelSelection from './ModelSelection'
import Comments from './Comments'
import axios from 'axios'
import EnvSettings, { ReviewTask } from './EnvSettings'
import DataDisplay from './DataDisplay'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { withStyles } from '@material-ui/core/styles'
import { apiConfig } from './Lib/config'
import { showNotification } from '../../app/Notifications/NotificationActionCreator'
import { connect } from 'react-redux'
import { ArrowDownward } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'
const styles = (theme) => ({
  root: {
    border: '1px solid lightgray',
    padding: '0 0px 15px 15px',
  },
  tabContainer: {
    marginTop: 10,
  },
  tabContent: {
    maxHeight: 'Calc(100vh - 230px)',
    overflowY: 'auto',
    paddingRight: 15,
  },
  circle: {
    color: '#fff',
  },
})

const tabs = [
  {
    label: 'Asset',
    id: 'asset',
    value: 0,
  },
  {
    label: 'Comments',
    id: 'comments',
    value: 1,
  },
]

class ViewerSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentTab: 0, btnDownload: false }
  }

  updateTab = (currentTab) => {
    this.setState({ currentTab })
  }

  downloadFiles = async () => {
    this.setState({ btnDownload: true })
    if (this.props.data && this.props.data.variationDetails) {
      const zip = new JSZip()
      const downloadPromises = Object.keys(
        this.props.data.variationDetails.mid
      ).map(async (item) => {
        try {
          const response = await axios.get(
            apiConfig.threeDViewer.downloadFiles +
              this.props.data.variationDetails.mid[item].file_url +
              '?key=' +
              apiConfig.apiKey,
            { responseType: 'blob' }
          )

          if (response.status === 200) {
            const blob = new Blob([response.data])
            zip.file(item, blob) // Add file to zip
            this.setState({ btnDownload: false })
          } else {
            throw new Error('Failed to download file')
          }
        } catch (error) {
          console.error('Error in downloading file:', error)
        }
      })

      await Promise.all(downloadPromises)

      try {
        const zipped = await zip.generateAsync({ type: 'blob' })
        saveAs(zipped, `${this.props.tcin}.zip`) // Make sure saveAs is defined, possibly using FileSaver.js
      } catch (error) {
        console.error('Error in zipping files:', error)
        this.setState({ btnDownload: false })
      }
    }
  }

  downloadBlob(name, blob) {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = name
    a.click()
  }
  render() {
    const {
      classes,
      projectId,
      taskId,
      tcin,
      rtaStatus,
      updateTcinList,
      phaseId,
      serviceType,
    } = this.props

    const { currentTab } = this.state
    return (
      <div className={classes.root}>
        <CustomTabs updateTab={this.updateTab} value={currentTab} tabs={tabs} />
        <div className={classes.tabContainer}>
          <div className={classes.tabContent}>
            {currentTab === 0 ? (
              <>
                <ModelSelection {...this.props} />
                {serviceType === 'Create&License3DImage' && (
                  <Button
                    onClick={this.downloadFiles}
                    sx={{
                      marginTop: '1rem',
                      backgroundColor: 'rgb(204, 0, 0)',
                      color: '#fff',
                    }}
                    startIcon={<ArrowDownward />}
                    variant="contained"
                  >
                    {this.state.btnDownload ? (
                      <span>
                        Download Asset{' '}
                        <CircularProgress
                          size={24}
                          className={classes.circle}
                        />{' '}
                      </span>
                    ) : (
                      'Download Asset'
                    )}
                  </Button>
                )}
                {rtaStatus === 'AWAITING_REVIEW' ||
                rtaStatus.trim() === 'Awaiting review' ? (
                  <ReviewTask
                    projectId={projectId}
                    taskId={taskId}
                    tcin={tcin}
                    updateTcinList={updateTcinList}
                    phaseId={phaseId}
                  />
                ) : (
                  ''
                )}
                <EnvSettings {...this.props} />
                {this.props.data !== undefined ? (
                  <DataDisplay {...this.props} />
                ) : null}
              </>
            ) : null}
            {currentTab === 1 ? <Comments {...this.props} /> : null}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  showNotification,
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ViewerSidebar))
