import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AuthProvider, useAuth } from '@praxis/component-auth'
import SignInPrompt from './app/SignInPrompt/SignInPrompt'
import Layout from './app/Layout/Layout'
import muiTheme from './config/themeConfig'
import apiConfig from './config/apiConfig'
import { useEffect } from 'react'
import { getRedirectUrl } from './helpers/utils'
import { authConfig } from './constants/config'

const host = window.location.hostname
const isTPlus = host.includes(apiConfig?.auth_tplus?.appUrl)

const Main = () => {
  const auth = useAuth()
  const { isAuthorized, login, logout, session } = auth

  return (
    <>
      {isAuthorized() ? (
        <Layout
          logout={logout}
          session={session}
          isAuthorized={isAuthorized}
          login={login}
        />
      ) : (
        <Switch>
          <Route
            render={(props) => <SignInPrompt {...props} login={login} />}
          />
        </Switch>
      )}
    </>
  )
}

const App = () => {
  const params = new URLSearchParams(window?.location?.search)
  const redirectUrl = params.get('redirectUrl')

  useEffect(() => {
    if (redirectUrl) {
      localStorage.setItem('redirectUrl', redirectUrl)
    }
  }, [redirectUrl])

  let config = { ...authConfig }
  config.authorizationUrl = isTPlus
    ? apiConfig.auth_tplus.host + apiConfig.auth_tplus.authorizationPath
    : apiConfig.auth.host + apiConfig.auth.authorizationPath
  config.logoutUrl = isTPlus
    ? redirectUrl || getRedirectUrl() || apiConfig.auth_tplus.tplusUrl
    : `${apiConfig.auth.logoutHost}${apiConfig.auth.logoutPath}`

  return (
    <MuiThemeProvider theme={muiTheme}>
      <AuthProvider {...config}>
        <Router>
          <Main />
        </Router>
      </AuthProvider>
    </MuiThemeProvider>
  )
}
export default App
