import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { ArrowBack, ArrowForward } from '@material-ui/icons'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FBXViewer from './fbxViewer'
import ViewerSidebar from '../Sidebar'
import Asset from '../Lib/Asset'
import { setOldVariationFormat } from '../Lib/download'
import GltfViewer from './GltfViewer'
import Axios from 'axios'
import { apiConfig } from '../Lib/config'
import { isTPlus } from '../../../app/Auth/Auth'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

class Viewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGrid: true,
      showPivot: true,
      assets: [],
      currentAsset: { model: {} },
      inInches: false,
      dimensions: true,
      wireframe: false,
      light: true,
      data: undefined,
      assetMetaData: {},
      materialList: [],
      update: 0,
      comments: [],
      annotationMode: false,
      activeComment: '',
      autoFocus: true,
      newHotspot: undefined,
      activeHotspot: undefined,
      removeHotspot: undefined,
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tcin !== nextProps.tcin) {
      this.getData(nextProps.tcin)
    }
  }

  getData = (nextTcin) => {
    const { tcin: originalTcin } = this.props
    const tcin = nextTcin || originalTcin
    Axios.get(
      apiConfig.threeDViewer.assets +
        '/' +
        tcin +
        `?is_target_plus_vendor=${isTPlus}`
    ).then((resp) => {
      // if (!resp.data) return false
      const data = resp.data
      const table = setOldVariationFormat(
        data.variations || 0,
        data.variationDetails || 0
      )
      if (Object.keys(table).length === 0) {
        return
      }

      const assets = Asset.ParseUrlTable(table)
      let assetMetaData = {}
      if (data.variationMetadataMap) {
        assetMetaData = { ...data.variationMetadataMap }
      }
      this.setState(
        { data: data, assets, inInches: data.inInches, assetMetaData },
        () => {
          this.setCurrentAsset(assets[0].model.name)
        }
      )
    })
  }

  setCurrentAsset = (modelName) => {
    const { assets } = this.state
    const asset = assets.find((a) => a.model.name === modelName)
    if (!asset) return

    this.setState({ currentAsset: asset }, () => {
      this.onAssetLoaded()
    })
  }

  onAssetLoaded = (materials = []) => {
    const { currentAsset, assetMetaData } = this.state
    const relativePath = currentAsset.model.relativePath
    let variation = relativePath.split('/').pop() || 'low'
    let threeData =
      assetMetaData && assetMetaData[variation]
        ? assetMetaData[variation].threejs
        : []
    let materialList = []
    if (threeData.length) {
      materialList = threeData.map((material) => {
        let alphaCutoff =
          material.alpha_mode === 'BLEND' || material.alpha_mode === 'OPAQUE'
            ? null
            : material.alpha_mode === 'MASK' && material.alpha_cutoff !== null
            ? String(material.alpha_cutoff)
            : '0.1'
        this.setState({ materialList })

        return {
          name: material.material_name,
          type: material.alpha_mode,
          doubleSided: material.double_sided,
          meshNames: material.mesh_names,
          alphaCutoff,
        }
      })
    } else {
      materialList = materials
    }
    this.setState({ materialList })
  }

  toggleGrid = (val) => {
    this.setState({ showGrid: val })
  }

  togglePivot = (val) => {
    this.setState({ showPivot: val })
  }
  toggleDimensions = (val) => {
    this.setState({ dimensions: val })
  }
  toggleWireframe = (val) => {
    this.setState({ wireframe: val })
  }
  changeLight = (light) => {
    this.setState({ light })
  }

  onMaterialChange = (type, value, name, index) => {
    const { materialList, update } = this.state
    let newData = [...materialList]

    if (type === 'mode') {
      newData[index].type = value
    }
    if (type === 'cutoff') {
      newData[index].alphaCutoff = value
    }
    if (type === 'side') {
      newData[index].doubleSided = value
    }
    this.setState({ materialList: newData, update: update + 1 })
  }

  setComments = (comments) => {
    this.setState({ comments })
  }
  selectComment = (id) => {
    this.setState({ activeComment: id })
  }
  onNewHotspot = (value) => {
    this.setState({ newHotspot: value })
  }
  selectHotspot = (value, autofocus) => {
    this.setState({ activeHotspot: value, autoFocus: autofocus })
  }
  cancelHotspot = (removeHotspot) => {
    this.setState({ removeHotspot })
  }
  setAnnotationMode = (value) => {
    this.setState({ annotationMode: value })
  }
  render() {
    const {
      handleClose,
      open,
      moveLeft,
      moveRight,
      tcin,
      projectId,
      taskId,
      rtaStatus = '',
      updateTcinList = () => {},
      leftLoading,
      rightLoading,
      phaseId,
      serviceType,
    } = this.props
    const {
      showGrid,
      showPivot,
      currentAsset,
      data,
      inInches,
      assets,
      dimensions,
      wireframe,
      light,
      materialList,
      update,
      comments,
      annotationMode,
      activeComment,
      autoFocus,
      newHotspot,
      activeHotspot,
      removeHotspot,
    } = this.state

    const modelName = currentAsset.model.name
    let isFbx = modelName ? modelName.toLowerCase().includes('fbx') : false
    let isGltf = modelName
      ? modelName.toLowerCase().includes('gltf') ||
        modelName.toLowerCase().includes('glb')
      : false

    return (
      <>
        {
          <div>
            <Dialog open={open} maxWidth={'xl'} fullWidth>
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                3D Asset Model ({tcin})
              </DialogTitle>

              <DialogContent dividers>
                {moveLeft && moveRight ? (
                  <>
                    {leftLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <ArrowBack
                        style={{ cursor: 'pointer' }}
                        onClick={moveLeft}
                      />
                    )}
                    <Box style={{ float: 'right', cursor: 'pointer' }}>
                      {rightLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ArrowForward onClick={moveRight} />
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}

                <Grid container justify="space-around" spacing={3}>
                  <Grid item xs={9}>
                    {isFbx ? (
                      <FBXViewer
                        inInches={inInches}
                        asset={currentAsset}
                        showgrid={showGrid}
                        showpivot={showPivot}
                        dimensions={dimensions}
                        wireframe={wireframe}
                        onAssetLoaded={this.onAssetLoaded}
                        materialList={materialList}
                        update={update}
                      />
                    ) : null}
                    {isGltf ? (
                      <GltfViewer
                        inInches={inInches}
                        asset={currentAsset}
                        showgrid={showGrid}
                        showpivot={showPivot}
                        dimensions={dimensions}
                        wireframe={wireframe}
                        light={light}
                        onAssetLoaded={this.onAssetLoaded}
                        materialList={materialList}
                        update={update}
                        comments={comments}
                        annotationMode={annotationMode}
                        activeComment={activeComment}
                        selectComment={this.selectComment}
                        autoFocus={autoFocus}
                        onNewHotspot={this.onNewHotspot}
                        activeHotspot={activeHotspot}
                        selectHotspot={this.selectHotspot}
                        removeHotspot={removeHotspot}
                      />
                    ) : null}
                    {!isFbx && !isGltf ? <div>No assets found</div> : null}
                  </Grid>
                  <Grid item xs={3}>
                    <ViewerSidebar
                      tcin={tcin}
                      serviceType={serviceType}
                      data={data}
                      assets={assets}
                      toggleGrid={this.toggleGrid}
                      changeAsset={this.setCurrentAsset}
                      togglePivot={this.togglePivot}
                      toggleDimensions={this.toggleDimensions}
                      toggleWireframe={this.toggleWireframe}
                      changeLight={this.changeLight}
                      materialList={materialList}
                      onMaterialChange={this.onMaterialChange}
                      setComments={this.setComments}
                      annotationMode={annotationMode}
                      setAnnotationMode={this.setAnnotationMode}
                      activeComment={activeComment}
                      newHotspot={newHotspot}
                      autoFocus={autoFocus}
                      selectHotspot={this.selectHotspot}
                      cancelHotspot={this.cancelHotspot}
                      projectId={projectId}
                      taskId={taskId}
                      rtaStatus={rtaStatus}
                      updateTcinList={updateTcinList}
                      phaseId={phaseId}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
        }
      </>
    )
  }
}

export default Viewer
