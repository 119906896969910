import React from 'react'
import { Typography, withStyles, Button } from '@material-ui/core'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

const style = (theme) => ({
  root: {
    flexDirection: 'column',
  },
  image: {
    textAlign: 'left',
    marginTop: '1rem',
    width: '100%',
  },
  fileName: {
    wordBreak: 'break-word',
    textAlign: 'left',
  },
  highlight: {
    color: '#333',
    textDecoration: 'none',
    transitionColor: '0.125s ease-in-out',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      color: '#cc0000',
      cursor: 'pointer',
    },
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '1rem',
    marginTop: '0.5rem',
  },
  sectionHeader: {
    fontWeight: 500,
    padding: 6,
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
})

class QRCodes extends React.Component {
  state = {
    fileList: [],
    showQRCode: false,
    qrcode: new window.QRious({ size: 300 }),
    codeText: '',
  }

  componentDidMount() {
    setTimeout(() => this.populateFiles(), 100)
  }

  populateFiles() {
    let files = []

    Object.entries(this.props.data).forEach(([i, det]) => {
      files.push({ name: i, assets: [], type: 'header' })
      Object.entries(det).forEach(([name, url]) => {
        files.map((file) =>
          file && file.name === i
            ? file.assets.push({ type: 'file', name, url, variation: i })
            : file
        )
      })
    })
    let formattedFiles = files
    if (files.length === 1) {
      formattedFiles[0].assets = files[0].assets.sort((a, b) =>
        a.name === b.name ? 0 : a.name < b.name ? -1 : 1
      )
    } else {
      formattedFiles = files.sort((a, b) => {
        a.assets.sort((c, d) =>
          c.name === d.name ? 0 : c.name < d.name ? -1 : 1
        )
        b.assets.sort((c, d) =>
          c.name === d.name ? 0 : c.name < d.name ? -1 : 1
        )
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1
      })
    }
    this.setState({ fileList: formattedFiles })
  }

  showCodeView(prefix, url) {
    this.setState({ showQRCode: true, codeText: prefix + url })
  }

  hideCodeView() {
    this.setState({ showQRCode: false })
  }

  generateimage() {
    const { qrcode, codeText } = this.state
    qrcode.value = codeText
    return qrcode.toDataURL()
  }

  render() {
    const { classes } = this.props
    const { fileList, showQRCode } = this.state

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>QR Codes</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {showQRCode ? (
            <div className={classes.qrContainer}>
              <img src={this.generateimage()} alt="QRCode" />
              <div className={classes.closeButton}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={(e) => this.hideCodeView(e)}
                >
                  Close
                </Button>
              </div>
            </div>
          ) : (
            fileList.length > 0 &&
            fileList.map((file) => (
              <>
                {file.assets.map(
                  (asset) =>
                    asset.url.vault_url != null &&
                    asset.url.vault_url !== '' &&
                    (asset.name.endsWith('gltf') ||
                      asset.name.endsWith('glb') ||
                      asset.name.endsWith('usdz')) && (
                      <>
                        <Typography
                          align="center"
                          variant="subtitle1"
                          className={classes.sectionHeader}
                        >
                          {asset.name}
                        </Typography>
                        <div className={classes.fileContainer}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href="#"
                            onClick={() =>
                              this.showCodeView(
                                'target://seeitonyou?model_url=',
                                asset.url.vault_url
                              )
                            }
                            className={classNames(
                              classes.highlight,
                              classes.fileName
                            )}
                          >
                            See It On You (Android App, iOS13 App-New)
                          </a>
                        </div>

                        <div className={classes.fileContainer}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href="#"
                            onClick={() =>
                              this.showCodeView(
                                'target://seeitonyou?reality_url=',
                                asset.url.vault_url
                              )
                            }
                            className={classNames(
                              classes.highlight,
                              classes.fileName
                            )}
                          >
                            See It On You (iOS13 App-Old)
                          </a>
                        </div>

                        <div className={classes.fileContainer}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href="#"
                            onClick={() =>
                              this.showCodeView(
                                'target://ar?model_url=',
                                asset.url.vault_url
                              )
                            }
                            className={classNames(
                              classes.highlight,
                              classes.fileName
                            )}
                          >
                            See It In Your Space (Android App, iOS App)
                          </a>
                        </div>

                        <div className={classes.fileContainer}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href="#"
                            onClick={() =>
                              this.showCodeView(
                                'https://arvr.google.com/scene-viewer/1.0?file=',
                                asset.url.vault_url
                              )
                            }
                            className={classNames(
                              classes.highlight,
                              classes.fileName
                            )}
                          >
                            Android Scene Viewer
                          </a>
                        </div>

                        <div className={classes.fileContainer}>
                          {/* eslint-disable-next-line*/}
                          <a
                            href="#"
                            onClick={() =>
                              this.showCodeView('', asset.url.vault_url)
                            }
                            className={classNames(
                              classes.highlight,
                              classes.fileName
                            )}
                          >
                            Direct Link (For AR Quick Look, Webpage)
                          </a>
                        </div>
                      </>
                    )
                )}
              </>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(style)(QRCodes)
