/* eslint-disable*/
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '6px 16px 6px 4px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridColumnGap: '8px',
    gridTemplateColumns: '43% 26% 26%',
    alignItems: 'center',
    marginBottom: 12,
  },
  matName: {
    fontSize: 13,
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  select: {
    marginBottom: 4,
    '& .MuiSelect-outlined': {
      paddingRight: 12,
    },
    '& .MuiSelect-iconOutlined': {
      right: 2,
      top: 'calc(50% - 10px)',
    },
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 12,
    alignItems: 'center',
  },
  slider: {
    flex: 5,
  },
  cutoffValue: {
    flex: 1,
    minWidth: 25,
  },
}))

const MaterialSettings = ({
  alphaCutoff,
  type,
  doubleSided,
  name,
  index,
  onMaterialChange,
  ...props
}) => {
  const classes = useStyles()
  const changeAlphaMode = (e) => {
    const value = e.target.value
    onMaterialChange('mode', value, name, index)
  }
  const changeSide = (e) => {
    const value = e.target.value

    onMaterialChange('side', value === 'doubleSided', name, index)
  }
  const changeCutoff = (e, value) => {
    onMaterialChange('cutoff', value, name, index)
  }
  const sliderValue = alphaCutoff == null ? 0.0 : alphaCutoff

  return (
    <div className={classes.root}>
      <div className={classes.matName} title={name}>
        {name}
      </div>
      <Select
        className={classes.select}
        native
        value={type}
        inputProps={{
          name: 'transparency',
        }}
        input={<BootstrapInput />}
        variant="outlined"
        onChange={changeAlphaMode}
      >
        <option value="OPAQUE">Opaque</option>
        <option value="BLEND">Blend</option>
        <option value="MASK">Mask</option>
      </Select>

      {type === 'MASK' ? (
        <>
          <div className={classes.sliderContainer}>
            <Slider
              min={0.0}
              step={0.01}
              max={1}
              value={sliderValue}
              valueLabelDisplay="auto"
              aria-labelledby="continuous-slider"
              onChange={changeCutoff}
              className={classes.slider}
            />
            <span className={classes.cutoffValue}>{sliderValue}</span>
          </div>
          <div> </div>
        </>
      ) : null}

      <Select
        native
        value={doubleSided === true ? 'doubleSided' : 'frontSided'}
        inputProps={{
          name: 'sided',
        }}
        input={<BootstrapInput />}
        variant="outlined"
        className={classes.select}
        onChange={changeSide}
      >
        <option value="doubleSided">DoubleSide</option>
        <option value="frontSided">FrontSide</option>
      </Select>
    </div>
  )
}

export default MaterialSettings
