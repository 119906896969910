import {
  Typography,
  makeStyles,
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core'
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F1F1F1',
  borderRadius: '4px',
}

// Project Details Style
export const useStyles = makeStyles(() => ({
  backGroundBox: {
    backgroundColor: '#fff',
  },
  headerWrapper: { marginTop: '125px' },
  header: {
    boxShadow: 'none !important',
    height: '90px !important',
    marginTop: '165px !important',
    borderBottom: '0.5px solid #B3B3B3 !important',
    display: 'flex',
    justifyContent: 'center',
  },
  taskBarBox: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: '0px 4rem',
    color: 'black',
  },
  addTcinDetails: {
    color: '#fff',
    background: '#CC0000',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '14px',
    borderRadius: '8px',
    padding: '0.4rem',
    '&:hover': {
      color: '#fff',
      background: '#CC0000',
    },
    '&:disabled': {
      backgroundColor: '#dbdbdb',
    },
  },
  uploadAssets: {
    borderRadius: '4px',
  },
  taskContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  taskActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  actionButtons: {
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLabel: {
    fontWight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#888888',
  },
  headerDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerValue: {
    color: '#000000',
    marginLeft: '10px',
  },
  headerDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: '750px',
  },
}))

//Used in Tcin Details Modal
export const TypoReuse = ({ heading, subHeading }) => {
  return (
    <Typography style={{ marginBottom: '16px' }}>
      <span
        style={{
          fontWeight: '400',
          fontSize: '14px',
          color: '#888888',
          marginBottom: '16px',
          marginRight: '1rem',
        }}
      >
        {heading} :
      </span>{' '}
      {subHeading}
    </Typography>
  )
}

//circle progress with label
export const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export const NBrMonthStatusCard = ({
  xs,
  title,
  value,
  classHeader,
  classValue,
}) => {
  return (
    <Grid xs={xs} item>
      <p className={classHeader}>{title}</p>
      <p className={classValue}>{value}</p>
    </Grid>
  )
}

//Bar
export const BarChartBar = ({ title, value, qVal, height, classHeader }) => {
  return (
    <tr className="qtr" id={`q${qVal}`}>
      <th scope="row">{title}</th>
      <td className="paid bar" style={{ height: height }}>
        <p className={classHeader}>{value}</p>
      </td>
    </tr>
  )
}
