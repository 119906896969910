import React from 'react'
import { withStyles } from '@material-ui/core'
import { copyToClipboardNew } from '../Lib/copy'

const styles = (theme) => ({
  detail: {
    margin: 10,
    cursor: 'pointer',
    transition: 'color 0.125s ease-in-out',
    '&:hover': {
      color: '#CC0000',
    },
  },
  specs: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    flexWrap: 'wrap',
  },
})

class Specs extends React.Component {
  onCopyClick = () => {
    const {
      data: { tcin },
    } = this.props

    copyToClipboardNew(tcin)
  }
  onPdpClick = () => {
    const {
      data: { tcin },
    } = this.props
    window.open(`https://www.target.com/p/-/A-${tcin}#lnk=sametab`)
  }
  onViewInRoomClick = () => {
    const {
      data: { tcin },
    } = this.props
    window.open(
      `https://www.target.com/c/view-in-room/-/N-x39s9?tir=${tcin}#lnk=sametab`
    )
  }
  render() {
    const { isQA = false, data, classes } = this.props
    return (
      <>
        {!isQA && (
          <div>
            <span onClick={this.onCopyClick} className={classes.detail}>
              {data.tcin}
            </span>
            <span>/</span>
            <span onClick={this.onPdpClick} className={classes.detail}>
              target.com
            </span>
            <span>/</span>
            <span onClick={this.onViewInRoomClick} className={classes.detail}>
              view-in-room
            </span>
          </div>
        )}
      </>
    )
  }
}
export default withStyles(styles)(Specs)
