import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const style = (theme) => ({
  root: {
    flexDirection: 'column',
  },
  highlight: {
    color: '#cc0000',
  },
})

class Files extends React.Component {
  sortFeatures(features = []) {
    const { data } = this.props
    if (features) {
      const featureListNew = features
      const tcinObj = [
        {
          name: 'Tcin',
          value: data.tcin,
        },
      ]
      features = tcinObj.concat(
        featureListNew.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      )
    }
    return features
  }
  render() {
    const { classes, data } = this.props
    const formatedFeaturesList = this.sortFeatures(data.features)
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Features</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {formatedFeaturesList.map((feature) => (
            <div>
              <div
                className={
                  feature.name.toLowerCase().includes('dimension')
                    ? classes.highlight
                    : ''
                }
              >
                {feature.name}
              </div>
              <div style={{ marginLeft: '1rem' }}>{feature.value}</div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(style)(Files)
