import { useReducer, createContext } from 'react'
import {
  TASK_FILE_STATUS,
  REMOVE_FILE,
  INIT_TASK_FILE_STATUS,
} from './TaskActionType'
import { cloneDeep } from 'lodash'

export const TaskContext = createContext()

const initialState = {
  taskFileStatus: {},
}

const reducer = (state = initialState, action) => {
  // let { taskListData } = action.payload
  const { tcin, fileStatesList } = action.payload
  const newTaskFileStatus = cloneDeep(state.taskFileStatus)
  switch (action.type) {
    case INIT_TASK_FILE_STATUS:
      return {
        ...state,
        taskFileStatus: action.payload,
      }

    case TASK_FILE_STATUS:
      newTaskFileStatus[tcin] = {
        ...newTaskFileStatus[tcin],
        ...fileStatesList,
      }
      return {
        ...state,
        taskFileStatus: newTaskFileStatus,
      }
    case REMOVE_FILE:
      try {
        delete newTaskFileStatus[action.payload.tcin][action.payload.key]
        return {
          ...state,
          taskFileStatus: newTaskFileStatus,
        }
      } catch (e) {
        return { ...state }
      }
    default:
      return state
  }
}

export const TaskContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <TaskContext.Provider value={[state, dispatch]}>
      {props.children}
    </TaskContext.Provider>
  )
}
