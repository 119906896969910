import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProject } from '../store/NationalBrand/ActionCreator'

// to get paginated item in correct page a
function usePagination(data, loader, itemsPerPage) {
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const maxPage =
    !loader &&
    nbrVendorConfirm.message !== 'error in login' &&
    Math.ceil(data[0]?.total_count / itemsPerPage)

  //call back data
  function currentData(page) {
    if (
      nbrVendorConfirm &&
      nbrVendorConfirm.message !== 'error in login' &&
      nbrVendorConfirm.vendor_id
    ) {
      let payload = {
        count: itemsPerPage,
        page: page,
        projectType: 'Non Tcin',
        sortBy: 'project_create_date',
        sortOrder: 'desc',
        vendorId: nbrVendorConfirm.vendor_id,
      }
      dispatch(fetchProject(payload))
    }
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  //to get and move to number
  function jump(page) {
    const pageNumber = Math.max(1, page)
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage))
  }

  return { next, prev, jump, currentData, currentPage, maxPage }
}

export default usePagination
