import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

export const StyledHeader = styled(AppBar)`
  background: #fff;
  width: 100%;
  height: 110px;
  margin-top: 86px;
`
export const StyledToolbar = styled(Toolbar)`
  min-height: 90px;
  width: calc(100% - 100px);
`
export const TableWrapper = styled.div`
  padding: 112px 20px 30px 20px;
`
export const TabsWrapper = styled.div`
  margin-top: -30px;
`

export const StyledToolbarHeader = styled(Toolbar)`
  min-height: 0;
  padding: 10px 30px 0 30px;
  display: flex;
  justify-content: space-between;
`
export const ExpansionPanel = styled(MuiExpansionPanel)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: none;
  '&:not(:last-child)': {
    border-bottom: 0;
  }
  '&:before': {
    display: none;
  }
  '&$expanded': {
    margin: 'auto';
  }
`

export const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -1;
  min-height: 56;
  '&$expanded': {
    min-height: 56;
  }
`

export const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)`
  padding: 8px;
`

export const RightSideContentWrapper = styled.div`
  display: flex;
  justify-content: inherit;
  width: 44%;
`

export const NoDataSection = styled.div`
  text-align: center;
  font-size: 20px;
`
