import { apiConfig } from './config'

const environment = {
  key: apiConfig?.apiKey,
  assets: apiConfig?.threeDViewer?.renditions,
  downloadAssets: apiConfig?.threeDViewer?.downloadFiles,
}

export const downloadBlob = (name, blob) => {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = name
  a.click()
}
const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

export const setOldVariationFormat = (oldList, newList) => {
  if (newList) {
    let updatedVariation = {}
    Object.entries(newList).forEach(([i, det]) => {
      let fileArr = {}
      for (let j in det) {
        fileArr[j] = det[j].file_url
      }
      updatedVariation[i] = fileArr
    })
    return updatedVariation
  } else {
    return oldList
  }
}

export const files = (asset) => {
  if (!asset.id) return Promise.reject(new Error('No Id Provided'))
  // TODO: remove the hardcoded variations
  let data = setOldVariationFormat(asset.variations, asset.variationDetails)
  data = data && data !== null ? data : {}
  let promises = []
  //let startTime
  Object.keys(data).forEach(function (k) {
    if (!data[k]) return
    // startTime = new Date().getTime()
    promises = Object.entries(data[k]).map(([name, url]) => {
      return fetch(
        environment.downloadAssets + url + '?key=' + environment.key,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + getCookie('access_token'),
            'x-id-token': getCookie('id_token'),
          },
        }
      )
        .then((response) => {
          return response.blob()
        })
        .then((blob) => ({ k, name, blob }))
    })
  })

  return Promise.all(promises)
    .then((variations) => {
      // let endTime = new Date().getTime()
      const zip = window.JSZip().folder(asset.id)
      zip.file('readme.txt', `CGISANDBOX - ${asset.id}`)
      variations.forEach((variation) => {
        const folder = zip.folder(variation.k)
        folder.file(variation.name, variation.blob, { binary: true })
      })
      // postEvent({
      //   eventType: 'Download Zipped Files',
      //   startTime,
      //   endTime,
      //   data: { assetName },
      // })
      return zip.generateAsync({ type: 'blob' })
    })
    .then((blob) => downloadBlob(`${asset.id}.zip`, blob))
}

export const images = (asset) => {
  if (!asset.id) return Promise.reject(new Error('No Id Provided'))
  // let data = AssetDetails[0].images
  const { images: data = {} } = asset
  const promises = Object.keys(data).map((url) => {
    return fetch(data[url], {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => ({ url: parseInt(url) + 1, blob }))
  })
  return Promise.all(promises)
    .then((variations) => {
      const zip = window.JSZip().folder(asset.id)
      variations.forEach((variation) => {
        zip.file(asset.id + '_' + variation.url + '.jpeg', variation.blob, {
          binary: true,
        })
      })
      return zip.generateAsync({ type: 'blob' })
    })
    .then((blob) => downloadBlob(`${asset.id}_images.zip`, blob))
}

export const downloadfile = (name, url, $this) => {
  $this.$store.dispatch('setProcessingText', 'Downloading Asset')
  $this.$store.dispatch('setProcessing', true)

  fetch(environment.assets + url + '?key=' + environment.key, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getCookie('access_token'),
      'x-id-token': getCookie('id_token'),
    },
  })
    .then((response) => {
      return response.status === 200 ? response.blob() : ''
    })
    .then((blob) => {
      if (blob !== '') {
        downloadBlob(name, blob)
        $this.$store.dispatch('setProcessing', false)
      } else {
        $this.$store.dispatch('addError', 'unable to download asset.')
        $this.$store.dispatch('setProcessing', false)
      }
    })
}

export const downloadImage = (name, url, $this) => {
  $this.$store.dispatch('setProcessingText', 'Downloading Attachment')
  $this.$store.dispatch('setProcessing', true)

  fetch(url + '?key=' + environment.key, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getCookie('access_token'),
      'x-id-token': getCookie('id_token'),
    },
  })
    .then((response) => {
      return response.status === 200 ? response.blob() : ''
    })
    .then((blob) => {
      if (blob !== '') {
        downloadBlob(name, blob)
      } else {
        $this.$store.dispatch('addError', 'Unable to download Attachment.')
        //  $this.$store.dispatch('setProcessing', false)
      }
      $this.$store.dispatch('setProcessing', false)
    })
}
