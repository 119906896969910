import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Files from '../Files'
import Features from '../Features'
import Images from '../Images'
import QRCodes from '../QRCodes'
import Tags from '../Tags'
import Specs from '../Specs'
import MetaSettings from '../MetaSettings'
import { showNotification } from '../../../app/Notifications/NotificationActionCreator'

const styles = (theme) => ({
  listRoot: {
    fontSize: '1.1rem',
    lineHeight: '2rem',
    marginTop: 20,
  },
})

class DataDisplay extends React.Component {
  getVariations(oldList, newList) {
    let updatedVariation = []
    if (newList) {
      return newList
    } else {
      Object.entries(oldList).forEach(([i, det]) => {
        let fileArr = []
        for (let j in det) {
          fileArr[j] = {
            file_url: det[j],
          }
        }
        updatedVariation[i] = fileArr
      })
    }
    return updatedVariation
  }

  render() {
    const { classes, data, email, showNotification, serviceType } = this.props
    const variations = this.getVariations(
      data.variations || {},
      data.variationDetails
    )
    return (
      <div className={classes.listRoot}>
        {serviceType === 'Create&License3DImage' && (
          <Files data={variations} showNotification={showNotification} />
        )}
        <Features data={data} />
        <Images data={data} />
        <QRCodes data={variations} />
        <MetaSettings {...this.props} />
        {data.tags && (
          <Tags
            data={data}
            currentUser={email}
            showNotification={showNotification}
          />
        )}
        <Specs data={data} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { auth } = state
  const { email } = auth
  return {
    email,
  }
}

export default connect(mapStateToProps, { showNotification })(
  withStyles(styles)(DataDisplay)
)
