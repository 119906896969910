import { useEffect, useRef } from 'react'
function promiseAllStepN(n, list) {
  let tail = list.splice(n)
  let head = list
  let resolved = []
  let processed = 0
  return new Promise((resolve, reject) => {
    head.forEach((x) => {
      let res = x()
      resolved.push(res)
      res
        .then((y) => {
          runNext()
          return y
        })
        .catch((e) => {
          reject(e)
        })
    })
    function runNext() {
      if (processed === tail.length) {
        resolve(Promise.all(resolved))
      } else {
        resolved.push(
          tail[processed]().then((x) => {
            runNext()
            return x
          })
        )
        processed++
      }
    }
  })
}

export const setPromiseConcurrent = () => {
  Promise.allConcurrent = (n) => (list) => promiseAllStepN(n, list)
}

export const decodeHtmlCharCodes = (str) => {
  if (str) {
    return str.toString().replace(/(&#(\d+);)/g, function (charCode) {
      return String.fromCharCode(charCode)
    })
  }
  return ''
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const month = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}
export const getFormattedDate = (dbDate) => {
  if (!dbDate) return 'N/A'
  const date = new Date(dbDate)
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return month + '/' + day + '/' + year
}

export const getRedirectUrl = () => {
  return localStorage.getItem('redirectUrl')
}
