import axios from 'axios'
import {
  TASK_SUCCESS,
  TASK_LIST_INITIATED,
  TASK_SET_FILTERS,
  TASK_STATUS_COUNT_SUCCESS,
} from './TaskActionType'
import apiConfig from '../../config/apiConfig'

let cancel

export function getTaskList({
  queryParams = {},
  callbackSuccess = () => {},
  callbackError = () => {},
}) {
  const {
    page = 1,
    count = 30,
    statusId = '',
    sortOrder = '',
    sortBy = '',
    searchQuery = '',
    filters,
    loginId,
    phaseId,
    showApprovedTask = false,
  } = queryParams

  if (cancel) {
    cancel()
  }
  axios({
    method: 'post',
    url: apiConfig.task.taskListing,
    data: {
      login_id: loginId,
      search: {
        keyword: searchQuery,
      },
      page,
      filters,
      count,
      sort_by: sortBy,
      sort_order: sortOrder,
      show_approved_tasks: showApprovedTask,
      status_id: statusId,
      phase_id: phaseId,
    },
    cancelToken: new axios.CancelToken(function executor(c) {
      cancel = c
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        callbackSuccess(response.data)
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        // do nothing
      } else {
        callbackError(error)
      }
    })
}

export function taskListSuccess(response) {
  return {
    type: TASK_SUCCESS,
    payload: {
      data: response,
    },
  }
}

export function taskSetFilters(response) {
  return {
    type: TASK_SET_FILTERS,
    payload: {
      data: response,
    },
  }
}

export function taskListInitiated() {
  return {
    type: TASK_LIST_INITIATED,
  }
}

export function taskCountSuccess(response) {
  return {
    type: TASK_STATUS_COUNT_SUCCESS,
    payload: {
      data: response,
    },
  }
}

const CancelToken = axios.CancelToken
export async function fileUpload({ file }) {
  const source = CancelToken.source()
  const envUrlStart =
    apiConfig.task.uploadApi + '/chunk_uploads/start?key=' + apiConfig.apiKey

  const envUrlUpload =
    apiConfig.task.uploadApi + '/chunk_uploads?key=' + apiConfig.apiKey

  const envUrlEnd =
    apiConfig.task.uploadApi + '/chunk_uploads/end?key=' + apiConfig.apiKey

  const response = await axios.post(
    envUrlStart,
    {
      targets: ['INTERNAL'],
      original_file_name: file.name,
    },
    {
      cancelToken: source.token,
    }
  )

  const chunkSize = 5 * 1024 * 1024 // 5 MB
  const sizeOfFile = file.size
  const numberOfChunks = Math.ceil(sizeOfFile / chunkSize)
  const chunksPromise = []

  for (let i = 0; i < numberOfChunks; i++) {
    const chunkedFormData = new FormData()
    const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize)
    chunkedFormData.append(
      'chunkUploadRequest',
      new Blob(
        [
          JSON.stringify({
            upload_id: response.data.upload_id,
            file_name: file.name,
            chunk_number: i,
            total_number_of_chunks: numberOfChunks,
            chunk_size: chunkSize,
            total_file_size: sizeOfFile,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )

    chunkedFormData.append('file', chunk)
    chunksPromise.push(
      () =>
        new Promise((resolve, reject) =>
          axios
            .post(envUrlUpload, chunkedFormData, {
              cancelToken: source.token,
            })
            .then(() => {
              // const percentageComplete = ((i + 1) / numberOfChunks) * 100 - 1
              resolve()
            })
            .catch((error) => {
              console.log(error)
              source.cancel()
              reject(error)
            })
        )
    )
  }

  await Promise.allConcurrent(5)(chunksPromise)

  const finalResponse = await axios.post(
    envUrlEnd,
    {
      upload_id: response.data.upload_id,
      file_name: file.name,
      targets: ['INTERNAL'],
      total_number_of_chunks: numberOfChunks,
      chunk_size: chunkSize,
      total_file_size: file.size,
      content_type: 'image',
      metadata: '{"key": "value"}',
    },
    {
      cancelToken: source.token,
    }
  )

  return { fileId: finalResponse.data.job_id }
}
