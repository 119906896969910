/* eslint-disable*/
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/ExpansionPanel'
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import { Box, Button, CircularProgress, Popover } from '@material-ui/core'
import { apiConfig } from './Lib/config'
import { showNotification } from '../../app/Notifications/NotificationActionCreator'
import { useDispatch } from 'react-redux'
import { isTPlus } from '../../app/Auth/Auth'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '0 0 6px 6px',
    marginBottom: 5,
    borderBottom: 0,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '16px 0 5px 0',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 35,
    },
  },
  content: {
    margin: '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default function EnvSettings({
  toggleGrid = () => {},
  togglePivot = () => {},
  changeLight = () => {},
  ...props
}) {
  const [state, setState] = React.useState({
    grid: true,
    pivot: true,
    light: true,
  })
  const { grid, pivot, light } = state

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  React.useEffect(() => {
    toggleGrid(grid)
  }, [grid])
  React.useEffect(() => {
    togglePivot(pivot)
  }, [pivot])
  React.useEffect(() => {
    changeLight(light)
  }, [light])

  return (
    <Accordion square expanded>
      <AccordionSummary>
        <Typography variant="body1">Environment Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={grid} onChange={handleChange} name="grid" />
            }
            labelPlacement="start"
            label="Show Grid"
          />
          <FormControlLabel
            control={
              <Switch checked={pivot} onChange={handleChange} name="pivot" />
            }
            labelPlacement="start"
            label="Show Pivot"
          />
          <FormControlLabel
            control={
              <Switch checked={light} onChange={handleChange} name="light" />
            }
            labelPlacement="start"
            label="Neutral Light"
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}

export function Dimensions({ features }) {
  return (
    <Accordion square expanded style={{ height: '100px' }}>
      <AccordionSummary>
        <Typography variant="body1" style={{ color: '#cc0000' }}>
          Dimensions (Overall)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {features?.map((feature) => {
          if (feature.name.includes('Dimensions')) {
            return feature.value
          } else {
            return <></>
          }
        })}
      </AccordionDetails>
    </Accordion>
  )
}

export function ReviewTask({
  projectId,
  taskId,
  tcin,
  phaseId,
  updateTcinList = () => {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [actionType, setActionType] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [showButton, setShowButton] = React.useState(true)
  const dispatch = useDispatch()

  const handleClick = (event, actionType) => {
    setAnchorEl(event.currentTarget)
    setActionType(actionType)
  }

  const handleClose = (actionType = '') => {
    setAnchorEl(null)
    setActionType(actionType)
  }

  const handleReviewTask = (actionType) => {
    handleClose(actionType)
    setLoading(true)
    const postData = {
      project_tcin_task: [
        {
          project_uuid: projectId,
          task_uuid: taskId,
          tcin: tcin,
        },
      ],
      phase_id: phaseId ? phaseId : 'RTA',
      status_id: actionType === 'Approve' ? 'APPROVED' : 'REJECT_AND_REASSIGN',
      is_target_plus_vendor: isTPlus,
    }
    axios
      .put(apiConfig.threeDViewer.reviewTask, postData)
      .then((res) => {
        setLoading(false)
        updateTcinList({
          tcin,
          status_id: actionType === 'Approve' ? 'Approved' : 'Rework',
        })
        setActionType('')
        dispatch(
          showNotification(true, 'Status changed successfully', 'success')
        )
        setShowButton(false)
      })
      .catch((err) => {
        setActionType('')
        setLoading(false)
        setShowButton(true)
        dispatch(showNotification(true, 'Unable to change the status', 'error'))
      })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  if (!showButton) return <></>
  return (
    <Accordion square expanded style={{ height: '100px' }}>
      <AccordionSummary>
        <Typography variant="body1">Review</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            marginTop: '10px',
            width: '90%',
          }}
        >
          <Button
            color="primary"
            style={{
              backgroundColor: '#cc0000',
              color: '#ffffff',
              textTransform: 'none',
              marginRight: '20px',
            }}
            onClick={(e) => handleClick(e, 'Reject')}
            aria-describedby={id}
            disabled={loading}
          >
            Reject
            {loading && actionType === 'Reject' ? (
              <CircularProgress
                style={{
                  width: '25px',
                  height: '25px',
                  marginLeft: '3px',
                  color: '#ffffff',
                }}
              />
            ) : (
              ''
            )}
          </Button>
          <Button
            color="primary"
            style={{
              backgroundColor: '#008300',
              color: '#ffffff',
              textTransform: 'none',
            }}
            onClick={(e) => handleClick(e, 'Approve')}
            aria-describedby={id}
            disabled={loading}
          >
            Approve
            {loading && actionType === 'Approve' ? (
              <CircularProgress
                style={{
                  width: '25px',
                  height: '25px',
                  marginLeft: '3px',
                  color: '#ffffff',
                }}
              />
            ) : (
              ''
            )}
          </Button>
        </Box>
      </AccordionDetails>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box style={{ padding: '10px 10px 0 10px' }}>
          <Typography>Are you sure want to {actionType}?</Typography>
          <Box style={{ float: 'right' }}>
            <Button
              color="primary"
              onClick={() => handleReviewTask(actionType)}
            >
              Yes
            </Button>
            <Button color="primary" onClick={() => handleClose()}>
              No
            </Button>
          </Box>
        </Box>
      </Popover>
    </Accordion>
  )
}
