import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { styled } from '@material-ui/core/styles'

import MuiAccordion from '@material-ui/core/ExpansionPanel'
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

//assets.map((a) => a.model.name)

const Accordion = withStyles({
  root: {
    border: '0',

    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 35,
    },
  },
  content: {
    margin: '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const ModelName = styled('span')(({ theme }) => ({
  color: theme.palette.primary.red,
}))

export default function ModelSelection({ changeAsset, assets }) {
  const [expanded, setExpanded] = React.useState(false)

  const AccrdionhandleChange = (panel) => (event, newExpanded) => {
    setExpanded(!expanded)
  }

  const [model, setModel] = React.useState('')

  const handleChange = (event) => {
    setModel(event.target.value)
    setExpanded(false)
    changeAsset(event.target.value)
  }

  const value = model === '' && assets.length ? assets[0].model.name : model
  return (
    <Accordion
      square
      expanded={expanded}
      onChange={AccrdionhandleChange('panel1')}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography variant="h5">
          ModelU: <ModelName>{value}</ModelName>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup
          aria-label="gender"
          name="model"
          value={value}
          onChange={handleChange}
        >
          {assets.map((a) => {
            return (
              <FormControlLabel
                color="secondary"
                value={a.model.name}
                control={<Radio color="secondary" />}
                label={a.model.name}
              />
            )
          })}
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  )
}
