/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { trim, reverse, clone } from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Button, Paper, CircularProgress } from '@material-ui/core'
import { getCommentList, AddComment } from './VendorActionCreator'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MessageIcon from '@material-ui/icons/Message'
import RefreshIcon from '@material-ui/icons/Refresh'
import { API_CALL_FAILURE } from '../../constants/TcinStatus'
import { decodeHtmlCharCodes } from '../../helpers/utils'

const styles = (theme) => ({
  infoHeader: {
    height: '65px',
    display: 'flex',
  },
  infoAdd: {
    padding: '4px 0 0 16px',
    display: 'flex',
    height: '70px',
  },
  infoHeaderText: {
    fontSize: '16px',
    color: '#333',
    fontWeight: 'bold',
  },
  headerTextUpper: {
    fontWeight: 400,
    fontSize: '20px',
    marginBottom: '2px',
  },
  headerTextLower: {
    fontWeight: 300,
    fontSize: '13px',
  },
  commentBody: {
    margin: '10px',
    height: '262px',
    paddingBottom: '20px',
    overflow: 'auto',
  },
  infoText: {
    color: theme.palette.primary.greyText,
    fontSize: '14px',
    width: '20%',
  },
  infoTextRight: {
    fontSize: '14px',
    fontWeight: 200,
    fontStyle: 'normal',
    color: theme.palette.primary.crispGreyText,
    marginRight: '24px',
    wordWrap: 'break-word',
    width: '74%',
    lineHeight: '20px',
  },
  bottomSpace: {
    marginBottom: '14px',
    display: 'flex',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 500,
  },
  rightIcon: {
    marginLeft: 8,
  },
  button: {
    margin: 8,
    height: 40,
    width: 100,
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.primaryBlue,
  },
  noComments: {
    fontWeight: 700,
    fontSize: '16px',
    color: theme.palette.primary.greylightest,
    margin: '24%',
  },
  getError: {
    fontWeight: 700,
    fontSize: '16px',
    color: theme.palette.primary.red,
    margin: '24%',
  },
  dateText: {
    textAlign: 'center',
    fontSize: '13px',
    color: theme.palette.primary.greylightest,
  },
  commentTilesRight: {
    border: '1px solid ' + theme.palette.primary.lighterBlue,
    backgroundColor: theme.palette.primary.lighterBlue,
    borderRadius: 4,
    color: theme.palette.primary.light,
    maxWidth: '500px',
    minHeight: '70px',
    marginLeft: 'auto',
    marginTop: 16,
    marginRight: 20,
    marginBottom: 16,
    padding: 16,
  },
  commentTilesLeft: {
    border: '1px solid ' + theme.palette.primary.greyBackground,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    color: theme.palette.primary.pitchBlack,
    maxWidth: '500px',
    minHeight: '70px',
    marginLeft: 16,
    marginTop: 16,
    marginRight: 'auto',
    marginBottom: 16,
    padding: 16,
  },
  headerInCommentTiles: {
    fontFamily: 'Helvetica',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
  },
  progress: {
    margin: '16px',
    marginLeft: '50%',
    marginTop: '20%',
    color: theme.palette.primary.red,
  },
  displaySidebySide: {
    display: 'flex',
    marginBottom: 16,
  },
  iconCircle: {
    marginRight: 16,
    fontSize: 32,
    color: 'black',
  },
  iconRefresh: {
    marginTop: '0%',
    marginLeft: '36%',
    cursor: 'pointer',
    color: 'grey',
  },
  iconMsg: {
    marginTop: '2%',
    marginRight: '15%',
    cursor: 'pointer',
  },
})

function VenndorComment({ ...props }) {
  const { classes, rowData, emailOfUser } = props
  const { tcin, vendorId } = rowData
  const [isDisabled, setIsDisabled] = useState(true)
  const [comment, setComment] = useState('')
  const [commentList, setCommentList] = useState([])

  const [isDataFound, setIsDataFound] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    gettingCommentDataFromAPI()
  }, [])

  function gettingCommentDataFromAPI() {
    getCommentList(tcin, vendorId)
      .then((response) => {
        var list = response.data.reverse()
        setCommentList(list)
        setIsDataFound(true) // either list or error
        setError('')
      })
      .catch((err) => {
        console.log(err)
        setIsDataFound(true) // either list or error
        setError(API_CALL_FAILURE)
      })
  }

  function handleRefresh() {
    setIsDataFound(false)
    gettingCommentDataFromAPI()
  }

  function handleAdd() {
    var reqBody = [
      {
        source: 'Vendor',
        comment_id: 'Vendor-' + new Date().getTime(),
        comment_type: 'New',
        commented_by: emailOfUser,
        commented_at: new Date().getTime().toString(),
        comment_desc: comment,
      },
    ]
    setComment('')
    setIsDisabled(true)
    setIsDataFound(false)
    AddComment(tcin, reqBody, vendorId)
      .then((response) => {
        gettingCommentDataFromAPI()
      })
      .catch((err) => {
        console.log(err)
        gettingCommentDataFromAPI()
      })
  }

  function handleChangeCommentText(event) {
    setComment(event.target.value)
    if (trim(event.target.value) !== '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  function prepareCommentArea() {
    return commentList.map((eachCommentItem, index) => (
      <div key={index}>
        <div className={classes.dateText} key={eachCommentItem.date_label}>
          {moment(eachCommentItem.date_label, 'YYYY-MM-DD').format(
            'dddd, Do MMMM YYYY'
          )}
        </div>
        {reverse(clone(eachCommentItem.comments)).map((item, key) =>
          item.source === 'Vendor' ? (
            <Paper className={classes.commentTilesRight} key={key}>
              <div className={classes.displaySidebySide}>
                <AccountCircleIcon className={classes.iconCircle} />
                <div className={classes.headerInCommentTiles}>
                  <div>{item.commented_by}</div>
                  <div>{moment(item.commented_at).format('LT')}</div>
                </div>
              </div>
              <div>{decodeHtmlCharCodes(item.comment_desc)}</div>
            </Paper>
          ) : (
            <Paper className={classes.commentTilesLeft} key={key}>
              <div className={classes.displaySidebySide}>
                <AccountCircleIcon className={classes.iconCircle} />
                <div className={classes.headerInCommentTiles}>
                  <div>{item.commented_by}</div>
                  <div>{moment(item.commented_at).format('LT')}</div>
                </div>
              </div>
              <div>{decodeHtmlCharCodes(item.comment_desc)}</div>
            </Paper>
          )
        )}
      </div>
    ))
  }

  return (
    <div style={{ height: 400 }}>
      <div className={classes.infoHeader}>
        <div>
          <div
            className={classes.infoHeaderText + ' ' + classes.headerTextUpper}
          >
            Comments
          </div>
          <div
            className={classes.infoHeaderText + ' ' + classes.headerTextLower}
          >
            If needed, ask & answer questions about this item.
          </div>
        </div>
        <RefreshIcon className={classes.iconRefresh} onClick={handleRefresh} />
      </div>
      <div className={classes.infoAdd}>
        <TextField
          id="add_comment"
          label="Comment"
          placeholder="Add a comment"
          value={comment}
          className={classes.textField}
          onChange={handleChangeCommentText}
        />
        <Button
          variant="contained"
          color="primary"
          className={
            classes.button + ' ' + (isDisabled ? '' : classes.buttonPrimary)
          }
          size="medium"
          disabled={isDisabled}
          onClick={isDisabled ? () => {} : handleAdd}
        >
          <MessageIcon
            color={isDisabled ? 'disabled' : 'white'}
            className={classes.iconMsg}
          />
          ADD
        </Button>
      </div>
      {isDataFound ? (
        error ? (
          <div className={classes.getError}>{error}</div>
        ) : (
          <div className={classes.commentBody}>
            {commentList.length === 0 ? (
              <div className={classes.noComments}>
                No comments have been added to this item
              </div>
            ) : (
              prepareCommentArea()
            )}
          </div>
        )
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </div>
  )
}

export default connect(null, { getCommentList, AddComment })(
  withStyles(styles)(VenndorComment)
)
