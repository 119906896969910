export const taskHeaders = [
  {
    id: 'phase_id',
    label: 'PHASE',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'tcin',
    label: 'TCIN',
    sortable: false,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'project_name',
    label: 'Project',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'project_cycle',
    label: 'Cycle',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'relationship_type',
    label: 'Relationship Type',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'color',
    label: 'Color',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'brand_name',
    label: 'Brand',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'launch_date',
    label: 'Launch Date',
    sortable: true,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'status',
    label: 'SH Status',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'dpci',
    label: 'DPCI',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'item_state',
    label: 'IDS Status',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'artist_completion_date',
    label: 'Artist Completion date',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'reviewer_completion_date',
    label: 'Review Completion date',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'Review_Rejected',
    label: 'Review_Rejected',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'rework_count',
    label: 'No. of Rework',
    sortable: false,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'over_due',
    label: 'Overdue',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'path',
    label: 'Path',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'status_id',
    label: 'Task Status',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'complexity',
    label: 'Complexity',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'priority',
    label: 'Priority',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'due_date',
    label: 'Due Date',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'due_date',
    label: 'Task Due Date',
    sortable: false,
    grid_display: false,
    display_excel: true,
  },
  {
    id: 'artist_name',
    label: 'Artist',
    sortable: true,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'reviewer_name',
    label: 'Reviewer',
    sortable: true,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'product_type',
    label: 'Item Type',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  // {
  //   id: 'task_summary',
  //   label: 'TASK SUMMARY',
  //   sortable: false,
  //   grid_display: true,
  //   display_excel: true,
  // },
  {
    id: 'parent_id',
    label: 'Parent',
    sortable: true,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'poster_frame',
    label: 'Poster Frame',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'scene_members',
    label: 'Scene Members',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'created_date',
    label: 'Created Date',
    sortable: true,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'assigned_date',
    label: 'Assigned Date',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'default_version',
    label: 'Default asset SH availability',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'default_version_error_message',
    label: 'DV error',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'styled_version',
    label: 'styled asset SH availability',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'style_version_error_message',
    label: 'SV error',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'unbundled_version',
    label: 'Unbundled asset SH availability',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'unbundle_version_error_message',
    label: 'UV error',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'referenced_projects',
    label: 'Referenced Projects',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'task_comment_count',
    label: 'Comments',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
  {
    id: 'production_phase_pc_path',
    label: 'PC PATH',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'production_phase_mac_path',
    label: 'MAC PATH',
    sortable: false,
    grid_display: false,
    display_excel: false,
  },
  {
    id: 'updated_date',
    label: 'Approved Date',
    sortable: true,
    grid_display: true,
    display_excel: true,
  },
  {
    id: 'more_button',
    label: '',
    sortable: false,
    grid_display: true,
    display_excel: false,
  },
]
