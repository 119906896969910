export const WAITING_FOR_REVIEW = 'waiting for review'
export const INPUTS_REQUIRED = 'inputs required'
export const INPUTS_VERIFIED = 'inputs verified'
export const INPUTS_APPROVED = 'inputs approved'
export const DISPLAY_WAITING_FOR_REVIEW = 'Waiting for Review'
export const DISPLAY_INPUTS_REQUIRED = 'Inputs Required'
export const DISPLAY_INPUTS_VERIFIED = 'Inputs Verified'
export const DISPLAY_INPUTS_APPROVED = 'Inputs Approved'
export const DISPLAY_READY_FOR_UPLOAD = 'Ready for Upload'
export const DISPLAY_FAILED_UPLOAD = 'Upload Failed. Please try upload again.'
export const API_CALL_FAILURE = 'An error occured, Please try after sometime.'
