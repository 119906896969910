import { useContext } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { UploadFiles } from 'gga-ui-library'
import { forEach } from 'lodash'
import apiConfig from '../../config/apiConfig'
// import { updateTask } from '../Task/useTaskSearch'
import { showNotification } from '../Notifications/NotificationActionCreator'
import { VendorContext } from './VendorContext'
import { TASK_FILE_STATUS, REMOVE_FILE } from '../Task/TaskActionType'
import { setPromiseConcurrent } from '../../helpers/utils'

const CancelToken = axios.CancelToken
setPromiseConcurrent()

const setReloadStopper = (on) => {
  window.onbeforeunload = function () {
    return on
  }
}

export const FilesUploadTimeDelay = 20000

const validateUploadFileName = (fileName, tcin, regex) => {
  // const capturingRegex = regex
  // var match = fileName.match(capturingRegex)
  // // const fileTCIN = fileName.search(tcin) !== -1
  // return Boolean(match && fileName === match[0])
  return true
}

// Component starts from here.
const UploadFilesContainerVendorPipeline = ({
  item,
  updateFilesTable,
  regex,
  email,
  deleteFile,
}) => {
  const dispatch = useDispatch()
  const [store, dispatchContext] = useContext(VendorContext)
  // const [submitLoading, setSubmitLoading] = useState(false)
  const setFileStatesList = (fileStatesList) => {
    dispatchContext({
      type: TASK_FILE_STATUS,
      payload: { tcin: item.tcin, fileStatesList },
    })
  }

  const fileStatesList = store.taskFileStatus[item.tcin] || {}

  const generateFileStatus = ({
    name,
    progress,
    status,
    fileId,
    cancelToken = null,
  }) => {
    setFileStatesList({
      [name]: { name, progress, status, fileId, cancelToken },
    })
  }

  const removeFiles = (key) => {
    try {
      dispatchContext({ type: REMOVE_FILE, payload: { key, tcin: item.tcin } })
      const source = fileStatesList[key].cancelToken
      if (source) source.cancel()
      // Start - Persisting code - LocalStorage
      const taskFileStatus =
        JSON.parse(localStorage.getItem('taskFileStatus')) || {}

      delete taskFileStatus[item.tcin][key]
      localStorage.setItem('taskFileStatus', JSON.stringify(taskFileStatus))
      // End - Persisting code - LocalStorage
    } catch (error) {
      console.log('removeFiles => ', error)
    }
  }

  const uploadFileHandler = (file, fileType) => {
    if (!validateUploadFileName(file.file.name, item.tcin, regex)) {
      dispatch(
        showNotification(
          true,
          'File name should match "_texturing.zip"',
          'error'
        )
      )
      return
    }

    const ext = file.file.name.slice(file.file.name.indexOf('.') + 1)

    //set Reload stopper
    setReloadStopper('stop')
    const requestSource = CancelToken.source()

    const envUrl =
      apiConfig.pipeline.uploadApi +
      '/cgi_assets/v1/vendor_asset_uploads/chunks/start?key=' +
      apiConfig.apiKey
    generateFileStatus({
      progress: 0,
      status: 'waiting',
      name: file.file.name,
      cancelToken: requestSource,
    })

    axios
      .post(
        envUrl,
        {
          asset_type: fileType,
          external_asset_group_id: item.id,
          file_name: file.file.name,
          file_type: ext,
          language: 'EN',
          vendor_email: email,
          vendor_id: '',
          vendor_name: item.vendorName || 'Target India (Non-USA)',
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }
      )
      .then((res) => {
        generateFileStatus({
          progress: 1,
          status: 'in_progress',
          name: file.file.name,
          cancelToken: requestSource,
        })
        chunkUpload({
          uploadId: res.data.externalAssetTrackingSummary.id,
          file: file.file,
          requestSource,
          fileType,
        })
      })
      .catch((error) => {
        setReloadStopper()
        generateFileStatus({
          progress: 15,
          status: 'error',
          name: file.name,
          fileId: null,
        })
      })
  }

  const chunkUpload = async ({ uploadId, file, requestSource, fileType }) => {
    const source = requestSource

    // setup chunked load parameters
    const chunkSize = 1 * 1024 * 1024 // 1 mb
    const sizeOfFile = file.size
    const numberOfChunks = Math.ceil(sizeOfFile / chunkSize)
    // alert(numberOfChunks)
    // send file chunk by chunk
    const chunksPromise = []
    for (let i = 0; i < numberOfChunks; i++) {
      const envUrl =
        apiConfig.pipeline.uploadApi +
        '/cgi_assets/v1/vendor_asset_uploads/chunks?key=' +
        apiConfig.apiKey

      // const chunkedFormData = new window.FormData()
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize)

      chunksPromise.push(
        () =>
          new Promise((resolve) =>
            axios
              .put(envUrl, chunk, {
                headers: {
                  'X-Upload-Container': 'vendor_cgi',
                  'X-Upload-Reference': uploadId,
                  'X-Upload-Asset-Type': fileType,
                  'Content-Disposition':
                    'attachment; filename="' + file.name + '"',
                  'content-type': 'application/json;charset=utf-8',
                  'Content-Range':
                    'bytes ' +
                    i * chunkSize +
                    '-' +
                    (i + 1) * chunkSize +
                    '/' +
                    sizeOfFile,
                },
                cancelToken: source.token,
              })
              .then(() => {
                const percentageComplete = ((i + 1) / numberOfChunks) * 100 - 1
                generateFileStatus({
                  progress: percentageComplete,
                  status: 'in_progress',
                  name: file.name,
                  cancelToken: requestSource,
                })
                resolve()
              })
              .catch(() => {
                setReloadStopper()
                deleteFile(uploadId)
                generateFileStatus({
                  progress: 19,
                  status: 'error',
                  name: file.name,
                  fileId: null,
                })
              })
          )
      )
    }

    Promise.allConcurrent(5)(chunksPromise).then((res) => {
      endUpload({
        uploadId,
        file,
        numberOfChunks,
        chunkSize,
        requestSource,
      })
    })
  }

  const endUpload = ({
    uploadId,
    file,
    numberOfChunks,
    chunkSize,
    sizeOfFile,
    requestSource,
  }) => {
    const envUrl =
      apiConfig.pipeline.uploadApi +
      '/cgi_assets/v1/vendor_asset_uploads/chunks/complete?key=' +
      apiConfig.apiKey
    const source = requestSource

    axios
      .post(
        envUrl,
        {
          fileName: file.name,
          externalAssetTrackingSummaryId: uploadId,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        const statusData = {
          progress: 100,
          status: 'success',
          name: file.name,
          fileId: res.data.job_id,
        }
        updateFilesTable(FilesUploadTimeDelay, item.tcin)
        generateFileStatus(statusData)
        setReloadStopper()

        // Start - Persisting code - LocalStorage
        const successData = {
          [file.name]: statusData,
        }
        forEach(fileStatesList, (val, key) => {
          if (val.status === 'success') {
            successData[key] = val
          }
        })
        const taskFileStatus =
          JSON.parse(localStorage.getItem('taskFileStatus')) || {}

        taskFileStatus[item.tcin] = successData
        localStorage.setItem('taskFileStatus', JSON.stringify(taskFileStatus))
        // End - Persisting code - LocalStorage
      })
      .catch((error) => {
        setReloadStopper()
        deleteFile(uploadId)
        generateFileStatus({
          progress: 27,
          status: 'error',
          name: file.name,
          fileId: null,
        })
      })
  }

  const onCancelUpload = () => {
    forEach(fileStatesList, (fileStatus) => {
      const source = fileStatus.cancelToken
      if (source) source.cancel()
    })
  }

  return (
    <div style={{ display: 'flex' }}>
      <span
        style={{
          padding: '7px',
          fontSize: '20px',
          fontWeight: '500',
        }}
      >
        Upload Files:
      </span>
      <UploadFiles
        tcin={item.tcin}
        dropzoneArray={[
          {
            text: 'Drop CAD files here or browse',
            fileType: 'CGI_CAD',
            acceptedFileTypes: [
              '.step',
              '.iges',
              '.igs',
              '.obj',
              '.fbx',
              '.3ds',
              '.stl',
              '.max',
              '.jegs',
              '.sldprt',
              '.dwg',
            ],
          },
          {
            text: 'Drop PMI files here or browse',
            fileType: 'CGI_PMI',
            acceptedFileTypes: [
              '.txt',
              '.pdf',
              '.jpg',
              '.jpeg',
              '.ai',
              '.psd',
              '.svg',
              '.png',
              '.tif',
              '.tiff',
              '.bmp',
            ],
          },
          {
            text: 'Drop REF files here or browse',
            fileType: 'CGI_REF',
            acceptedFileTypes: [
              '.jpg',
              '.pdf',
              '.jpeg',
              '.psd',
              '.tif',
              '.tiff',
              '.png',
              '.bmp',
            ],
          },
          // {
          //   text: 'Drop Video (PDF) files here or browse',
          //   fileType: 'VIDEO',
          //   acceptedFileTypes: ['.pdf', '.jegs', '.sldprt', '.dwg'],
          // },
        ]}
        uploadFileHandler={uploadFileHandler}
        fileStatesList={fileStatesList}
        removeFiles={removeFiles}
        onSubmit={() => {}}
        onCancelUpload={onCancelUpload}
        submitLoading={false}
        hideDialogActions
      />
    </div>
  )
}

export default UploadFilesContainerVendorPipeline
