import { makeStyles } from '@material-ui/core'
import { Close, GetApp as DownloadIcon } from '@material-ui/icons'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentWrapper: { width: '457px', height: '250px' },
  downloadRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 8px 8px 0',
    borderBottom: '1px solid #7c7e84',
    marginBottom: '10px',
  },
  downloadAllButton: {
    backgroundColor: '#3D70D6 !important',
    color: '#fff !important',
  },
  downloadIcon: { cursor: 'pointer' },
}))

function DownloadPopup({ open = false, onClose, urlZip1, urlZip2, tcin }) {
  const styles = useStyles()
  const getDownloadRow = (name, link) => {
    return (
      <Box className={styles.downloadRowWrapper}>
        {link ? name : `${name} is not available for download`}
        {!!link && (
          <DownloadIcon
            className={styles.downloadIcon}
            onClick={() => {
              window.location.assign(link)
            }}
          />
        )}
      </Box>
    )
  }

  const downloadAll = () => {
    if (urlZip1) {
      window.open(urlZip1)
    }
    if (urlZip2) {
      window.open(urlZip2)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box className={styles.titleWrapper}>
          <Typography variant="body1">Select files to download</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={styles.contentWrapper}>
          {getDownloadRow(`${tcin}_styled.zip`, urlZip1)}
          {getDownloadRow(`${tcin}_refrence.zip`, urlZip2)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button className={styles.downloadAllButton} onClick={downloadAll}>
          Download All
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DownloadPopup
