/* eslint-disable */
import { useState, useRef } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { connect } from 'react-redux'

import axios from 'axios'
import { Tooltip, IconButton, Button } from '@material-ui/core'
import { AttachFile, Close } from '@material-ui/icons'
import { showNotification } from '../../../app/Notifications/NotificationActionCreator'
import { makeStyles } from '@material-ui/core/styles'
import apiConfig from '../../../config/apiConfig'
import { apiConfig as commentsApiConfig } from '../Lib/config'
import { isTPlus } from '../../../app/Auth/Auth'

const mentionStyle = (active) => {
  return {
    width: '100%',
    height: 65,
    border: `1px solid ${active ? 'red' : 'gray'}`,
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
    },
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 5,

      border: '1px solid transparent',
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        color: '#555',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }
}
const useStyles = makeStyles((theme) => ({
  iconButton: { padding: 0, marginLeft: 5 },
  filesWrapper: {
    border: `1px solid`,
    borderTop: 0,
    borderColor: ({ color }) => color,
  },
  fileLabel: { width: '100%', display: 'block', padding: 5 },
  attachIcon: {
    verticalAlign: 'middle',
    fontSize: '1.5em',
    transform: 'rotate(45deg)',
  },
  fileItem: { padding: '2px 10px', color: '#366cd9', fontSize: 12 },
  cancel: { color: theme.palette.primary.targetRed },
  actionWrapper: { textAlign: 'right', marginTop: 5 },
  saveButton: { color: theme.palette.primary.blueText },
  cancelButton: { color: theme.palette.primary.targetRed },
  maxError: {
    color: theme.palette.primary.targetRed,
    marginLeft: 10,
    fontSize: 10,
  },
}))

function fetchUsers(query, callback) {
  if (!query) return
  callback([
    {
      display: 'Loading...',
      id: 'Loading...',
    },
  ])
  axios
    .get(
      `${apiConfig.projects.getAllMembers}?nameLike=${query}&key=${apiConfig.key}`
    )
    // Transform the users to what react-mentions expects
    .then((res) => {
      return res.data.map((user) => ({
        display: user.display_name,
        id: user.login_id,
      }))
    })
    .then(callback)
}

const CommentInput = ({
  tcin = '',
  isEdit = false,
  attachmentList = [],
  text: commentText = '',
  onCancel = () => {},
  isReply = false,
  id = '',
  onNewComment = () => {},
  parentId = '',
  showNotification,
  newHotspot = undefined,
}) => {
  const [text, setText] = useState(commentText)
  const [files, setFiles] = useState([])
  const [savedFiles, setSavedFiles] = useState(attachmentList)
  const [filesToBeDeleted, setfilesToBeDeleted] = useState([])
  const [maxError, setMaxError] = useState(false)
  const [saving, setSaving] = useState(false)
  const fileRef = useRef(null)
  const classes = useStyles({
    color: newHotspot !== undefined ? 'red' : 'gray',
  })
  const handleFileChange = (e) => {
    let newFiles = Object.values(fileRef.current.files).map((item) => item)
    let filesData = [...new Set([...files, ...newFiles])]

    if (Math.abs(filesData.length + savedFiles.length) <= 10) {
      setFiles(filesData)
      setMaxError(false)
      return
    }
    setMaxError(true)
  }
  const cancelComment = () => {
    setText(commentText)
    setFiles([])
    onCancel()
  }
  const saveComment = () => {
    setSaving(true)
    let requestData = { text }

    if (isEdit && parentId && parentId !== '') {
      //Editing the replied comment
      requestData = {
        reply_comments: [
          {
            text,
            id,
          },
        ],
        id: parentId,
      }
    }
    if (isEdit && !parentId && parentId === '') {
      //Editing the comment
      requestData = {
        text,
        id,
      }
    }
    if (isReply && parentId && parentId !== '') {
      //replying  the comment
      requestData = {
        reply_comments: [
          {
            text,
          },
        ],
        id: parentId,
      }
    }

    if (!isEdit && !isReply) {
      //assigning HARCODED because it will be only in EXT GGA. we may need to change this logic when we use it in internal gga.
      requestData.to_domain_type = 'TARGET'
    }

    requestData.annotation_data = null
    if (newHotspot !== undefined) {
      requestData.annotation_data = {
        position: newHotspot.dataset.position,
        normal: newHotspot.dataset.normal,
        camera_orbit: newHotspot.dataset.cameraOrbit,
        camera_target: newHotspot.dataset.cameraTarget,
      }
    }

    axios
      .post(commentsApiConfig.threeDViewer.saveComments(tcin), {
        ...requestData,
        is_target_plus_vendor: isTPlus,
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          if (files.length) {
            let newCommentId = ''
            if (parentId !== '') {
              if (id === '') {
                newCommentId = data
                  .find((item) => item.id === parentId)
                  .reply_comments.find((item) => item.is_new === true).id
              } else {
                newCommentId = id
              }
              saveAttachment(newCommentId, parentId)

              // call file saving with this comment id
            } else {
              if (id !== '') {
                newCommentId = id
              } else {
                newCommentId = data.find((item) => item.is_new === true).id
              }
              saveAttachment('', newCommentId)
            }
          } else {
            onCommentSuccess(data)
          }
        }
      })
    deleteAttachment()
  }
  const onCommentSuccess = (data) => {
    if (filesToBeDeleted.length === 0) {
      setSaving(false)
      cancelComment()
      onNewComment(data)
    }
  }

  const deleteSavedFile = (file) => {
    setfilesToBeDeleted([...filesToBeDeleted, file.name])
    setSavedFiles(savedFiles.filter((item) => item.name !== file.name))
  }
  const deleteFile = (file) => {
    setFiles(files.filter((item) => item.name !== file.name))
  }

  const saveAttachment = (commentId, parentId) => {
    let formData = new FormData()
    files.forEach((file) => {
      formData.append('file', file)
    })

    formData.append('id', parentId)
    formData.append('reply_id', commentId)
    axios
      .put(commentsApiConfig.threeDViewer.attachComments(tcin), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          onCommentSuccess(response.data)
        } else {
          setSaving(false)
          showNotification(true, 'Error in saving attachment.', 'error')
        }
      })
      .catch((e) => {
        console.log(e)
        setSaving(false)
        showNotification(true, 'Error in saving attachment', 'error')
      })
  }
  const deleteAttachment = () => {
    let successCount = 0
    filesToBeDeleted.forEach((item, index) => {
      setTimeout(() => {
        axios
          .delete(commentsApiConfig.threeDViewer.deleteComment(tcin, id, item))
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              successCount++
              if (successCount === filesToBeDeleted.length) {
                //this.onDeleteAttachment(response, 1000)
                setfilesToBeDeleted([])
                onCommentSuccess(response.data)
              }
            } else {
              setSaving(false)
              showNotification(true, 'Error in deleting attachment', 'error')
            }
          })
          .catch((e) => {
            console.log(e)
            setSaving(false)
            showNotification(true, 'Error in deleting attachment', 'error')
          })
      }, index * 1000)
    })
  }

  const AttachmentItem = ({ data: file, deleteAction = () => {} }) => {
    return (
      <div className={classes.fileItem}>
        {file.name}
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            deleteAction(file)
          }}
        >
          <Close className={classes.cancel} fontSize="small" />
        </IconButton>
      </div>
    )
  }
  const commentTextValue = text && text != null ? text : ''

  return (
    <>
      <MentionsInput
        style={mentionStyle(newHotspot !== undefined)}
        value={commentTextValue}
        onChange={(e) => {
          setText(e.target.value)
        }}
        placeholder="Type your comment..."
      >
        <Mention
          displayTransform={(user, dis) => `@${dis}`}
          trigger="@"
          data={fetchUsers}
          appendSpaceOnAdd
        />
      </MentionsInput>
      <div className={classes.filesWrapper}>
        <label className={classes.fileLabel}>
          <Tooltip title="Attach file">
            <span>
              <AttachFile color="#ccc" className={classes.attachIcon} />
              Attach Files
            </span>
          </Tooltip>

          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/*,.pdf"
            ref={fileRef}
            multiple="multiple"
            onChange={handleFileChange}
          />
        </label>
        {maxError ? (
          <span className={classes.maxError}>
            {' '}
            Only 10 attachments allowed per comment
          </span>
        ) : (
          ''
        )}
        {savedFiles.length
          ? savedFiles.map((file) => (
              <AttachmentItem data={file} deleteAction={deleteSavedFile} />
            ))
          : null}
        {files.length ? (
          <div>
            {files.map((file) => (
              <AttachmentItem data={file} deleteAction={deleteFile} />
            ))}
          </div>
        ) : null}
      </div>
      <div className={classes.actionWrapper}>
        <Button
          className={classes.cancelButton}
          onClick={cancelComment}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          disabled={text === '' || saving}
          onClick={saveComment}
          variant="text"
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default connect((state) => state, { showNotification })(CommentInput)
