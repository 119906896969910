/* eslint-disable no-prototype-builtins */
import { promptToSignIn, signOut } from './AuthContainer'
import axios from 'axios'
import { connect } from 'react-redux'
import React from 'react'
import { showNotification } from '../Notifications/NotificationActionCreator'
import apiConfig from '../../config/apiConfig'
import { isTPlus } from './Auth'
import { getRedirectUrl } from '../../helpers/utils'
// import authUtil from './authUtil'
/*
Interceptor adds useful handlers to axios requests and responses.
If the user has an accessToken, it is used as the value for the
Authorization header. This applies to all requests to .target.com and
.tgt domains. You can disable this per request by passing in this config:
{headers: {Authorization: false}}
If an axios response is an error, it is handled based on the error code:
- 401: prompt the user to sign in
- 403: notify the user that they don't have permission to do that
- Other: show the axios error message to the user
When the component unmounts, the interceptors are removed.
*/
export class Interceptor extends React.Component {
  static state = {
    authInterceptor: undefined,
    errorInterceptor: undefined,
  }
  componentDidMount() {
    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }
  componentWillUnmount() {
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const { accessToken } = this.props
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        // const isAuthenticateUser = authUtil()
        // if (!isAuthenticateUser) {
        //   localStorage.removeItem('access_token') // eslint-disable-line
        //   localStorage.removeItem('environment') // eslint-disable-line
        //   localStorage.removeItem('id_token') // eslint-disable-line
        //   window.location = '/'
        // }
        /*eslint no-prototype-builtins: "error"*/
        if (!config.headers.hasOwnProperty('Authorization')) {
          if (accessToken && this.isTargetDomain(config.url)) {
            config.headers.Authorization = accessToken
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
        }
        if (!config.headers.hasOwnProperty('x-api-key')) {
          if (this.props.xApiKey && this.isTargetDomain(config.url)) {
            config.headers['x-api-key'] = this.props.xApiKey
          }
        } else if (!config.headers['x-api-key']) {
          delete config.headers['x-api-key']
        }
        if (!config.headers.hasOwnProperty('x-id-token')) {
          if (this.props.xIdToken && this.isTargetDomain(config.url)) {
            config.headers['x-id-token'] = this.props.xIdToken
          }
        } else if (!config.headers['x-id-token']) {
          delete config.headers['x-id-token']
        }
        if (config.url.includes('renditions')) {
          config.responseType = 'blob'
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response) {
          const code = error.response.status
          if (code === 401) {
            if (isTPlus) {
              window.location.href =
                getRedirectUrl() || apiConfig.auth_tplus.tplusUrl
            } else {
              this.props.promptToSignIn()
            }
          } else {
            let message = 'Something went wrong.'
            if (code === 403) {
              message = `You're not authorized to do that.`
              this.props.showNotification(true, message, 'error')
            }
          }
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }
  // hostname ends with .target.com or .tgt

  isTargetDomain = (url) => {
    return /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)
  }
  render() {
    return null
  }
}

const mapStateToProps = () => ({
  accessToken: localStorage.getItem('access_token') || ' ',
  xApiKey: apiConfig.apiKey,
  xIdToken: localStorage.getItem('id_token') || ' ',
})

const mapDispatchToProps = {
  promptToSignIn,
  showNotification,
  signOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Interceptor)
