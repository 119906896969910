import { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { TaskActionButton } from 'gga-ui-library'
import { Button, CircularProgress, Link } from '@material-ui/core'
import { SaveAlt } from '@material-ui/icons'

import { showNotification } from '../Notifications/NotificationActionCreator'
import apiConfig from '../../config/apiConfig'
import DownloadPopup from './DownloadPopup'

const DownloadFileContainer = ({
  jobId,
  fileName,
  lastUpdated,
  listingType = false,
  nameButton = false,
  selectedPhase = '',
  urlZip1,
  urlZip2,
  tcin,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const isASB = selectedPhase === 'AGENCY_SCENE_BUILDING'

  const downloadFileHandler = (event) => {
    event.stopPropagation()
    if (isASB) {
      setOpen(true)
      return ''
    }
    setLoading(true)
    const url = `${apiConfig.task.downloadUrl}/${jobId}/${fileName}`
    axios
      .get(url)
      .then((response) => {
        if (response) {
          let filePath = `${apiConfig.task.fileDownload}/${jobId}?key=${apiConfig.apiKey}&file_name=${fileName}&is_download=true`
          window.location.assign(filePath)
        } else {
          dispatch(showNotification(true, 'Something went wrong', 'error'))
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        dispatch(showNotification(true, 'Something went wrong', 'error'))
      })
  }

  if (nameButton) {
    return (
      <Button onClick={downloadFileHandler} color="primary" variant="outlined">
        <SaveAlt /> &nbsp;{fileName} &nbsp;
        {loading ? <CircularProgress size={20} /> : null}
      </Button>
    )
  }

  let disableButton = true
  if ((isASB && (urlZip1 || urlZip2)) || (jobId && fileName)) {
    disableButton = false
  }

  return (
    <>
      {listingType ? (
        <Link component="button" variant="body2" onClick={downloadFileHandler}>
          {fileName} {lastUpdated && '@'} {lastUpdated.split('T')[1]}
        </Link>
      ) : (
        <TaskActionButton
          onClick={downloadFileHandler}
          disableBtn={disableButton}
          buttonType={loading ? 'loading' : 'download'}
          buttonName={loading ? 'Preparing to download' : 'Download files'}
        />
      )}
      <DownloadPopup
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        urlZip1={urlZip1}
        urlZip2={urlZip2}
        tcin={tcin}
      />
    </>
  )
}

export default DownloadFileContainer
