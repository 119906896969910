import React from 'react'
import { withStyles, Button, TextField } from '@material-ui/core'
import Tag from './tag'
import axios from 'axios'

const style = (theme) => ({
  root: {
    margin: '10px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    color: '#666',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
  },
  addButton: {
    height: 85,
  },
  input: {
    margin: 10,
  },
})

class Tags extends React.Component {
  constructor() {
    super()
    this.state = {
      isAdding: false,
      tags: [],
    }
  }
  componentDidMount = () => {
    const {
      data: { tags },
    } = this.props
    this.setState({ tags })
  }
  addTag = (value) => {
    this.setState({ isAdding: value })
  }

  createNewTag = (e) => {
    const { showNotification, data: propData } = this.props
    if (!e.target.value) return
    const url =
      'https://stage-api.target.com/digital_assets/v1/cgisandbox/' +
      'assets/' +
      propData.tcin +
      '/tags'
    axios
      .post(url, {
        tag: e.target.value,
      })
      .then((response) => {
        this.tags = response.data
        this.setState({ tags: response.data })
        this.addTag(false)
        showNotification(true, 'Tag Created Successfully', 'success')
      })
      .catch((e) => {
        showNotification(true, 'Unable to add tag', 'error')
      })
  }
  deleteTag = (e, data) => {
    // remove the tags
    const { showNotification, data: propData } = this.props
    const params = {
      data: {
        tag: data.tag,
        user: data.user,
        time: data.time,
      },
    }
    const url =
      'https://stage-api.target.com/digital_assets/v1/cgisandbox/assets/' +
      propData.tcin +
      '/tags'
    axios
      .delete(url, params)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ tags: response.data })
          showNotification(true, 'Tag Removed Successfully', 'success')
        } else {
          showNotification(true, 'Unable to delete tag', 'error')
        }
      })
      .catch((e) => {
        showNotification(true, 'Unable to delete tag', 'error')
      })
  }

  render() {
    const { classes, currentUser = '' } = this.props
    const { isAdding, tags } = this.state
    return (
      <>
        <div className={classes.root}>
          {tags.map((tag) => (
            <Tag
              tag={tag}
              currentUser={currentUser}
              deleteTag={this.deleteTag}
            ></Tag>
          ))}
          <div className={classes.input}>
            {!isAdding ? (
              <Button
                variant="outlined"
                onClick={(e) => this.addTag(true)}
                className={classes.addButton}
              >
                +
              </Button>
            ) : (
              <TextField
                id="outlined-secondary"
                label="Add New Tag"
                variant="outlined"
                color="secondary"
                size="small"
                autoFocus
                onBlur={(e) => this.addTag(false)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.createNewTag(event)
                  }
                }}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(style)(Tags)
