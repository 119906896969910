import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const style = (theme) => ({
  root: {
    flexDirection: 'column',
  },
  image: {
    textAlign: 'left',
    marginTop: '1rem',
    width: '100%',
  },
})

class Images extends React.Component {
  render() {
    const { classes, data } = this.props
    const params = '?scl=1'
    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Images</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          <div class="cs-images">
            {data.images.map((image, i) => (
              <img
                alt={'Tcin ' + i}
                key={i}
                className={classes.image}
                src={`${image}${params}`}
              ></img>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(style)(Images)
