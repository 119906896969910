/* eslint-disable*/
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import MaterialSettings from './MaterialSettings'

const useStyles = makeStyles((theme) => ({
  section: {
    width: '100%',
    padding: '8px',
    margin: '5px 0',
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '0 0 6px 6px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderBottom: 0,
  },
  controlSection: {
    display: 'flex',
  },
}))

export default function MetaSettings({
  materialList,
  onMaterialChange,
  toggleDimensions = () => {},
  toggleWireframe = () => {},
  ...props
}) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    dimensions: true,
    wireframe: false,
  })
  const { dimensions, wireframe } = state

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }
  React.useEffect(() => {
    toggleDimensions(dimensions)
  }, [dimensions])
  React.useEffect(() => {
    toggleWireframe(wireframe)
  }, [wireframe])

  return (
    <>
      <div className={classes.section}>
        {materialList.map((item, i) => {
          return (
            <MaterialSettings
              onMaterialChange={onMaterialChange}
              index={i}
              {...item}
              key={i}
            />
          )
        })}

        <div className={classes.controlSection}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={dimensions}
                  onChange={handleChange}
                  name="dimensions"
                />
              }
              labelPlacement="start"
              label="Show Dimensions"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={wireframe}
                  onChange={handleChange}
                  name="wireframe"
                />
              }
              labelPlacement="start"
              label="Enable Wireframe mode"
            />
          </FormGroup>
        </div>
      </div>
    </>
  )
}
