import { OPEN_STATE } from './SignInPromptActionType'
import { DefaultState } from './SignInPromptDefaultStateData'

export default function uploadReducer(state = DefaultState, action = {}) {
  switch (action.type) {
    case OPEN_STATE: {
      const { payload } = action
      const newState = {
        ...state,
        openState: payload.openState,
      }
      return newState
    }
    default:
      return state
  }
}
