import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Chip, Fab, LinearProgress, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { FilesUploadTimeDelay } from './UploadFilesContainerVendorPipeline'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxHeight: 500,
  },
  container: {
    maxHeight: '60vh',
  },
})

const tcinStatus = {
  'Inputs Verified': {
    text: 'Inputs Verified',
    bgcolor: 'rgba(51, 153, 0, 1)',
    txtcolor: '#fff',
  },
  InvalidStatus: {
    text: 'Upload Failed',
    bgcolor: 'rgba(255, 0, 0, 1)',
    txtcolor: '#fff',
  },
  OnHold: {
    text: 'On Hold',
    bgcolor: '#f9e0e0',
    txtcolor: '#333',
  },
  'Waiting for review': {
    text: 'Waiting For Review',
    bgcolor: 'rgb(255, 204, 0, 1)',
    txtcolor: '#333',
  },
  'Inputs required': {
    text: 'Inputs required',
    bgcolor: 'rgba(143, 4, 24, 1)',
    txtcolor: '#fff',
  },
  InProcess: {
    text: 'Processing...',
    bgcolor: '#efefef',
    txtcolor: '#333',
  },
  Error: {
    text: 'Processing...',
    bgcolor: '#efefef',
    txtcolor: '#333',
  },
  RequestForMoreInputs: {
    text: 'Inputs required',
    bgcolor: 'rgba(143, 4, 24, 1)',
    txtcolor: '#fff',
  },
  Approved: {
    text: 'Inputs Approved',
    bgcolor: 'rgba(51, 153, 0, 1)',
    txtcolor: '#fff',
  },
  default: {
    text: '',
    bgcolor: '#fff',
    txtcolor: '#333',
  },
}

export default function FilesTable({
  filesData,
  loading,
  deleteFile,
  loadingDeterminate,
}) {
  const classes = useStyles()
  const [completed, setCompleted] = React.useState(0)

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        const diff = 100000 / FilesUploadTimeDelay
        return Math.min(oldCompleted + diff, 100)
      })
    }
    let timer
    if (loadingDeterminate) timer = setInterval(progress, 1000)
    if (!loadingDeterminate) {
      setCompleted(0)
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [loadingDeterminate])

  if (loadingDeterminate) {
    return (
      <>
        <LinearProgress
          variant="buffer"
          value={completed}
          valueBuffer={2 + Math.random() * 10 + completed}
        />
        <br />
        <Typography variant="subtitle2" color="textSecondary">
          We are processing your files this could take upto 1 min.
        </Typography>
      </>
    )
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">File Name</TableCell>
            <TableCell align="right">File Type</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={4}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            filesData &&
            filesData.map((row) => {
              let tcinStatusObj = tcinStatus[row.approval_status]

              if (!tcinStatus[row.approval_status]) {
                tcinStatusObj = tcinStatus.default
              }

              return (
                <TableRow key={row.external_asset_tracking_summary_id}>
                  <TableCell component="th" scope="row">
                    {row.file_name}
                  </TableCell>
                  <TableCell align="right">
                    {(row.asset_type || '').replace(/_/g, ' ').toUpperCase()}
                  </TableCell>
                  <TableCell align="right">
                    {row.approval_status && (
                      <Chip
                        label={tcinStatusObj.text}
                        style={{
                          backgroundColor: tcinStatusObj.bgcolor,
                          color: tcinStatusObj.txtcolor,
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {(row.approval_status === 'Inputs required' ||
                      row.approval_status === undefined) && (
                      <Fab
                        onClick={() =>
                          deleteFile(row.external_asset_tracking_summary_id)
                        }
                        size="small"
                        style={{ color: '#ac0100', backgroundColor: 'white' }}
                      >
                        <Delete />
                      </Fab>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
