import { useState, useEffect } from 'react'
import { TabHeader } from 'gga-ui-library'

//Tab data
const tabsMetaData = [
  {
    statusId: 'active',
    serverId: 'ACTIVE',
    label: 'Active',
    status: 'Active',
  },
  {
    statusId: 'carry-forward',
    serverId: 'CARRY_FORWARD',
    label: 'Carry Forward',
    status: 'Carry Forward',
  },
  {
    statusId: 'completed',
    serverId: 'COMPLETED',
    label: 'Completed',
    status: 'Completed',
  },
]

const NBrHeader = ({ getCurrentTab = () => {}, activeTab }) => {
  const [localActiveTab, setActiveTab] = useState(0)

  useEffect(() => {
    getCurrentTab(tabsMetaData[0].serverId, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTab = (index) => {
    setActiveTab(index)
    getCurrentTab(tabsMetaData[index].serverId)
  }

  useEffect(() => {
    const index = tabsMetaData.findIndex((tabs) => tabs.serverId === activeTab)
    setActiveTab(index)
  }, [activeTab])

  return (
    <div className="nbr-header">
      {/* <div>
        <span className="header-project-status-label">Project Status</span>
      </div> */}
      <div className="header-container">
        <div className="tab-header">
          <TabHeader
            tabs={tabsMetaData}
            activeTab={localActiveTab}
            updateTab={updateTab}
          />
        </div>
      </div>
    </div>
  )
}

export default NBrHeader
