import harbinger from 'harbinger'
import _ from 'lodash'

const SIGN_IN = 'auth/SIGN_IN'
const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'
const SIGN_OUT = 'auth/SIGN_OUT'
const PROMPT_SIGN_IN = 'auth/PROMPT_SIGN_IN'
const DECLINE_SIGN_IN = 'auth/DECLINE_SIGN_IN'
const ADD_PERMISSON = 'auth/ADD_PERMISSON'

export const signIn = () => ({ type: SIGN_IN }) //Required

export const signInSuccess = (payload, successCallback = () => {}) => {
  return { type: SIGN_IN_SUCCESS, payload: payload }
}

export const signOut = () => ({ type: SIGN_OUT }) //Required

export function addPermision(permission) {
  return {
    type: ADD_PERMISSON,
    payload: {
      permission: permission,
    },
  }
}

export const promptToSignIn = () => ({ type: PROMPT_SIGN_IN })

export const declineSignIn = () => ({ type: DECLINE_SIGN_IN })

// const formatAdGroups = (groups = []) => {
//   return groups.reduce((result, group) => {
//     group.split(',').forEach((attribute) => {
//       if (attribute.indexOf('CN=') === 0) result.push(attribute.split('CN=')[1])
//     })
//     return result
//   }, [])
// }

const checkPermission = (userInfo) => {
  var permission = {
    isCepSuperUser: false,
    imgCurationEdit: false,
    imgReviewUpload: false,
    imgLabelsEdit: false,
    imgReview: false,
    sizeChartsEdit: false,
    videoUpload: false,
    videoReview: false,
    cgi: false,
    longCopy: false,
    longCopySearchAndManage: false,
    longCopyBulkUpload: false,
    longCopyNarrativeScience: false,
    specMismatch: false,
    copy: false,
    vendorTcinMap: false,
    hostName: window.location.host,
    deleteCopyFeature: false,
    instantEditLongCopy: false,
    badgesEdit: false,
    prodSpecs: false,
  }
  harbinger.visitorId = userInfo.lanId
  // determine user access rights based on AD groups
  _.each(userInfo, function (userInfo) {
    var upperGroup = userInfo.memberOf
    // DO NOT DELETE YET... WAIT UNTIL USERS ARE MIGRATED OUT TO CEP-ITEMDATA
    if (_.includes(upperGroup, 'APP-CEP-IMAGES-EDIT')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      harbinger.segment = 'IDS'
    }

    //ITEM DATA SPECIALIST
    if (_.includes(upperGroup, 'APP-CEP-ITEMDATA')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      permission.imgReview = true
      harbinger.segment = 'IDS'
    }

    //ITEM DATA SPECIALIST ADVANCED
    if (_.includes(upperGroup, 'APP-CEP-ITEMDATA-ADVANCED')) {
      permission.imgCurationEdit = true
      permission.imgReviewUpload = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReview = true
      permission.videoReview = true
      harbinger.segment = 'IDS-Advanced'
    }

    //SITE MERCHANDISING
    if (_.includes(upperGroup, 'APP-CEP-SITEMERCH')) {
      permission.imgLabelsEdit = true
      permission.imgCurationEdit = true
      harbinger.segment = 'Site Merch'
    }

    // CONTENT PLANNING
    if (_.includes(upperGroup, 'APP-CEP-CONTENT')) {
      permission.imgCurationEdit = true
      permission.sizeChartsEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.videoReview = true
      permission.copy = true
      permission.imgReviewUpload = true
      permission.longCopy = true // Authorized to show Copy & Bullets link to left navigation menu
      permission.longCopySearchAndManage = true // Authorized to show Search & Manage tab on Long Copy Page
      permission.longCopyBulkUpload = true // Authorized to show Bulk Upload and Bulk Upload History tabs on Long Copy Page
      permission.longCopyNarrativeScience = true // Authorized to show Narrative Science tab on Long Copy Page
      permission.specMismatch = true // Authorized to show Spec Mismatch tab on Long Copy Page
      permission.deleteCopyFeature = true
      permission.instantEditLongCopy = true
      permission.badgesEdit = true
      permission.prodSpecs = true // Authorized to show ProdSpec link to left navigation menu
      harbinger.segment = 'Content Planning'
    }

    // CGI --- IS THIS SOMETHING WE CAN DELETE? WE ARE NOT USING INTERNAL CGI, RIGHT?
    if (_.includes(upperGroup, 'APP-CEP-CGI')) {
      permission.cgi = true
      harbinger.segment = 'Cgi'
    }

    // COPY WRITERS (INTERNAL/INDIA)
    if (_.includes(upperGroup, 'APP-CEP-COPYWRITING')) {
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      permission.longCopyNarrativeScience = true
      harbinger.segment = 'Copy Writer'
    }

    // PIPELINE TEAM OR CGI TEAM
    if (
      _.includes(upperGroup, 'ADMN-CEP-PROD') ||
      _.includes(upperGroup, 'APP-CEP-PIPELINE-TEAM')
    ) {
      permission.isCepSuperUser = true
      permission.imgCurationEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.videoReview = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      permission.longCopyNarrativeScience = true
      permission.specMismatch = true
      permission.sizeChartsEdit = true
      permission.copy = true
      permission.imgReview = true
      permission.deleteCopyFeature = true
      permission.instantEditLongCopy = true
      permission.badgesEdit = true
      permission.prodSpecs = true
      harbinger.segment = 'Pipeline Team'
    }

    // VENDOR PARTNER TYPE
    if (_.includes(upperGroup, 'APP-CEP-EXTVENDOR-UPLOAD')) {
      permission.imgCurationEdit = true
      permission.longCopy = true
      permission.longCopySearchAndManage = false
      permission.longCopyBulkUpload = true
      permission.longCopyNarrativeScience = false
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.vendorTcinMap = false
      harbinger.segment = 'Merch Vendor'
    }

    // MARKETING PARTNER TYPE
    if (_.includes(upperGroup, 'APP-CEP-MARKETING-PARTNER')) {
      permission.longCopy = true
      permission.longCopySearchAndManage = true
      permission.longCopyBulkUpload = true
      permission.longCopyNarrativeScience = false
      permission.vendorTcinMap = false
      harbinger.segment = 'Copywriter'
    }

    // SALES REP TYPE
    if (_.includes(upperGroup, 'POL-MULTI-PARTNER')) {
      permission.imgCurationEdit = true
      permission.imgLabelsEdit = true
      permission.videoUpload = true
      permission.imgReviewUpload = true
      permission.cgi = true
      permission.vendorTcinMap = false
      permission.longCopy = true
      permission.longCopySearchAndManage = false
      permission.longCopyBulkUpload = true
      permission.longCopyNarrativeScience = false
      harbinger.segment = 'Sales Rep'
    }
  })
  return permission
}

export const formatUserInfo = (info = {}) => {
  return {
    email: info.email,
    firstName: info.firstName,
    lanId: info.lanId,
    lastName: info.lastName,
    memberOf: info.memberOf,
    permission: checkPermission(info),
    accessToken: info.accessToken,
    xIdToken: localStorage.id_token,
    displayName: info.displayname,
    company: info.company,
  }
}
