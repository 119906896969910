const copy = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData('Text', text)
  }
  if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy')
    } catch (ex) {
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export const copyToClipboardNew = (text = '') => {
  // Create a textarea to select text

  const textarea = document.createElement('textarea')
  textarea.value = text
  textarea.style.position = 'fixed'
  document.body.appendChild(textarea)

  // Clear any pre existing selections
  window.getSelection().removeAllRanges()
  // Select the text inside the textarea
  let range = document.createRange()
  range.selectNode(textarea)
  window.getSelection().addRange(range)

  try {
    // let successful = document.execCommand('copy')
    //let msg = successful ? 'text was copied' : 'Unable to copy'
  } catch (err) {
    // console.log('Unable to copy');
  } finally {
    document.body.removeChild(textarea)
  }
}

export default copy
