/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import apiConfig from '../../config/apiConfig'

export const getTcinTaskDetails = ({
  tcin,
  taskId,
  callbackError = () => {},
  callbackSuccess = () => {},
}) => {
  const TCIN = tcin
  const TaskId = taskId
  axios({
    method: 'get',
    url: `${apiConfig.task.taskTcinDetails}${TaskId}/tcins/${TCIN}`,
  })
    .then((response) => {
      callbackSuccess(response.data)
    })
    .catch((error) => {
      console.log('error', error)
    })
}

export const postComment = ({
  taskId,
  commentText,
  fileId,
  fileName,
  comment_reference_ids,
  callbackSuccess = () => {},
  callbackError = () => {},
}) => {
  axios({
    method: 'post',
    url: apiConfig.task.addComment,
    data: {
      task_id: taskId,
      comment_text: commentText,
      file_id: fileId,
      file_name: fileName,
      comment_reference_ids: comment_reference_ids,
    },
  })
    .then((response) => {
      callbackSuccess(response.data)
    })
    .catch((error) => {
      console.log('error', error)
      callbackError()
    })
}
