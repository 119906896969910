import { useContext, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFiles } from 'gga-ui-components'
import { forEach } from 'lodash'
import JSZip from 'jszip'

import apiConfig from '../../config/apiConfig'
import { updateTask } from './useTaskSearch'
import { showNotification } from '../Notifications/NotificationActionCreator'
import { TaskContext } from './TaskContext'
import { TASK_FILE_STATUS, REMOVE_FILE } from './TaskActionType'
import { setPromiseConcurrent } from '../../helpers/utils'
import { Worker } from '../../helpers/UploadWorker.worker'
import WebWorker from '../../helpers/Webworker'

const CancelToken = axios.CancelToken
setPromiseConcurrent()

const setReloadStopper = (on) => {
  window.onbeforeunload = function () {
    return on
  }
}

const validateUploadFileName = (fileName, tcin) => {
  const capturingRegex = /_texturing.zip/i
  var match = fileName.match(capturingRegex)
  // const fileTCIN = fileName.search(tcin) !== -1
  return Boolean(match && fileName === match[0])
}

const UploadFilesContainer = ({ item, getTaskList, selectedPhase }) => {
  const dispatch = useDispatch()
  const [store, dispatchContext] = useContext(TaskContext)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [maxFile, setMaxFile] = useState()
  const [tiffFolder, setTiffFolder] = useState([])
  const [isZipping, setIsZipping] = useState(false)
  const [tiffFolderName, setTiffFolderName] = useState('')

  const {
    auth: { accessToken, lanId },
    xIdToken,
  } = useSelector((state) => state.auth)

  const isASB = selectedPhase === 'AGENCY_SCENE_BUILDING'

  const setFileStatesList = (fileStatesList) => {
    dispatchContext({
      type: TASK_FILE_STATUS,
      payload: { tcin: item.tcin, fileStatesList },
    })
  }

  const fileStatesList = store.taskFileStatus[item.tcin] || {}

  const generateFileStatus = ({
    name,
    progress,
    status,
    fileId,
    cancelToken = null,
  }) => {
    setFileStatesList({
      [name]: { name, progress, status, fileId, cancelToken },
    })
  }

  const removeFiles = (key) => {
    try {
      dispatchContext({ type: REMOVE_FILE, payload: { key, tcin: item.tcin } })
      const source = fileStatesList[key].cancelToken
      if (source) source.cancel()
      // Start - Persisting code - LocalStorage
      const taskFileStatus =
        JSON.parse(localStorage.getItem('taskFileStatus')) || {}

      delete taskFileStatus[item.tcin][key]
      localStorage.setItem('taskFileStatus', JSON.stringify(taskFileStatus))
      // End - Persisting code - LocalStorage
    } catch (error) {
      console.log('removeFiles => ', error)
    }
  }

  const convertZip = async (name) => {
    const zip = new JSZip()
    const folder = zip.folder(name)
    folder.file(maxFile.name, maxFile, { base64: true })
    for (let i = 0; i < tiffFolder.length; i++) {
      const item = tiffFolder[i]
      folder.file(item.file.name, item.file, { binary: true })
    }
    const response = await zip.generateAsync({ type: 'blob' })
    return response
  }

  const uploadFileHandler = (file, type, folderName, overRideASB) => {
    if (type === 'MAX') {
      setMaxFile(file[0].file)
    } else if (type === 'TIFF') {
      if (file.length) {
        if (folderName) {
          setTiffFolderName(() => folderName)
        } else {
          const folderName = file[0].file.name
          setTiffFolderName(() => folderName)
        }
      }
      setTiffFolder(() => [...file])
    }

    if (isASB && !overRideASB) {
      return
    }

    if (!isASB && !validateUploadFileName(file.file.name, item.tcin)) {
      dispatch(
        showNotification(
          true,
          'File name should match "_texturing.zip"',
          'error'
        )
      )
      return
    }

    //set Reload stopper
    setReloadStopper('stop')
    const requestSource = CancelToken.source()

    const envUrl =
      apiConfig.task.uploadApi + '/chunk_uploads/start?key=' + apiConfig.apiKey
    generateFileStatus({
      progress: 0,
      status: 'waiting',
      name: file.file.name,
      cancelToken: requestSource,
    })
    const postData = {
      targets: ['INTERNAL'],
      original_file_name: file.file.name,
      ...(isASB && { tcin: item.tcin }),
    }
    axios
      .post(envUrl, {
        ...postData,
      })
      .then((res) => {
        // chunkStartResponse["upload_id"]
        generateFileStatus({
          progress: 1,
          status: 'in_progress',
          name: file.file.name,
          cancelToken: requestSource,
        })
        if (isASB) {
          const worker = new WebWorker(Worker)
          const api =
            apiConfig.task.uploadApi + '/chunk_uploads?key=' + apiConfig.apiKey
          const workerApiData = {
            file: file.file,
            uploadId: res.data.upload_id,
            api,
            accessToken,
            lanId,
            xIdToken,
            xApiKey: apiConfig.apiKey,
          }

          worker.postMessage({
            type: 'start',
            data: workerApiData,
          })

          // Listen for messages from the Web Worker
          worker.onmessage = (event) => {
            const { type, data } = event.data
            const percentageComplete =
              (data.chunkNumber / data.totalChunks) * 100 - 1
            if (type === 'progress') {
              if (data.chunkNumber !== data.totalChunks) {
                generateFileStatus({
                  progress: percentageComplete,
                  status: 'in_progress',
                  name: file.file.name,
                  cancelToken: requestSource,
                })
              }
            } else if (type === 'complete') {
              endUpload({
                uploadId: data.uploadId,
                file: file.file,
                numberOfChunks: data.totalChunks,
                chunkSize: 2 * 1024 * 1024,
                sizeOfFile: file.file.size,
                requestSource,
              })
              worker.terminate() // Terminate the Web Worker after completion
            } else if (type === 'fail') {
              setReloadStopper()
              generateFileStatus({
                progress: percentageComplete,
                status: 'error',
                name: file.file.name,
                cancelToken: requestSource,
              })
              worker.terminate()
            }
          }
        } else {
          chunkUpload({
            uploadId: res.data.upload_id,
            file: file.file,
            requestSource,
          })
        }
      })
      .catch((error) => {
        setReloadStopper()
        generateFileStatus({
          progress: 15,
          status: 'error',
          name: file.file.name,
          fileId: null,
        })
      })
  }

  const chunkUpload = async ({ uploadId, file, requestSource }) => {
    const source = requestSource
    // setup chunked load parameters
    const chunkSize = 2 * 1024 * 1024 // 2mb
    const sizeOfFile = file.size
    const numberOfChunks = Math.ceil(sizeOfFile / chunkSize)
    // send file chunk by chunk
    const chunksPromise = []
    for (let i = 0; i < numberOfChunks; i++) {
      const envUrl =
        apiConfig.task.uploadApi + '/chunk_uploads?key=' + apiConfig.apiKey

      const chunkedFormData = new FormData()
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize)

      chunkedFormData.append(
        'chunkUploadRequest',
        new Blob(
          [
            JSON.stringify({
              upload_id: uploadId,
              file_name: file.name,
              chunk_number: i,
              total_number_of_chunks: numberOfChunks,
              chunk_size: chunkSize,
              total_file_size: sizeOfFile,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      )
      chunkedFormData.append('file', chunk)
      chunksPromise.push(
        () =>
          new Promise((resolve) =>
            axios
              .post(envUrl, chunkedFormData, {
                cancelToken: source.token,
              })
              .then(() => {
                const percentageComplete = ((i + 1) / numberOfChunks) * 100 - 1
                generateFileStatus({
                  progress: percentageComplete,
                  status: 'in_progress',
                  name: file.name,
                  cancelToken: requestSource,
                })
                resolve()
              })
              .catch((error) => {
                setReloadStopper()
                generateFileStatus({
                  progress: 19,
                  status: 'error',
                  name: file.name,
                  fileId: null,
                })
              })
          )
      )
    }

    Promise.allConcurrent(5)(chunksPromise).then((res) => {
      endUpload({
        uploadId,
        file,
        numberOfChunks,
        chunkSize,
        sizeOfFile,
        requestSource,
      })
    })
  }

  const endUpload = ({
    uploadId,
    file,
    numberOfChunks,
    chunkSize,
    sizeOfFile,
    requestSource,
  }) => {
    const envUrl =
      apiConfig.task.uploadApi + '/chunk_uploads/end?key=' + apiConfig.apiKey
    const source = requestSource

    axios
      .post(
        envUrl,
        {
          upload_id: uploadId,
          file_name: file.name,
          targets: ['INTERNAL'],
          total_number_of_chunks: numberOfChunks,
          chunk_size: chunkSize,
          total_file_size: sizeOfFile,
          content_type: 'image',
          metadata: '{"key": "value"}',
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        const statusData = {
          progress: 100,
          status: 'success',
          name: file.name,
          fileId: res.data.job_id,
        }
        generateFileStatus(statusData)
        setReloadStopper()

        // Start - Persisting code - LocalStorage
        const successData = {
          [file.name]: statusData,
        }
        forEach(fileStatesList, (val, key) => {
          if (val.status === 'success') {
            successData[key] = val
          }
        })
        const taskFileStatus =
          JSON.parse(localStorage.getItem('taskFileStatus')) || {}

        taskFileStatus[item.tcin] = successData
        localStorage.setItem('taskFileStatus', JSON.stringify(taskFileStatus))
        // End - Persisting code - LocalStorage
      })
      .catch((error) => {
        setReloadStopper()
        generateFileStatus({
          progress: 27,
          status: 'error',
          name: file.name,
          fileId: null,
        })
      })
  }

  const sendForReviewHandler = () => {
    setSubmitLoading(true)
    updateTask({
      item,
      fileStatesList,
      successCallBack: () => {
        dispatch(
          showNotification(
            true,
            'Task was successfully submitted for review',
            'success'
          )
        )
        getTaskList(true)
        setSubmitLoading(false)

        // Start - Remove file from context
        forEach(fileStatesList, (val, key) => {
          dispatchContext({
            type: REMOVE_FILE,
            payload: { key, tcin: item.tcin },
          })
        })
        // END - Remove file from context

        // Start - Persisting code - LocalStorage
        const taskFileStatus =
          JSON.parse(localStorage.getItem('taskFileStatus')) || {}

        delete taskFileStatus[item.tcin]
        localStorage.setItem('taskFileStatus', JSON.stringify(taskFileStatus))
        // End - Persisting code - LocalStorage
      },
      errorCallBack: () => {
        dispatch(showNotification(true, 'Something Went Wrong', 'error'))
        setSubmitLoading(false)
      },
    })
  }

  const setStatus = (status, delay) => {
    const postData = {
      status: {
        progress: 0,
        status: 'waiting',
        name: status,
        fileId: 'progress',
      },
    }
    if (delay) {
      setTimeout(() => {
        setFileStatesList(postData)
      }, 2000)
    } else {
      setFileStatesList(postData)
    }
  }

  const zipAndUploadFiles = async () => {
    setIsZipping(true)
    setStatus('Preparing to zip the files...')
    setStatus('Zipping the files...', 2000)
    const name = `_Scene.zip`
    const response = await convertZip(name)
    const file = { file: new File([response], name) }
    setStatus('Finishing up the zipping process...')
    setStatus('Starting the upload process...', 2000)
    setTimeout(() => {
      removeFiles('status')
      uploadFileHandler(file, null, null, true)
      setMaxFile(() => undefined)
      setTiffFolder(() => [])
      setTiffFolderName(() => '')
      setIsZipping(false)
    }, 3000)
  }

  const onCancelUpload = () => {
    forEach(fileStatesList, (fileStatus) => {
      const source = fileStatus.cancelToken
      if (source) source.cancel()
    })
  }

  let props = {
    tcin: item.tcin,
    uploadFileHandler: uploadFileHandler,
    fileStatesList: fileStatesList,
    removeFiles: removeFiles,
    onSubmit: sendForReviewHandler,
    onCancelUpload: onCancelUpload,
    submitLoading: submitLoading,
    selectedPhase: selectedPhase,
    dropzoneArray: [
      {
        text: 'Drag and drop or browse',
        fileType: 'DEFAULT',
        acceptedFileTypes: ['.zip', 'application/zip'],
        useCustomComponent: true,
      },
    ],
    ...(isASB
      ? {
          dropzoneArray: [
            {
              text: 'Drag & Drop zipped .max file',
              fileType: 'MAX',
              acceptedFileTypes: ['.zip'],
              useCustomComponent: true,
            },
            {
              text: (
                <>
                  Drag & Drop reference folder <br /> (formats: jpeg, TIFF, png)
                </>
              ),
              fileType: 'TIFF',
              acceptedFileTypes: ['.tiff', '.jpg', '.jpeg', '.png', '.tif'],
              useCustomComponent: true,
            },
          ],
          filesLimit: 100,
          getAllFilesInTheDroppedFolder: true,
          showUpload: true,
          maxFileName: isZipping ? '' : maxFile?.name,
          tiffFolderName: isZipping ? '' : tiffFolderName,
          deleteTiffFolder: () => {
            setTiffFolder([])
            setTiffFolderName()
          },
          deleteMaxFolder: () => {
            setMaxFile()
          },
          zipAndUploadFiles,
        }
      : {}),
    maxFileSize: 15 * 1024 * 1024 * 1024, //15GB
  }

  return <UploadFiles {...props} />
}

export default UploadFilesContainer
