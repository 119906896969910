/* eslint-disable*/
import React, { useState, useRef, useCallback, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  withStyles,
  Box,
  LinearProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { Chip } from '@mui/material'
import FileNotAvailableIcon from '../assets/images/NoFile.svg'
import noResultsImage from '../assets/images/No-results.svg'
import NBrTcinDetailsModal from './NBrTcinDetailsModal'
import { useDispatch, useSelector } from 'react-redux'
import NBrUploaded from './NBrUploaded'
import { Update } from '@material-ui/icons'
import {
  fetchTcins,
  updateTcinStatus,
} from '../store/NationalBrand/ActionCreator'
import Viewer from '../../components/Sandbox/Viewer'
import ViewButton from './ViewButton'
import NbrRefreshAlert from './NbrRefreshAlert'

const colorScheme = (value) => {
  switch (value) {
    case 'IN_PROGRESS':
    case 'In Progress':
    case 'In progress':
      return {
        backgroundColor: 'rgb(230,212,255) !important',
        color: '#000 !important',
      }
    case 'AWAITING_REVIEW':
    case 'Awaiting review':
    case 'Awaiting Review':
      return {
        backgroundColor: 'rgb(255,207,131) !important',
        color: '#000 !important',
      }
    case 'ASSINGED':
    case 'Assigned':
      return {
        backgroundColor: 'rgb(219, 242, 255) !important',
        color: '#000 !important',
      }
    case 'REWORK':
    case 'Rework':
      return {
        backgroundColor: '  #B85300 !important',
        color: '#FFFFFF !important',
      }
    case 'APPROVED':
    case 'Approved':
      return {
        backgroundColor: '  #008300 !important',
        color: '#FFFFFF !important',
      }
    default:
      return {
        backgroundColor: '#E6E6E6 !important',
        color: '#333333 !important',
      }
  }
}

const showViewerStatusIndex = {
  AWAITING_REVIEW: true,
  'Awaiting review': true,
  REWORK: true,
  Rework: true,
  APPROVED: true,
  Approved: true,
  IN_PROGRESS: false,
  'In Progress': false,
  'In progress': false,
  Unassigned: false,
}

export const StyledChip = (props) => {
  const CustomStyledChip = withStyles({
    root: { ...colorScheme(props.label) },
  })(Chip)
  return <CustomStyledChip {...props} />
}

const NBrTcinTable = (props) => {
  const [openThreeDViewer, setOpenThreeDViewer] = useState({
    open: false,
    currentIndex: -1,
    tcin: '',
    taskId: '',
    rtaStatus: '',
    phaseId: '',
    serviceType: '',
  })
  const [pageNum, setPageNum] = useState(1)
  const nullRef = useRef(null)
  const dispatch = useDispatch()
  const [rightLoading, setRightLoading] = useState(false)
  const [leftLoading, setLeftLoading] = useState(false)
  const [validViewerTcins, setValidViewerTcins] = useState([])
  const [findServiceType, setFindService] = useState(false)
  const pageSize = 30

  const {
    tcinList,
    totalTcins,
    tcinLoading: loading,
  } = useSelector((state) => state.nationalBrand)

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tcinList.length) {
      const list = JSON.parse(JSON.stringify(tcinList))
      const filteredList = list.filter((tcin) => {
        return (
          tcin?.production_phase?.filter(
            (task) =>
              (task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA') &&
              showViewerStatusIndex[task.status_name]
          ).length > 0
        )
      })
      setValidViewerTcins(filteredList)

      let serviceFound = false
      for (let i = 0; i < list.length; i++) {
        if (list[i] && list[i].target_plus_service_type !== null) {
          serviceFound = true
          break
        }
      }
      setFindService(serviceFound)
    }
  }, [tcinList])

  useEffect(() => {
    if (props.searchValue) {
      fetchList()
    }
  }, [props.searchValue])

  useEffect(() => {
    if (props.refreshList === true || props.refreshList === false) {
      fetchList()
    }
    // eslint-disable-next-line
  }, [props.refreshList])

  const fetchList = () => {
    const list = JSON.parse(JSON.stringify(tcinList))
    for (let i = 0; i < list.length; i++) {
      if (list[i] && list[i].target_plus_service_type !== null) {
        setFindService(true)
      }
    }
    setPageNum(1)
    getData(1, props.searchValue, false)
  }

  const getData = (
    page = pageNum,
    keyword = props.searchValue,
    addMore = false
  ) => {
    const postData = {
      project_uuid: props.projectId,
      type: '',
      production_phase: false,
      filters: {},
      search: {
        keyword,
        type: '',
      },
      page,
      count: pageSize,
      sort_order: '',
      sort_by: '',
      status_id: '',
      phase_id: 'NATIONAL_BRAND',
    }

    dispatch(fetchTcins(postData, addMore))
  }

  const observer = useRef()

  const lastTaskElementRef = useCallback(
    (node) => {
      // if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalTcins > tcinList.length) {
          setPageNum((page) => page + 1)
          const page = pageNum + 1
          getData(page, props.searchValue, true)
        }
      })
      if (node) observer.current.observe(node)
    },
    // eslint-disable-next-line
    [loading, totalTcins, tcinList]
  )

  const moveRight = () => {
    setRightLoading(true)
    const { currentIndex } = openThreeDViewer
    const tcinDetails = tcinList[currentIndex]
    const filteredIndex = validViewerTcins.findIndex(
      (tcin) =>
        tcin?.multi_task_tcin_listing_pk?.tcin ===
        tcinDetails?.multi_task_tcin_listing_pk?.tcin
    )
    if (filteredIndex > -1) {
      const nextIndex = filteredIndex + 1
      const nextTcin = validViewerTcins[nextIndex]

      if (nextTcin) {
        const status = nextTcin?.production_phase?.filter(
          (task) => task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA'
        )
        const tcinListIndex = tcinList.findIndex(
          (tcin) =>
            tcin?.multi_task_tcin_listing_pk?.tcin ===
            nextTcin?.multi_task_tcin_listing_pk?.tcin
        )
        setOpenThreeDViewer((data) => ({
          ...data,
          currentIndex: tcinListIndex,
          tcin: nextTcin?.multi_task_tcin_listing_pk?.tcin,
          taskId: status && status.length ? status[0].task_uuid : '',
          rtaStatus: status && status.length ? status[0].status_name : 'N/A',
          phaseId: status && status.length ? status[0].phase_id : '',
          serviceType: nextTcin?.target_plus_service_type,
        }))
      }
    }
    setRightLoading(false)
  }

  const moveLeft = () => {
    setLeftLoading(true)
    const { currentIndex } = openThreeDViewer
    const tcinDetails = tcinList[currentIndex]
    const filteredIndex = validViewerTcins.findIndex(
      (tcin) =>
        tcin?.multi_task_tcin_listing_pk?.tcin ===
        tcinDetails?.multi_task_tcin_listing_pk?.tcin
    )
    if (filteredIndex > -1) {
      const prevIndex = filteredIndex - 1
      const prevTcin = validViewerTcins[prevIndex]
      if (prevTcin) {
        const status = prevTcin?.production_phase?.filter(
          (task) => task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA'
        )
        const tcinListIndex = tcinList.findIndex(
          (tcin) =>
            tcin?.multi_task_tcin_listing_pk?.tcin ===
            prevTcin?.multi_task_tcin_listing_pk?.tcin
        )
        setOpenThreeDViewer((data) => ({
          ...data,
          currentIndex: tcinListIndex,
          tcin: prevTcin?.multi_task_tcin_listing_pk?.tcin,
          taskId: status && status.length ? status[0].task_uuid : '',
          rtaStatus: status && status.length ? status[0].status_name : 'N/A',
          phaseId: status && status.length ? status[0].phase_id : '',
          serviceType: prevTcin?.target_plus_service_type,
        }))
      }
    }
    setLeftLoading(false)
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#F7F7F7',
      color: '#666666',
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.2)',
      height: '60px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell)

  //to open and close Tcin Details Modal
  const [open, setOpen] = useState(false)

  const [selectedRow, setSelectedRow] = useState([])

  // to get selected row for Tcin Details Id
  const [selectedRowId, setSelectedRowId] = useState(0)

  // handler function to set state for Tcin Modal id
  const handleOpenSideBar = (id) => {
    setSelectedRowId(id)
    let row = tcinList.filter(
      (item) => item.multi_task_tcin_listing_pk.tcin === id
    )
    setSelectedRow(row)
    setOpen(true)
  }

  const updateTcinList = (reviewResponse) => {
    const data = {
      tcin: reviewResponse.tcin,
      status: reviewResponse.status_id,
    }
    dispatch(updateTcinStatus(data))
  }

  const refreshData = () => {
    fetchList()
  }

  function formatString(text) {
    if (text) {
      const placeholder = 'XXX'
      const tempString = text.replace(/3D/g, placeholder)
      const spaced = tempString
        .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
        .replace(/&/g, ' & ')
      return spaced.replace(new RegExp(placeholder, 'g'), '3D ').trim()
    }
  }

  return (
    <Box
      sx={{
        padding: '160px 54px 30px 54px',
        height: '90vh',
        '& .Mui-selected': { backgroundColor: '#0032f514' },
        overflow: 'scroll',
      }}
    >
      <NbrRefreshAlert refreshData={refreshData} />
      <Box>{loading && <LinearProgress />}</Box>
      <TableContainer style={{ maxWidth: '100%', maxHeight: '650px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>TCIN</StyledTableCell>
              {findServiceType && (
                <StyledTableCell>3D Service Type</StyledTableCell>
              )}
              <StyledTableCell>Input Type</StyledTableCell>
              <StyledTableCell>Files</StyledTableCell>
              <StyledTableCell>RTA Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tcinList.map((tcin, i) => {
              const status = tcin?.production_phase?.filter(
                (task) =>
                  task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA'
              )
              const statusName =
                status && status.length ? status[0].status_name : 'N/A'
              return (
                <TableRow
                  hover
                  key={i}
                  ref={tcinList.length === i + 1 ? lastTaskElementRef : nullRef}
                  onClick={() =>
                    handleOpenSideBar(tcin?.multi_task_tcin_listing_pk?.tcin)
                  }
                  selected={tcin === selectedRow[0]}
                >
                  <TableCell>
                    {tcin?.multi_task_tcin_listing_pk?.tcin}
                  </TableCell>
                  {findServiceType && (
                    <TableCell>
                      <div>
                        <Chip
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            backgroundColor: '#E8EEFC',
                          }}
                          label={formatString(tcin.target_plus_service_type)}
                        />
                      </div>
                    </TableCell>
                  )}
                  <TableCell>{tcin?.input_type}</TableCell>
                  <TableCell align="left">
                    {tcin.agency_file_name || tcin.agency_job_id ? (
                      <NBrUploaded
                        tcinId={tcin?.multi_task_tcin_listing_pk?.tcin}
                        projectId={props.projectId}
                      />
                    ) : tcin.agency_file_upload_status === 'SUCCESSFUL' ? (
                      <Box className="flex-box no-file">
                        <Update
                          style={{ color: 'rgb(51, 108, 217)', height: '24px' }}
                        />
                        <span>Upload in progress</span>
                      </Box>
                    ) : (
                      <Box className="flex-box no-file">
                        <img
                          src={FileNotAvailableIcon}
                          alt="file-upload"
                          className="download-file-img"
                        />
                        <span>No files available</span>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <StyledChip label={statusName} />
                  </TableCell>
                  <TableCell>
                    {showViewerStatusIndex[statusName] && (
                      <ViewButton
                        onClick={(e) => {
                          e.stopPropagation()
                          const phaseId =
                            status && status.length ? status[0].phase_id : 'RTA'
                          setOpenThreeDViewer({
                            open: true,
                            currentIndex: i,
                            tcin: tcin?.multi_task_tcin_listing_pk?.tcin,
                            taskId:
                              status && status.length
                                ? status[0].task_uuid
                                : '',
                            rtaStatus:
                              status && status.length
                                ? status[0].status_name
                                : 'N/A',
                            phaseId,
                            serviceType:
                              tcin.target_plus_service_type &&
                              tcin.target_plus_service_type,
                          })
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <NBrTcinDetailsModal
          tcinId={selectedRowId}
          selectedRow={selectedRow}
          open={open}
          setOpen={setOpen}
          projectId={props.projectId}
        />
      )}
      {openThreeDViewer.open && (
        <Viewer
          open={openThreeDViewer.open}
          handleClose={() =>
            setOpenThreeDViewer({
              open: false,
              currentIndex: -1,
              tcin: '',
              taskId: '',
              rtaStatus: '',
              phaseId: '',
              serviceType: '',
            })
          }
          moveLeft={moveLeft}
          moveRight={moveRight}
          tcin={openThreeDViewer.tcin}
          projectId={props.projectId}
          taskId={openThreeDViewer.taskId}
          rtaStatus={openThreeDViewer.rtaStatus}
          updateTcinList={updateTcinList}
          leftLoading={leftLoading}
          rightLoading={rightLoading}
          phaseId={openThreeDViewer.phaseId}
          serviceType={openThreeDViewer.serviceType}
        />
      )}
      {tcinList.length === 0 && !loading && (
        <Grid className="dashboard-noResult">
          <img src={noResultsImage} alt="Project" height="56" />
          <Typography>No Results</Typography>
          <Typography>You haven't added any TCINs yet!</Typography>
        </Grid>
      )}
    </Box>
  )
}

export default React.memo(NBrTcinTable)
