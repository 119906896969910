import { apiConfig } from '../config'

export const authConfig = {
  clientId: apiConfig.auth.clientId,
  popupOptions: apiConfig.auth.popupOptions,
  loginRedirect: apiConfig.auth.loginRedirect,
  responseType: apiConfig.auth.responseType,
  scope: apiConfig.auth.scope,
  storageType: apiConfig.auth.storageType,
  tokenType: apiConfig.auth.tokenType,
}
