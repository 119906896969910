import styled from 'styled-components'
import { Grid, IconButton } from '@material-ui/core'

export const ActionContainer = styled.span``
export const DrawerContent = styled.div`
  padding: 20px;
  display: block;
`
export const CloseButton = styled(IconButton)`
  align-self: flex-start;
`

export const MainContent = styled.div`
  display: inline-block;
  padding: 10px 8px 10px 36px;
  margin-top: 0px;
  width: 100%;
`
export const CycleSection = styled.div`
  display: block;
  font-size: 20px;
  font-weight: 300;
  color: #333;
  padding: 0 0 2px 0;
`
export const TcinSection = styled.div`
  display: block;
  font-size: 25px;
  font-weight: 600;
  color: #333;
  padding: 0 0 20px 0;
`
export const IconSection = styled.div`
  display: inline-flex;
`
export const SectionTitle = styled.div`
  display: block;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin: 0px 0 10px 8px;
`
export const TcinListItems = styled.div``

export const TcinDetailSection = styled(Grid)`
  display: inline-flex;
  margin: 36px 0 0 163px;
  position: absolute;
`

export const TcinBlock = styled(Grid)`
  padding: 0 0 30px 0;
`

export const TaskBlock = styled(Grid)`
  padding: 30px 0 0 0;
`
export const SectionTaskTitle = styled.div`
  display: block;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  padding-bottom: 15px;
`
export const SectionCommentTitle = styled.div`
  display: block;
  font-size: 16px;
  color: #333;
  font-weight: bold;
`
export const LoaderStyle = styled.div`
  margin: 10% 50%;
`
export const FileListContainer = styled.section`
  margin-top: 30px;
`
