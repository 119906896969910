import { Modal } from '@material-ui/core'
const ReModal = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="modal"
    >
      {children}
    </Modal>
  )
}

export default ReModal
