import React from 'react'
import { shape, bool, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'
import { showNotification } from './NotificationActionCreator'
import { IconButton, SnackbarContent } from '@material-ui/core'
import { Close, CheckCircle, HighlightOff } from '@material-ui/icons'

export class Notifications extends React.Component {
  static propTypes = {
    notificationActions: shape({
      showNotification: func,
    }),
    notificationIsShown: bool,
    notificationMessage: string,
  }

  constructor(props) {
    super(props)
    this.state = {
      message: undefined, // Store message in state so text doesn't disappear during animation
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notificationMessage) {
      this.setState({
        message: nextProps.notificationMessage,
      })
    }
  }

  render() {
    const { notificationIsShown, notificationType = '' } = this.props
    const { message } = this.state
    const isFolderError = typeof message === 'object'

    const style = {
      color: 'black',
    }
    let preMessageIcon = ''
    if (notificationType === 'success') {
      style.backgroundColor = '#e0f4e0'
      preMessageIcon = (
        <CheckCircle
          fontSize="small"
          style={{ color: 'green', verticalAlign: 'bottom' }}
        />
      )
    }
    if (notificationType === 'error') {
      style.backgroundColor = '#ffcccc'
      preMessageIcon = (
        <HighlightOff style={{ color: 'red', verticalAlign: 'bottom' }} />
      )
    }

    const messageElement = (
      <div>
        {preMessageIcon} {message}
      </div>
    )
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notificationIsShown}
        onClose={this.handleRequestClose}
        autoHideDuration={isFolderError ? 8000 : 4000}
      >
        <SnackbarContent
          message={messageElement}
          style={style}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={this.handleRequestClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
    )
  }

  handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.showNotification(false)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showNotification }, dispatch)

const mapStateToProps = (state) => {
  const { notification } = state
  const { isShown, message, type } = notification
  return {
    notificationIsShown: isShown,
    notificationMessage: message,
    notificationType: type,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
