import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  infoHeader: {
    height: '65px',
  },
  infoHeaderText: {
    fontSize: '16px',
    color: '#333',
    fontWeight: 'bold',
  },
  headerTextUpper: {
    fontWeight: 400,
    fontSize: '20px',
    marginBottom: '2px',
  },
  headerTextLower: {
    fontWeight: 300,
    fontSize: '13px',
  },
  infoBody: {
    margin: '16px',
    height: 'auto',
  },
  infoText: {
    color: '#999',
    fontSize: '12px',
    width: '20%',
  },
  infoTextRight: {
    fontSize: '12px',
    fontWeight: 300,
    fontStyle: 'normal',
    color: '#000',
    marginRight: '24px',
    wordWrap: 'break-word',
    width: '74%',
    lineHeight: '15px',
  },
  bottomSpace: {
    marginBottom: '16px',
    display: 'flex',
  },
})

function TcinDetail({ ...props }) {
  const { classes, rowData } = props
  const { tcin, class_name: nameOfClass, department, division } = rowData
  const prodDescr =
    rowData &&
    rowData.prodDesc.props.title !== null &&
    rowData.prodDesc.props.title !== ''
      ? decodeHtmlCharCodes(rowData.prodDesc.props.title)
      : ''
  return (
    <div>
      <div className={classes.infoHeader}>
        <div className={classes.infoHeaderText + ' ' + classes.headerTextUpper}>
          Item Information
        </div>
        <div className={classes.infoHeaderText + ' ' + classes.headerTextLower}>
          Please verify you're supplying files for the correct item.
        </div>
      </div>
      <div className={classes.infoBody}>
        <div className={classes.bottomSpace}>
          <div className={classes.infoText}>TCIN</div>
          <div className={classes.infoTextRight}>{tcin}</div>
        </div>
        <div className={classes.bottomSpace}>
          <div className={classes.infoText}>Description</div>
          <div className={classes.infoTextRight}>{prodDescr}</div>
        </div>
        <div className={classes.bottomSpace}>
          <div className={classes.infoText}>Division</div>
          <div className={classes.infoTextRight}>
            {division && division !== null ? division : ''}
          </div>
        </div>
        <div className={classes.bottomSpace}>
          <div className={classes.infoText}>Department</div>
          <div className={classes.infoTextRight}>
            {department && department !== null ? department : ''}
          </div>
        </div>
        <div className={classes.bottomSpace}>
          <div className={classes.infoText}>Class</div>
          <div className={classes.infoTextRight}>
            {nameOfClass && nameOfClass !== null ? nameOfClass : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

const decodeHtmlCharCodes = (str) => {
  return str
    .toString()
    .replace(/(&#(\d+);)/g, function (match, capture, charCode) {
      return String.fromCharCode(charCode)
    })
}

export default withStyles(styles)(TcinDetail)
