import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReModal from '../../components/Modal/Modal'
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
//style
import './NbrStyles/Style.scss'
import { style } from './NbrStyles/NBrStyle'
//Actions
import { createProject } from '../store/NationalBrand/ActionCreator'
import { showNotification } from '../Notifications/NotificationActionCreator'

const useStyles = makeStyles(() => ({
  create: {
    marginTop: '17px',
    width: '67px',
    height: '36px',
    background: '#cc0000',
    borderRadius: '4px',
    color: 'white',
    position: 'absolute',
    textTransform: 'capitalize',
    bottom: '12px',
    right: '1rem',
    '&:hover': {
      backgroundColor: '#cc0000',
    },
  },
}))

const NBrProjectCreation = ({
  openCreateForm,
  setOpenCreateForm,
  projectCreated,
}) => {
  const dispatch = useDispatch()
  const [radioValue, setRadioValue] = useState('RTA')
  const [projectName, setProjectName] = useState('')
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const [error, setError] = useState('')
  const handleRadioButton = (e) => {
    setRadioValue(e.target.value)
  }

  const classes = useStyles()

  const handleCreateProject = (event) => {
    event.preventDefault()
    if (projectName.length > 0) {
      // for adding RTA upfront
      let payloadString = radioValue + '_' + projectName
      if (nbrVendorConfirm && nbrVendorConfirm.vendor_id) {
        let vendorId = nbrVendorConfirm.vendor_id
        let callbackSuccess = () => projectCreated(true)
        dispatch(createProject(payloadString, vendorId, callbackSuccess))
      }
      setOpenCreateForm(false)
    } else {
      dispatch(
        showNotification(true, 'Please Check Project Name Again', 'error')
      )
    }
    setProjectName('')
  }

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value)

    if (!/^\w+$/.test(event.target.value) && event.target.value.length > 0) {
      setError(
        'Use only letters, numbers & underscores. No special characters are allowed!'
      )
    } else if (
      /^_+$/.test(event.target.value) &&
      event.target.value.length > 0
    ) {
      setError("Project name having only underscore(s) can't be created!")
    } else {
      setError('')
    }
  }

  return (
    <ReModal open={openCreateForm}>
      <Box sx={style} className="project-modal">
        <div className="main-contain">
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Create new project
          </Typography>
          <IconButton onClick={() => setOpenCreateForm(false)}>
            <Clear />
          </IconButton>
        </div>
        <form onSubmit={handleCreateProject}>
          {/* /Adding radio as feedback -------/ */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioValue}
            onChange={handleRadioButton}
          >
            <FormControlLabel
              checked={true}
              value="RTA"
              control={<Radio />}
              label="RTA"
            />
          </RadioGroup>
          <TextField
            value={projectName}
            id="filled-basic"
            error={error ? true : false}
            label="Project name"
            variant="filled"
            helperText={error ? error : ''}
            onChange={handleProjectNameChange}
            inputProps={{
              maxLength: 35,
            }}
          />
          <Button
            disabled={error ? true : false}
            type="submit"
            className={classes.create}
          >
            Create
          </Button>
        </form>
      </Box>
    </ReModal>
  )
}

export default NBrProjectCreation
