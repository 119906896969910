import { Box, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'

function NbrRefreshAlert({ refreshData }) {
  return (
    <Box
      style={{
        backgroundColor: '#E2ECFD',
        width: '100%',
        padding: '5px 12px',
        marginBottom: '12px',
        borderRadius: '4px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InfoOutlinedIcon
          style={{ height: '20px', width: '20px', marginRight: '20px' }}
        />
        <Typography variant="subtitle">
          Please refresh the list at intervals to see the latest data
        </Typography>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '25px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={refreshData}
        >
          <CachedOutlinedIcon
            style={{ height: '20px', width: '20px', marginRight: '5px' }}
          />
          <Typography variant="subtitle">Refresh</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default NbrRefreshAlert
