import axios from 'axios'
import apiConfig from '../../config/apiConfig'

const axiosCancelToken = axios.CancelToken
const axiosSource = axiosCancelToken.source()

export function getCommentList(extId, vendorId) {
  var commentListUrl = apiConfig.pipeline.commentList + extId + '/' + vendorId
  return axios.get(commentListUrl)
}

export function getTcinDetail(extId) {
  var tcinDetailUrl = apiConfig.pipeline.tcinDetail + extId
  return axios.get(tcinDetailUrl)
}

export function AddComment(extId, requestBody, vendorId) {
  var cgiAddCommentUrl = apiConfig.pipeline.commentList + extId + '/' + vendorId
  var headers = {
    'Content-Type': 'application/json',
  }
  return axios.post(cgiAddCommentUrl, requestBody, {
    headers: headers,
    cancelToken: axiosSource.token,
  })
}
