import { useState } from 'react'
//style
import './NbrStyles/Style.scss'
//Components
import ProjectCreation from './NBrProjectCreation'
import NationalBrandProjectCard from './NBrProjectCard'
import NBrHeader from './NBrHeader'

const NBrProjectList = (props) => {
  const [openCreateForm, setOpenCreateForm] = useState(false)
  const [currentTab, setCurrentTab] = useState('')

  const [newProject, setNewProject] = useState(false)

  const tab = new URLSearchParams(props?.history?.location?.search).get(
    'activeTab'
  )

  const handleOpenCreateForm = () => {
    setOpenCreateForm(true)
  }

  const projectCreated = (create) => {
    setNewProject(create)
  }

  const getCurrentTab = (currentTab, isFirst) => {
    setCurrentTab(isFirst && tab ? tab : currentTab)
    props.history.push(
      `/projects?activeTab=${isFirst && tab ? tab : currentTab}`
    )
  }

  return (
    <>
      <NBrHeader
        handleOpenCreateForm={handleOpenCreateForm}
        getCurrentTab={getCurrentTab}
        activeTab={currentTab}
      />
      <NationalBrandProjectCard
        history={props.history}
        newProjectCreate={newProject}
        setNewProject={setNewProject}
        currentTab={currentTab}
      />
      <ProjectCreation
        openCreateForm={openCreateForm}
        setOpenCreateForm={setOpenCreateForm}
        projectCreated={projectCreated}
      />
    </>
  )
}

export default NBrProjectList
