import { createMuiTheme } from '@material-ui/core/styles'

//Remove Duplicate after verifying in components later
export const extGargantua = {
  main: '#336CD9',
  light: '#ffffff', // duplicate
  mediumLightGrey: '#CCC',
  lightGray: '#929292',
  contrastText: '#ffffff', // duplicate
  mediumGrey: '#666', // duplicate
  lighterBlue: '#5471d2',
  secondaryText: '#888888', // duplicate
  contentLight: '#888888', // duplicate
  red: '#CC0000',
  green: '#00A200',
  gray: '#666666', // duplicate
  grayLight: '#D6D6D6',
  pitchBlack: '#000000',
  greenText: '#156700',
  mustard: '#B5960A',
  redText: '#B30000',
  blue: '#224FCA',
  greyHeaderText: '#8f0418',
  greyContentText: '#F2F2F2',
  yellow: 'rgb(255, 204, 0)',
  parrotGreen: 'rgb(51, 153, 0)',
  warningRed: 'rgb(255, 0, 0)',
  greyBackground: '#efefef',
  greyLighterBackground: 'rgba(239, 239, 239, 1)',
  darkBlue: '#0D46A0',
  greyText: '#8D8D8D',
  crispGreyText: '#212121',
  primaryBlue: 'rgb(68,138,255)',
  greylightest: 'rgba(111,111,111,.5)',
  peach: 'rgba(240,85,69,.3)',
}
const pipelineTheme = createMuiTheme({
  palette: {
    primary: extGargantua,
  },
  typography: {
    useNextVariants: true,
  },
})

export default pipelineTheme
