import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, InputBase } from '@material-ui/core'
import { Search, Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    // display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: 14,
    lineHeight: '20px',
  },

  iconButton: {
    color: '#888888',
    fontSize: 14,
    margin: '11px 14px 11px 0',
    padding: '3px 0 3px 14px',
    borderLeft: ' 0.75px solid #d7d7d7',
    borderRadius: 0,
    '&:hover': {
      background: 'none',
    },
  },
  inputWrapper: {
    border: '1px solid #d7d7d7',
    height: 40,
    marginLeft: 190,
    width: '65%',
    display: 'flex',
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  focussed: {
    border: '1px solid #224FCA',
  },
}))

function VendorSearch(props) {
  const classes = useStyles()

  const [focussed, setFocussed] = useState(false)
  const {
    placeholder = 'Start Typing...',
    width,
    searchValue,
    handleChange,
    handleButtonClick,
  } = props
  const searchWrapperClass =
    (focussed ? classes.focussed : '') + ' ' + classes.inputWrapper

  return (
    <div className={searchWrapperClass} style={{ width: width }}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        onChange={handleChange}
        value={searchValue}
        onFocus={(e) => {
          setFocussed(true)
        }}
        onBlur={(e) => {
          setFocussed(false)
        }}
        data-table="table"
      />

      <IconButton
        onClick={handleButtonClick}
        className={classes.iconButton}
        aria-label="Search"
      >
        {searchValue === '' ? <Search /> : <Close />}
      </IconButton>
    </div>
  )
}

export default VendorSearch
