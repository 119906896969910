import React, { useState } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

import Scene from '../Lib/Scene'

const mystyle = {
  csScene: {
    border: ' 1px solid #ccc',
    boxSizing: ' border-box',
    overflow: ' hidden',
    position: 'relative',
    width: '100%',
    height: 'Calc(100vh - 160px)',
  },

  csAnchor: {
    position: 'absolute',
    color: ' #666',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    pointerEvents: 'none',
  },
  csAxis: {
    color: '#666',
  },
  anchorDimensionY: {
    left: '1rem',
  },

  anchorDimensionX: {
    top: '1rem',
    left: ' -2rem',
  },

  csOriginPoint: {
    width: '10px',
    height: '10px',
    left: ' -10px',
    top: ' -10px',
    border: ' 3px solid #3333339e',
    borderRadius: '10px',
  },
  csTri: {
    position: 'absolute',
    bottom: '0.5rem',
    left: '1rem',
  },
}
let fbxScene
const FBXViewer = ({
  asset,
  isDrag = false,
  inInches,
  showgrid = true,
  showpivot = true,
  wireframe = false,
  dimensions = true,
  onAssetLoaded = () => {},
  materialList,
  update = 0,
  ...props
}) => {
  const element = React.createRef()
  const [loading, setLoading] = useState(true)
  const [triCount, setTriCount] = useState(0)
  const applyEnvironmentSettings = () => {
    if (fbxScene) {
      fbxScene.togglePivot(showpivot)
      fbxScene.toggleGrid(showgrid)
    }
  }

  React.useEffect(() => {
    if (!fbxScene && window.WebGLRenderingContext) {
      fbxScene = new Scene(element.current)
    }
    window.addEventListener('resize', resizeScreen)
    return () => {
      if (fbxScene) {
        fbxScene.dispose()
      }
      fbxScene = undefined
      window.removeEventListener('resize', resizeScreen)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (fbxScene) {
      const table = asset.getFileTable(false)
      setLoading(true)
      fbxScene.add(table, isDrag, {}, inInches).then(() => {
        applyEnvironmentSettings()
        setLoading(false)
        materialList.forEach(
          ({ alphaCutoff, name, doubleSided, meshNames, type }) => {
            fbxScene.setAlphaTest(Number(alphaCutoff), name, meshNames, true)
            fbxScene.setSided(doubleSided, name, meshNames, true)
            fbxScene.setAlphaType(type, name, meshNames, true)
          }
        )
        let materials = fbxScene
          .getMaterials()
          .map(({ matName: name, meshName }) => {
            return {
              name,
              type:
                name && name.toLowerCase().includes('trp') ? 'BLEND' : 'OPAQUE',
              alphaCutoff: '0.0',
              meshNames: [meshName],
              doubleSided: false,
            }
          })
        onAssetLoaded(materials)

        window.requestAnimationFrame(() =>
          setTriCount(fbxScene.renderer.info.render.faces - 48)
        )
      })
    }
    // eslint-disable-next-line
  }, [asset.model.name])

  const resizeScreen = () => {
    if (fbxScene) {
      fbxScene.resize()
    }
  }

  React.useEffect(applyEnvironmentSettings, [showpivot, showgrid])
  React.useEffect(() => {
    if (!loading && fbxScene) {
      fbxScene.setWireframe(wireframe)
    }
    // eslint-disable-next-line
  }, [wireframe])

  React.useEffect(() => {
    if (!loading && fbxScene) {
      fbxScene.setDimensions(dimensions)
    }
    // eslint-disable-next-line
  }, [dimensions])

  React.useEffect(() => {
    if (update > 0 && !loading) {
      materialList.forEach(
        ({ alphaCutoff, name, doubleSided, meshNames, type }) => {
          // updateMaterialData(type, alphaCutoff,doubleSided,  name)
          fbxScene.setAlphaTest(Number(alphaCutoff), name, meshNames, true)
          fbxScene.setSided(doubleSided, name, meshNames, true)
          fbxScene.setAlphaType(type, name, meshNames, true)
        }
      )
    }
    // eslint-disable-next-line
  }, [update])

  return (
    <div style={mystyle.csScene} ref={element}>
      {loading ? <LinearProgress /> : null}
      {dimensions && (
        <>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.anchorDimensionX }}
            id="anchor-dimension-x"
          ></div>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.anchorDimensionY }}
            id="anchor-dimension-y"
          ></div>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.anchorDimensionX }}
            id="anchor-dimension-z"
          ></div>
        </>
      )}
      {showpivot ? (
        <>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.csAxis }}
            id="axis-x"
          ></div>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.csAxis }}
            id="axis-y"
          ></div>
          <div
            style={{ ...mystyle.csAnchor, ...mystyle.csAxis }}
            id="axis-z"
          ></div>
        </>
      ) : null}

      <div style={{ ...mystyle.csOriginPoint }} id="cs-origin-point"></div>
      <div style={mystyle.csTri}>{triCount} triangles</div>
    </div>
  )
}

export default FBXViewer
