import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import _ from 'lodash'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: 30,
    marginTop: 12,
  },
  tabs: {
    minHeight: 30,
  },
  tab: {
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 35,
    minWidth: 'auto',
    minHeight: 30,
    fontSize: '12px',
    '& span': {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 'auto',
    },
  },
})

class CustomTabs extends React.Component {
  state = {
    value: this.props.activeTab || 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
    this.props.updateTab(value)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.activeTab && prevProps.activeTab !== this.props.activeTab) {
      this.setState({ value: this.props.activeTab })
    }
  }

  render() {
    const { classes, tabs, color } = this.props
    const { value } = this.state
    return (
      <div className={classes.root}>
        <Tabs
          textColor={color || 'primary'}
          className={classes.tabs}
          value={value}
          onChange={this.handleChange}
        >
          {_.map(tabs, (tabItem, i) => {
            return (
              <Tab
                key={i}
                label={tabItem.label}
                value={i}
                className={classes.tab}
                disabled={tabItem.disabled}
              />
            )
          })}
        </Tabs>
      </div>
    )
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  updateTab: PropTypes.func,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
}

export default withStyles(styles)(CustomTabs)
