/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { map } from 'lodash'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import apiConfig from '../../config/apiConfig'
import DownloadFileContainer from './DownloadFileContainer'
import UploadFilesContainer from './UploadFilesContainer'
import { TaskActionButton } from 'gga-ui-library'
import { showNotification } from '../Notifications/NotificationActionCreator'

export const tabsMetaData = [
  {
    statusId: 'TOTAL',
    serverId: '',
    label: 'All Tasks (0)',
    status: 'All Tasks',
  },
  {
    statusId: 'ASSIGNED',
    serverId: 'ASSIGNED',
    label: 'Assigned (0)',
    status: 'Assigned',
  },
  {
    statusId: 'IN_REVIEW',
    serverId: 'IN_REVIEW',
    label: 'Review (0)',
    status: 'In Review',
  },
  {
    statusId: 'APPROVED',
    serverId: 'APPROVED',
    label: 'Approved (0)',
    status: 'Approved',
  },
  {
    statusId: 'ON_HOLD',
    serverId: 'ON_HOLD',
    label: 'On Hold (0)',
    status: 'On Hold',
  },
]

export const updateTask = ({
  item,
  statusId,
  fileStatesList = null,
  successCallBack = () => {},
  errorCallBack = () => {},
}) => {
  const payload = {
    project_tcin_task: [
      {
        task_uuid: item.task_uuid,
        project_uuid: item.project_uuid,
        tcin: item.tcin,
      },
    ],
    phase_id: item.phase_id,
    task_description: item.task_description,
    status_id: statusId,
    artist_assigned: item.artist_assigned, // item.artist_assigned
    due_date: item.due_date_timestamp, // item.due_date
    complexity: item.complexity,
    priority: item.priority,
    // file_id: ['31a2788c-6532-4d26-ab1a-f41b9b95717e'],
  }
  if (fileStatesList) {
    payload.file_id = map(fileStatesList, (fileStatus) => fileStatus.fileId)
    payload.status_id = 'AWAITING_REVIEW'
  }
  axios
    .put(apiConfig.task.updateTask, payload)
    .then(() => {
      successCallBack()
    })
    .catch((e) => {
      errorCallBack()
    })
}
let cancel
export const ActionButton = ({ item, getTaskList, selectedPhase }) => {
  const [updateTaskLoading, setUpdateTaskLoading] = useState(false)
  const dispatch = useDispatch()

  const startTaskHandler = (event, item) => {
    event.stopPropagation()
    setUpdateTaskLoading(true)
    updateTask({
      event,
      statusId: 'IN_PROGRESS',
      item,
      successCallBack: () => {
        setUpdateTaskLoading(false)
        dispatch(
          showNotification(
            true,
            'Success! We have changed & saved your new task details.',
            'success'
          )
        )
        getTaskList(true)
      },
      errorCallBack: () => {
        setUpdateTaskLoading(false)
        dispatch(showNotification(true, 'Something went wrong', 'error'))
      },
    })
  }
  if (item.status_id === 'ASSIGNED' || item.status_id === 'REVIEW_REJECTED') {
    return (
      <TaskActionButton
        buttonType={updateTaskLoading ? 'loading' : 'start_task'}
        onClick={(e) => startTaskHandler(e, item)}
      />
    )
  }
  if (item.status_id === 'IN_PROGRESS') {
    return (
      <UploadFilesContainer
        item={item}
        getTaskList={getTaskList}
        selectedPhase={selectedPhase}
      />
    )
  }
  return null
}

export default function useTaskSearch(
  params,
  pageNum,
  selectedPhase,
  setPageNum
) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [tasks, setTasks] = useState([])
  const [tabsData, setTabsData] = useState(tabsMetaData)
  const [filtersData, setFiltersData] = useState({})
  const [tabIndex, setTabIndex] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [totalTasks, setTotalTask] = useState(0)
  const getTaskList = (resetTaskList = false) => {
    if (cancel) cancel()
    let pageNumber = pageNum
    if (resetTaskList) {
      setPageNum(1)
      setTasks([])
      pageNumber = 1
    }
    setLoading(true)
    setError(false)
    const {
      page = pageNumber,
      count = 30,
      statusId = '',
      sortOrder = '',
      sortBy = '',
      searchQuery = '',
      filters,
      loginId,
      phaseId = selectedPhase,
      showApprovedTask = false,
    } = params
    axios({
      method: 'POST',
      url: apiConfig.task.taskListing,
      data: {
        login_id: loginId,
        search: {
          keyword: searchQuery,
        },
        page,
        filters,
        count,
        sort_by: sortBy,
        sort_order: sortOrder,
        show_approved_tasks: showApprovedTask,
        status_id: statusId,
        phase_id: phaseId,
      },
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c
      }),
    })
      .then((response) => {
        setTotalTask(response.data.total_count)
        const newTabsData = map(tabsData, (tab, index) => ({
          ...tab,
          label: `${tab.status} (${
            response.data.status_counts[tab.statusId] || 0
          })`,
        }))
        setTabsData(newTabsData)
        if (response.data.filters) {
          setFiltersData(response.data.filters)
        }

        setTasks((prevTasks) => {
          return [
            ...prevTasks,
            ...response.data.search_response.map((item) => {
              const container = {}
              container.rawData = item
              container.tcin = item.tcin
              container.taskId = item.task_uuid
              container.thumbnailUrl = item.thumbnail_url
              container.project = item.project_name
              container.fileUrl = (
                <DownloadFileContainer
                  jobId={item.agency_job_id}
                  fileName={item.agency_file_name}
                  selectedPhase={selectedPhase}
                  urlZip1={item.url_zip1}
                  urlZip2={item.url_zip2}
                  tcin={item.tcin}
                />
              )
              container.fileName = item.agency_file_name
              container.jobId = item.agency_job_id
              container.taskstatus = item.status_id
              container.on_hold_reason = item.on_hold_reason
                ? item.on_hold_reason
                : '-'
              container.assigneddate = item.assigned_date
              container.approveddate = item.updated_date.split('T')[0]
              container.recount = item.rework_count
              container.priority = item.priority
              container.duedate = item.due_date
              container.actions = (
                <ActionButton
                  item={item}
                  getTaskList={getTaskList}
                  selectedPhase={selectedPhase}
                />
              )
              container.projectId = item.project_uuid
              container.itemtype = item.product_type
              container.comments = item.task_comment_count
              return container
            }),
          ]
        })
        setHasMore(response.data.search_response.length === 30)
        setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        }
        setLoading(false)
        setError(true)
      })
    return () => cancel()
  }

  useEffect(() => {
    getTaskList()
  }, [params, pageNum, selectedPhase, setPageNum])
  return {
    loading,
    error,
    tasks,
    totalTasks,
    hasMore,
    tabsData,
    tabIndex,
    setTabIndex,
    filtersData,
    getTaskList,
    setTasks,
  }
}
