import FileDownloadIcon from '../assets/images/FileClip.svg'
import apiConfig from '../../config/apiConfig'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { downloadFile } from '../store/NationalBrand/ActionCreator'
import { showNotification } from '../Notifications/NotificationActionCreator'
import { Box, Tooltip } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
// style
import './NbrStyles/Style.scss'

const NBrUploaded = ({ tcinId, projectId }) => {
  const [isDownloading, setDownloadingFile] = useState(false)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    event.stopPropagation()
    setDownloadingFile(true)
    let succesReponse = (isPresent, response) => {
      if (isPresent && response.data && response.data.agency_file_name) {
        let agency_file_name = response.data.agency_file_name
        let jobId = response.data.agency_job_id
        let filePath = `${apiConfig.task.fileDownload}/${jobId}?key=${apiConfig.apiKey}&file_name=${agency_file_name}&is_download=true`
        window.location.assign(filePath)
        setDownloadingFile(false)
      } else {
        dispatch(
          showNotification(
            true,
            `Files for TCIN ${tcinId} are not uploaded yet`,
            'success'
          )
        )
        setDownloadingFile(false)
      }
    }
    dispatch(downloadFile(tcinId, projectId, succesReponse))
  }

  return (
    <>
      <Tooltip
        title="Download TCIN Files"
        placement="bottom-start"
        color="#336cd9"
      >
        <Box className="flex-box pointer file-available" onClick={handleClick}>
          {isDownloading ? (
            <CircularProgress style={{ width: '20px', height: '20px' }} />
          ) : (
            <img
              src={FileDownloadIcon}
              alt="file-upload"
              className="download-file-img"
            />
          )}
          <span className="norm-text"> Files available</span>
        </Box>
      </Tooltip>
    </>
  )
}

export default NBrUploaded
