/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react'
import _ from 'lodash'
import { useAuth } from '@praxis/component-auth'
import {
  Toolbar,
  Breadcrumbs,
  Typography,
  Link,
  LinearProgress,
  Badge,
  Button,
  withStyles,
} from '@material-ui/core'
import { Box } from '@mui/material'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  DataTable,
  TabHeader,
  Filters,
  TaskSummary,
  PhaseDropdown,
  SearchInput,
} from 'gga-ui-components'
import { makeStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import useTaskSearch, { ActionButton } from './useTaskSearch'
import {
  StyledHeader,
  StyledToolbar,
  TableWrapper,
  TabsWrapper,
  StyledToolbarHeader,
  RightSideContentWrapper,
  NoDataSection,
} from './TaskStyle'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { forEach } from 'lodash'
import { FilterList } from '@material-ui/icons'
import { getTcinTaskDetails, postComment } from './TcinTaskContainer'
import { TaskContext } from './TaskContext'
import { INIT_TASK_FILE_STATUS } from './TaskActionType'
import DownloadFileContainer from './DownloadFileContainer'
import { fileUpload } from './TaskActionCreator'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { taskHeaders } from '../../helpers/ReportHeaders'
import { headCells, approvedHeadCells } from '../../helpers/TableHeaders'

function fetchUsers(query, callback) {
  if (!query) return

  callback([
    {
      display: 'Loading...',
      id: 'Loading...',
    },
  ])
  axios
    .get(
      `${apiConfig.task.getAllMembers}?nameLike=${query}&key=${apiConfig.apiKey}`
    )
    // Transform the users to what react-mentions expects
    .then((res) => {
      return res.data.map((user) => ({
        display: user.display_name,
        id: user.login_id,
      }))
    })
    .then(callback)
}

const ExpansionPanel = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'grid',
  },
}))(MuiExpansionPanelDetails)

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    padding: '7px 10px',
    fontSize: '14px',
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}))

function handleClick(event) {
  event.preventDefault()
}

const phaseList = {
  AGENCY_HIGHPOLY: {
    phase_id: 'AGENCY_HIGHPOLY',
    phase_name: 'Agency Highpoly',
    next_phase: null,
    agency: true,
  },
  AGENCY_LIGHTING: {
    phase_id: 'AGENCY_LIGHTING',
    phase_name: 'Agency Lighting',
    next_phase: null,
    agency: true,
  },
  AGENCY_VRAY: {
    phase_id: 'AGENCY_VRAY',
    phase_name: 'Agency Vray',
    next_phase: null,
    agency: true,
  },
  AGENCY_SCENE_BUILDING: {
    phase_id: 'AGENCY_SCENE_BUILDING',
    phase_name: 'Agency Scene Building',
    next_phase: null,
    agency: true,
  },
}

const ASC = 'asc'
const DESC = 'desc'

const TaskLanding = (props) => {
  const auth = useAuth()
  const [params, setParams] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [taskSummaryLoader, setTaskSummaryLoader] = useState(true)
  const [taskDetails, setTaskDetails] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const [taskComment, setTaskComment] = useState('')
  const [userIds, setUserIds] = useState([])
  const [newTaskId, setNewTaskId] = useState('')
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [commentsOnLoad, setCommentsOnLoad] = useState(false)
  const [taskTcinComments, setTaskTcinComments] = useState([])
  const [selectedFiltersList, setSelectedFiltersList] = useState({})
  const [currentRowItem, setCurrentRowItem] = useState({})
  const [commentFile, setCommentFile] = useState({})
  const [commentFileUploadLoader, setCommentFileUploadLoader] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [selectedTr, setSelectedTr] = useState(null)
  const [selectedPhase, setSelectedPhase] = useState('AGENCY_HIGHPOLY')
  const [searchValue, setSearchValue] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortOrderBy, setSortOrderBy] = useState('')
  const [headers, setHeaders] = useState([])
  const [rowData, setRowData] = useState([])
  const [reportsData, setReportsData] = useState([])
  const [downloadLoading, setDownloadLoading] = useState(true)
  const classes = useStyles()
  const { session } = auth || {}
  const [store, dispatchContext] = useContext(TaskContext)

  const setFileStatesInitial = () => {
    const taskFileStatus =
      JSON.parse(localStorage.getItem('taskFileStatus')) || {}
    dispatchContext({
      type: INIT_TASK_FILE_STATUS,
      payload: taskFileStatus,
    })
  }

  useEffect(() => {
    setFileStatesInitial()
    downloadReport()
  }, [])

  const {
    tasks,
    loading,
    hasMore,
    error,
    tabsData,
    tabIndex,
    totalTasks,
    setTabIndex,
    filtersData,
    getTaskList,
    setTasks,
  } = useTaskSearch(params, pageNum, selectedPhase, setPageNum)

  const changeTab = (index) => {
    setPageNum(1)
    setTasks([])
    setTabIndex(index)
    closeDrawer()
    const tab = tabsData[index]
    setSelectedFiltersList({})
    setSearchValue('')
    resetSort()
    setParams({
      loginId: session && session.userInfo.lanId,
      statusId: tab.serverId,
      filters: {},
      showApprovedTask: false,
      phaseId: selectedPhase,
      searchQuery: '',
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
    })
  }

  let filterBadgeCount = 0
  if (selectedFiltersList) {
    forEach(selectedFiltersList, (filters) => {
      forEach(filters, (o) => {
        filterBadgeCount++
      })
    })
  }

  const setSelectedFiltersForYourTask = (chosenFilters) => {
    setSelectedFiltersList(chosenFilters)
    setPageNum(1)
    setTasks([])
    setParams({
      filters: chosenFilters,
      searchQuery: searchValue,
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
      statusId: tabsData[tabIndex].serverId,
    })
  }

  const handleRowClick = (event, value, tcin, taskId, item) => {
    const targetEle = event.target
    if (targetEle.parentNode.tagName !== 'TR') return
    highlightRow(targetEle.parentNode)
    setOpenDrawer(value)
    setCurrentRowItem(item)
    setTaskSummaryLoader(true)
    setTaskDetails({})
    setCommentsOnLoad(false)
    getTcinTaskDetails({
      tcin,
      taskId,
      callbackSuccess: (response) => {
        setTaskDetails(() => {
          response.task_comments = response.task_comments.map((comment) => ({
            ...comment,
            downloadButton: (
              <DownloadFileContainer
                jobId={comment.file_id}
                fileName={comment.file_name}
                nameButton
              />
            ),
          }))
          setTaskSummaryLoader(false)

          return response
        })
      },
      callbackError: () => {
        setTaskSummaryLoader(false)
      },
    })
  }

  const highlightRow = (tr) => {
    if (selectedTr) {
      selectedTr.classList.remove('Mui-selected')
    }
    setSelectedTr(tr)
    tr.classList.add('Mui-selected')
  }

  const addComment = () => {
    setCommentsLoading(true)
    setTaskTcinComments([])
    postComment({
      taskId: currentRowItem.task_uuid,
      commentText: taskComment,
      fileId: commentFile.fileId,
      fileName: commentFile.fileName,
      comment_reference_ids: userIds,
      callbackSuccess: (response) => {
        if (response) {
          setCommentsLoading(false)
          setTaskComment('')
          setTaskTcinComments(
            response.map((comment) => ({
              ...comment,
              downloadButton: (
                <DownloadFileContainer
                  jobId={comment.file_id}
                  fileName={comment.file_name}
                  nameButton
                />
              ),
            }))
          )
          setCommentFile({})
        }
      },
      callbackError: () => {
        setCommentsLoading(false)
      },
    })
    setCommentsOnLoad(true)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
    if (commentsOnLoad) {
      setPageNum(1)
      setTasks([])
      const tab = tabsData[tabIndex]
      setParams({
        loginId: session && session.userInfo.lanId,
        statusId: tab.serverId,
        filters: selectedFiltersList,
        searchQuery: searchValue,
        showApprovedTask: false,
        phaseId: selectedPhase,
        sortOrder: sortOrder,
        sortBy: sortOrderBy,
      })
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const taskSummaryActionButtons = (data) => {
    return (
      <>
        <DownloadFileContainer
          jobId={data.job_id}
          fileName={data.file_name}
          selectedPhase={selectedPhase}
          urlZip1={currentRowItem.url_zip1}
          urlZip2={currentRowItem.url_zip2}
          tcin={currentRowItem.tcin}
        />
        &nbsp;&nbsp;&nbsp;
        <ActionButton
          item={currentRowItem}
          getTaskList={() => {
            closeDrawer()
            getTaskList(true)
          }}
          selectedPhase={selectedPhase}
        />
      </>
    )
  }

  const commentFileAttachmentHandler = async (file) => {
    setCommentFileUploadLoader(true)
    try {
      setCommentFile({ fileId: null, fileName: file.name })
      const { fileId } = await fileUpload({ file })
      setCommentFile({ fileId, fileName: file.name })
    } catch (e) {
      removeAttachment()
    }
    setCommentFileUploadLoader(false)
  }

  const onDiscardComment = () => {
    setTaskComment('')
    setCommentFile({})
  }

  const removeAttachment = () => {
    setCommentFile({})
  }

  const downloadFileVersions = (uploads) => {
    let uploadedKeys = Object.keys(uploads)
    let uploadedData = Object.values(uploads)
    return (
      <>
        {uploadedKeys.map((key, index) => {
          return (
            <ExpansionPanel
              square
              expanded={expanded === 'panel' + index}
              onChange={handleChange('panel' + index)}
              elevation={0}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>{key}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {uploadedData[index].map((dataItem) => {
                  return (
                    <DownloadFileContainer
                      jobId={dataItem.job_id}
                      fileName={dataItem.file_name}
                      lastUpdated={dataItem.last_update}
                      listingType={true}
                    />
                  )
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </>
    )
  }

  const downloadStylingDocs = (stylingDocs) => {
    if (selectedPhase === 'AGENCY_SCENE_BUILDING') {
      return <></>
    }
    return (
      <>
        {stylingDocs ? (
          <div>
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Styling Documents</b>
            </Typography>
            {_.map(stylingDocs, (fileId, fileName) => (
              <div key={fileId}>
                <DownloadFileContainer
                  jobId={fileId}
                  fileName={fileName}
                  lastUpdated={''}
                  listingType
                />
              </div>
            ))}
          </div>
        ) : null}
      </>
    )
  }

  const debounceCallback = useCallback(
    _.debounce((value) => {
      setTasks([])
      setPageNum(1)
      setParams({
        searchQuery: value,
        filters: selectedFiltersList,
        sortOrder: sortOrder,
        sortBy: sortOrderBy,
        statusId: tabsData[tabIndex].serverId,
      })
    }, 1000),
    [selectedFiltersList]
  )

  const searchHandler = (e) => {
    setSearchValue(e.target.value)
    debounceCallback(e.target.value)
  }

  const removeSearch = () => {
    setSearchValue('')
    setTasks([])
    setPageNum(1)
    setParams({
      searchQuery: '',
      filters: selectedFiltersList,
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
      statusId: tabsData[tabIndex].serverId,
    })
  }

  const handlePhaseChange = (phase) => {
    setSelectedPhase(phase)
    setSelectedFiltersList({})
    setTasks([])
    setPageNum(1)
    resetSort()
    setSearchValue('')
    const tab = tabsData[tabIndex]
    setParams({
      statusId: tab.serverId,
      filters: {},
      searchQuery: '',
      showApprovedTask: false,
      phaseId: phase,
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
    })
  }

  const resetSort = () => {
    setSortOrder('')
    setSortOrderBy('')
  }

  const sortHandler = (event, property) => {
    const newSortOrder = sortOrder === ASC ? DESC : ASC
    const orderBy = newSortOrder ? property : ''
    setTasks([])
    setPageNum(1)
    setSortOrder(newSortOrder)
    setSortOrderBy(orderBy)
  }

  useEffect(() => {
    setParams({
      searchQuery: searchValue,
      filters: selectedFiltersList,
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
      statusId: tabsData[tabIndex].serverId,
    })
  }, [sortOrder, sortOrderBy])

  const downloadReport = () => {
    setReportsData([])
    const selectedPhase = 'AGENCY_HIGHPOLY'
    let apiUrl = `${apiConfig.task.downloadReport}task_user_report`

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.map((task) => {
          _.forEach(task, (o, key) => {
            task[key] = _.toString(o)
          })
          return task
        })
        setReportsData(data)
      })
      .catch(() => {
        setDownloadLoading(false)
      })
  }

  const generateCSV = () => {
    if (!_.isEmpty(reportsData)) {
      let headersList = taskHeaders
      const keyHeaderMap = {
        'production_phase.artist_due_date': 'artist_due_date',
        artist_assigned: 'artist_name',
        reviewer_assigned: 'reviewer_name',
      }

      const filteredHeadersList = headersList.filter((o) => o.display_excel)
      const headerLabels = filteredHeadersList.map(({ id, label }) => ({
        label,
        key: keyHeaderMap[id] || id,
      }))
      setHeaders(headerLabels)
      setRowData(reportsData)
    } else {
      this.setState({
        headers: [],
        rowData:
          'Unfortunately we could not generate your report as there is no tcin assigned to the project.',
      })
    }
  }

  const headerWithHold = () => {
    let headers = []
    let findIdx = headCells.findIndex((id) => id.id === 'taskstatus')
    for (let i = 0; i <= findIdx; i++) {
      headers.push(headCells[i])
    }
    headers.push({
      id: 'on_hold_reason',
      numeric: true,
      disablePadding: true,
      label: 'Reason for on-Hold',
      sortable: false,
    })
    for (let i = findIdx + 1; i < headCells.length; i++) {
      headers.push(headCells[i])
    }
    return headers
  }

  return (
    <>
      <StyledHeader position="fixed">
        {loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
        <StyledToolbarHeader>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" href="/" onClick={handleClick}>
              CGI Tasks
            </Link>
            <Typography
              color="textPrimary"
              style={{ textTransform: 'capitalize' }}
            >
              {selectedPhase.replace('_', ' ').toLowerCase()}
            </Typography>
          </Breadcrumbs>
          <RightSideContentWrapper>
            <Box
              sx={{
                '& .MuiSelect-select': {
                  width: '180px',
                },
              }}
            >
              <PhaseDropdown
                selectedPhase={selectedPhase}
                dropdownList={phaseList}
                handlePhaseChange={handlePhaseChange}
                classes={{ color: '#fff' }}
              />
            </Box>
            <SearchInput
              placeholder="Search for TCIN, Brand, Artist..."
              searchValue={searchValue}
              handleChange={searchHandler}
              handleButtonClick={removeSearch}
            />
            <CSVLink
              style={{ textDecoration: 'none' }}
              filename={'report.csv'}
              data={rowData}
              headers={headers}
              onClick={generateCSV}
            >
              <Button
                variant="outlined"
                color="default"
                className={classes.button}
              >
                Report <CloudDownloadIcon />
              </Button>
            </CSVLink>
          </RightSideContentWrapper>
        </StyledToolbarHeader>
        <React.Fragment>
          <StyledToolbarHeader>
            <TabsWrapper>
              <TabHeader
                updateTab={changeTab}
                tabs={tabsData}
                activeTab={tabIndex}
              />
            </TabsWrapper>
          </StyledToolbarHeader>
          <div style={{ position: 'absolute', right: 20, top: 70 }}>
            <Badge badgeContent={filterBadgeCount} color="primary">
              <Button
                variant={filterBadgeCount > 0 || filterOpen ? 'outlined' : null}
                color={'primary'}
                onClick={() => setFilterOpen(!filterOpen)}
              >
                <FilterList />
                &nbsp; Filter
              </Button>
            </Badge>
          </div>
        </React.Fragment>
      </StyledHeader>

      <StyledToolbar />
      {
        <div>
          <div style={{ width: filterOpen ? 'calc(100% - 300px)' : '100%' }}>
            <TableWrapper>
              {tasks && tasks.length > 0 ? (
                <>
                  <DataTable
                    tablebody={tasks}
                    loading={loading}
                    totalTasks={totalTasks}
                    error={error}
                    hasMore={hasMore}
                    setPageNum={setPageNum}
                    onRowClick={handleRowClick}
                    tableheaders={
                      tabsData[tabIndex].serverId === 'APPROVED'
                        ? approvedHeadCells
                        : selectedPhase === 'AGENCY_HIGHPOLY' &&
                          tabsData[tabIndex].serverId === 'ON_HOLD'
                        ? headerWithHold()
                        : headCells
                    }
                    order={sortOrder}
                    orderBy={sortOrderBy}
                    sortHandler={sortHandler}
                  />
                </>
              ) : loading === false && tasks.length === 0 ? (
                <NoDataSection>
                  Currently, There is no task assigned
                </NoDataSection>
              ) : (
                'Loading, Please wait....'
              )}
              <TaskSummary
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                taskComment={taskComment}
                setTaskComment={setTaskComment}
                setUserIds={setUserIds}
                fetchUsers={fetchUsers}
                onDiscardComment={onDiscardComment}
                taskDetails={taskDetails}
                taskSummaryLoader={taskSummaryLoader}
                setNewTaskId={setNewTaskId}
                addComment={addComment}
                commentsLoading={commentsLoading}
                taskTcinComments={taskTcinComments}
                commentsOnLoad={commentsOnLoad}
                taskSummaryActionButtons={taskSummaryActionButtons}
                onFileAttach={commentFileAttachmentHandler}
                uploadLoader={commentFileUploadLoader}
                removeAttachment={removeAttachment}
                downloadFileVersions={downloadFileVersions}
                commentFile={commentFile}
                downloadStylingDocs={downloadStylingDocs}
                uploadedVersionText={
                  selectedPhase === 'AGENCY_SCENE_BULDING'
                    ? 'Uploaded Files'
                    : ''
                }
              />
            </TableWrapper>
          </div>

          {filterOpen ? (
            <Filters
              filtersList={filtersData}
              tcinSetSelectedFilters={setSelectedFiltersForYourTask}
              selectedFiltersList={selectedFiltersList}
              filterHeading="Filter tasks by"
            />
          ) : null}
        </div>
      }
    </>
  )
}

export default TaskLanding
