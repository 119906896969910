import { useEffect, useState, useRef } from 'react'
import Axios from 'axios'
import CommentInput from './CommentInput'
import CommentList from './CommentList'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Switch from '@material-ui/core/Switch'
import { apiConfig } from '../Lib/config'
import { isTPlus } from '../../../app/Auth/Auth'
const useStyles = makeStyles(({ theme }) => ({
  inputBox: {
    marginBottom: 10,
  },
  commentsList: {},
}))

const Comments = ({
  tcin = '',
  annotationMode = false,
  setAnnotationMode = () => {},
  setComments = () => {},
  selectComment,
  selectHotspot,
  activeComment,
  newHotspot,
  cancelHotspot,
}) => {
  const classes = useStyles()
  const [commentsData, setCommentsData] = useState([])
  const newCommentRef = useRef()
  useEffect(() => {
    Axios.get(
      `${apiConfig.threeDViewer.getComments}/${tcin}?is_target_plus_vendor=${isTPlus}`
    )
      .then((resp) => {
        const comments = (resp.data || [])
          .map((item) => item)
          .sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
        setCommentsData(comments)
        setComments(comments)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [tcin, setComments])
  const onNewComment = (data) => {
    const comments = (data || [])
      .map((item) => item)
      .sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
    setCommentsData(comments)
    setComments(comments)
  }
  const handleChange = (e) => {
    setAnnotationMode(e.target.checked)
  }
  useEffect(() => {
    if (activeComment === 'new') {
      newCommentRef.current.scrollIntoView()
    }
  }, [activeComment])
  const onCancel = () => {
    cancelHotspot(newHotspot)
  }

  return (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={annotationMode}
              onChange={handleChange}
              name="Annotation Mode"
            />
          }
          labelPlacement="start"
          label="Annotation Mode"
        />
      </div>
      <div className={classes.inputBox} ref={newCommentRef}>
        <CommentInput
          newHotspot={newHotspot}
          tcin={tcin}
          onNewComment={onNewComment}
          onCancel={onCancel}
        />
      </div>

      <CommentList
        data={commentsData}
        onNewComment={onNewComment}
        tcin={tcin}
        annotationMode={annotationMode}
        selectComment={selectComment}
        activeComment={activeComment}
        selectHotspot={selectHotspot}
      />
    </>
  )
}

export default Comments
