/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
// import { map } from 'lodash'
import axios from 'axios'
// import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
// import { showNotification } from '../Notifications/NotificationActionCreator'
import { Chip, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  prodDescription: {
    width: '400px',
    padding: ' 10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  customWidth: {
    maxWidth: 500,
  },
}))

const tcinStatus = {
  InputsVerified: {
    text: 'Inputs Verified',
    bgcolor: 'rgba(51, 153, 0, 1)',
    txtcolor: '#fff',
  },
  InvalidStatus: {
    text: 'Upload Failed',
    bgcolor: 'rgba(255, 0, 0, 1)',
    txtcolor: '#fff',
  },
  OnHold: {
    text: 'On Hold',
    bgcolor: '#f9e0e0',
    txtcolor: '#333',
  },
  ReadyForReview: {
    text: 'Waiting For Review',
    bgcolor: 'rgb(255, 204, 0, 1)',
    txtcolor: '#333',
  },
  AssignToVendor: {
    text: 'Inputs required',
    bgcolor: 'rgba(143, 4, 24, 1)',
    txtcolor: '#fff',
  },
  InProgress: {
    text: 'Processing...',
    bgcolor: '#efefef',
    txtcolor: '#333',
  },
  Error: {
    text: 'Processing...',
    bgcolor: '#efefef',
    txtcolor: '#333',
  },
  RequestForMoreInputs: {
    text: 'Inputs required',
    bgcolor: 'rgba(143, 4, 24, 1)',
    txtcolor: '#fff',
  },
  Approved: {
    text: 'Inputs Approved',
    bgcolor: 'rgba(51, 153, 0, 1)',
    txtcolor: '#fff',
  },
}

let cancel
export default function useVendorApis(params, pageNum, setPageNum) {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [vendorTask, setVendorTask] = useState([])
  // const [filtersData, setFiltersData] = useState({})
  const [hasMore, setHasMore] = useState(false)
  const [totalTcins, setTotalTcins] = useState(0)
  const getVendorsList = (resetVendorList = false) => {
    if (cancel) cancel()
    let pageNumber = pageNum
    if (resetVendorList) {
      setPageNum(1)
      setVendorTask([])
      pageNumber = 1
    }
    setLoading(true)
    setError(false)
    const {
      page = pageNumber,
      count = 30,
      sortOrder = '',
      sortBy = '',
      searchQuery = '',
      filters,
      loginId,
    } = params
    axios({
      method: 'GET',
      url: apiConfig.pipeline.listVendorTcins,
      data: {
        login_id: loginId,
        search: {
          keyword: searchQuery,
        },
        page,
        filters,
        count,
        sort_by: sortBy,
        sort_order: sortOrder,
      },
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c
      }),
    })
      .then((response) => {
        let rawData = response.data
        let dataList = rawData.sort((a, b) => {
          let da = a.lastUpdatedAt
          let db = b.lastUpdatedAt
          return db - da
        })
        setTotalTcins(response.data.length)
        setVendorTask((prevTasks) => {
          return [
            ...prevTasks,
            ...dataList.map((item, index) => {
              const container = {}
              container.id = item.id
              container.status = (
                <Chip
                  label={tcinStatus[item.status].text}
                  style={{
                    backgroundColor: tcinStatus[item.status].bgcolor,
                    color: tcinStatus[item.status].txtcolor,
                  }}
                />
              )
              container.tcin = item.tcin
              container.prodDesc = (
                <Tooltip title={item.prod_desc}>
                  <div className={classes.prodDescription}>
                    {item.prod_desc}
                  </div>
                </Tooltip>
              )
              container.brand = item.brand
              container.vendorName = item.vendor_name
              container.lastUpdatedAt = new Date(
                item.last_updated_at
              ).toLocaleDateString()
              container.dueDate = new Date(item.due_date).toLocaleDateString()
              container.launchDate = new Date(
                item.launch_date
              ).toLocaleDateString()

              container.vendorId = item.vendor_id
              return container
            }),
          ]
        })
        setHasMore(response.data.length === 30)
        setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return
        }
        setLoading(false)
        setError(true)
      })
    return () => cancel()
  }

  useEffect(() => {
    getVendorsList()
  }, [params, pageNum, setPageNum])
  return {
    loading,
    error,
    vendorTask,
    totalTcins,
    hasMore,
    // filtersData,
    getVendorsList,
    setVendorTask,
  }
}
