export const Worker = () => {
  onmessage = function (event) {
    const { type, data } = event.data
    console.log('Web Worker received message:')
    if (type === 'start') {
      uploadChunks(data)
    }
  }
  const uploadChunks = ({
    file,
    uploadId,
    api,
    accessToken,
    xApiKey,
    xIdToken,
  }) => {
    const chunkSize = 2 * 1024 * 1024 // 2MB chunks
    const totalChunks = Math.ceil(file.size / chunkSize)
    let promises = []
    const headers = {
      authorization: accessToken,
      'x-api-key': xApiKey,
      'x-id-token': xIdToken,
    }

    for (let currentChunk = 0; currentChunk < totalChunks; currentChunk++) {
      const chunkedFormData = new FormData()
      const chunk = file.slice(
        currentChunk * chunkSize,
        (currentChunk + 1) * chunkSize
      )

      chunkedFormData.append(
        'chunkUploadRequest',
        new Blob(
          [
            JSON.stringify({
              upload_id: uploadId,
              file_name: file.name,
              chunk_number: currentChunk,
              total_number_of_chunks: totalChunks,
              chunk_size: chunkSize,
              total_file_size: file.size,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      )
      chunkedFormData.append('file', chunk)

      promises.push(
        () =>
          new Promise((res) => {
            fetch(api, {
              method: 'POST',
              body: chunkedFormData,
              headers,
            })
              .then((response) => {
                return response.json()
              })
              .then(() => {
                postMessage({
                  type: 'progress',
                  data: { chunkNumber: currentChunk + 1, totalChunks },
                })
                res()
              })
              .catch((error) => {
                console.log('error: ', error)
                postMessage({
                  type: 'fail',
                  data: { chunkNumber: currentChunk + 1, totalChunks },
                })
              })
          })
      )
    }

    function promiseAllConcurrent(promises, concurrencyLimit) {
      return new Promise((resolve, reject) => {
        if (!Array.isArray(promises)) {
          reject(new Error('Input must be an array of promises'))
          return
        }

        const totalPromises = promises.length
        let completedPromises = 0
        let index = 0
        const results = new Array(totalPromises)

        function runNext() {
          const current = index++
          if (current >= totalPromises) {
            // All promises completed
            resolve(results)
            return
          }

          const promise = promises[current]

          Promise.resolve(promise())
            .then((result) => {
              results[current] = result
            })
            .catch((error) => {
              results[current] = { error }
            })
            .finally(() => {
              completedPromises++

              // If there are more promises to run, continue
              if (index < totalPromises) {
                runNext()
              }

              // If all promises are completed, resolve the main promise
              if (completedPromises === totalPromises) {
                resolve(results)
              }
            })
        }

        // Start the first batch of promises
        for (let i = 0; i < concurrencyLimit && i < totalPromises; i++) {
          runNext()
        }
      })
    }

    promiseAllConcurrent(promises, 5)
      .then((results) => {
        postMessage({
          type: 'complete',
          data: {
            uploadId: uploadId,
            totalChunks,
          },
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
