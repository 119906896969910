import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

export const StyledHeader = styled(AppBar)`
  background: #fff;
  width: 100%;
  height: 70px;
  margin-top: 86px;
`
export const StyledToolbar = styled(Toolbar)`
  min-height: 90px;
  width: calc(100% - 100px);
`
export const TableWrapper = styled.div`
  padding: 112px 20px 20px 20px;
`
export const TabsWrapper = styled.div`
  margin-top: -30px;
`

export const StyledToolbarHeader = styled(Toolbar)`
  min-height: 0;
  padding: 10px 30px 0 30px;
  display: flex;
  justify-content: space-between;
`

export const RightSideContentWrapper = styled.div`
  display: flex;
  justify-content: inherit;
  width: 34%;
`

export const NoDataSection = styled.div`
  text-align: center;
  font-size: 20px;
`
