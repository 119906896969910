/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import brandLogo from '../assets/images/ic_logo_red.svg'
import HelpIcon from '@mui/icons-material/HelpSharp'
import {
  BrandLogoWrapper,
  BrandLogo,
  StyledLayoutHeader,
  NavTaskIcon,
  VendorPipelineIcon,
  StyledToolbar,
  StyledListItem,
  ListWrapper,
  LogoutIcon,
  NationalBrandIcon,
  NBRDashboardIcon,
} from './LayoutStyle'
import {
  useScrollTrigger,
  Tooltip,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, NavLink } from 'react-router-dom'
import { isTPlus } from '../Auth/Auth'

function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  toolbar: theme.mixins.toolbar,

  navLink: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    color: '#fff',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  layoutHeader: {
    color: '#ffffff',
    backgroundColor: '#AC0000',
    height: '112px',
  },
  userNameBox: {
    color: '#fff',
  },
  userName: {
    color: '#fff',
    // marginLeft: '12px',
  },
}))

const LayoutHeader = (props) => {
  //Auth Correction
  const { displayName, company, firstName, lastName } = useSelector(
    (state) => state.auth
  )
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { nbrVendorConfirm, logout } = props

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open, nbrVendorConfirm])

  const displayNavMenu = () => {
    return (
      <StyledToolbar>
        <BrandLogoWrapper>
          <BrandLogo src={brandLogo} alt="logo" />
        </BrandLogoWrapper>
        <ListWrapper>
          {getMenuButtons()}
          {getLogoutButton()}
        </ListWrapper>
      </StyledToolbar>
    )
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  const getLogoutButton = () => {
    return (
      <Tooltip title="Logout" placement="right-start" arrow>
        <StyledListItem
          button
          key="Logout"
          selected={selectedIndex === 1}
          onClick={() => dispatch(logout())}
          className={classes.userNameBox}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>{firstName?.charAt(0) + lastName?.charAt(0)} </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={displayName}
              secondary={company}
              // secondaryTypographyProps={{ color: '#fff' }}
            />
            <LogoutIcon />
          </ListItem>

          {/* <ListItemText primary="Logout" /> */}
        </StyledListItem>
      </Tooltip>
    )
  }

  const headersData = [
    {
      label: 'Tasks',
      href: '/',
      icon: <NavTaskIcon />,
      show: !isTPlus,
    },
    {
      label: 'Pipeline',
      href: '/Pipeline',
      icon: <VendorPipelineIcon />,
      show: !isTPlus,
    },
    {
      label: 'Projects',
      href: '/projects',
      icon: <NationalBrandIcon />,
      show: nbrVendorConfirm || isTPlus,
    },
    {
      label: 'Dashboard',
      href: '/dashboard',
      icon: <NBRDashboardIcon />,
      show: nbrVendorConfirm || isTPlus,
    },
    {
      label: 'Help',
      href: require('../assets/onboarding/onboarding.pdf'),
      icon: <HelpIcon style={{ color: '#fff' }} />,
      show: isTPlus,
      target: '_blank',
      linkComponent: 'a',
    },
  ]

  const CustomLink = ({
    href,
    target,
    icon,
    label,
    classes,
    linkComponent,
  }) => {
    // Decide which component to use
    const Link = linkComponent || NavLink

    // Common props for both link types
    const linkProps = {
      className: classes.navLink,
      ...(target ? { href, target } : { to: href }),
    }

    return (
      <Link {...linkProps}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </Link>
    )
  }

  const getMenuButtons = () => {
    return headersData.map(
      ({ label, href, icon, show, target, linkComponent }, index) => {
        if (!show) return null
        return (
          <Tooltip key={label} title={label} placement="right-start" arrow>
            <StyledListItem
              button
              key={label}
              // selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <CustomLink
                href={href}
                target={target}
                icon={icon}
                label={label}
                classes={classes}
                linkComponent={linkComponent}
              />
            </StyledListItem>
          </Tooltip>
        )
      }
    )
  }

  return (
    <>
      <ElevationScroll {...props}>
        <StyledLayoutHeader>{displayNavMenu()}</StyledLayoutHeader>
      </ElevationScroll>
    </>
  )
}

export default LayoutHeader
