/* eslint-disable */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import ReModal from '../../components/Modal/Modal'
import sample from '../assets/sample.xls'
import * as XLSX from 'xlsx'
//image
import { AddCircleOutline } from '@material-ui/icons'
import TrickWithUnder from '../assets/images/TrickwithUnderline.svg'
//style
import './NbrStyles/Style.scss'
import { style } from './NbrStyles/NBrStyle'
//Actions
import { uploadAddTcin, resetState } from '../store/NationalBrand/ActionCreator'
import { CSVLink } from 'react-csv'
import { tcinTemplate } from '../../helpers/SampleFiles'
import { showNotification } from '../Notifications/NotificationActionCreator'

const serviceTypes = [
  {
    title: 'Ingest 3D Image',
    price: '',
    details: `Upload your existing 3D image for quality checks and have it published onto Target.com at no extra charge. Will require edits if it does not meet Target's quality requirements.`,
  },
  {
    title: 'Create 3D Image',
    price: '$100/Item',
    details:
      'Build a 3D image for the item. The 3D image will be Target owned and cannot be used externally.',
  },
  {
    title: 'Create & License 3D Image',
    price: '$120/Item',
    details:
      'Build a 3D image for the item and own the rights to download the 3D image for external use. ',
  },
]

const NBrAddTcin = ({
  openAddTcin,
  setAddTcinModal,
  title,
  projectId,
  fetchList,
}) => {
  const dispatch = useDispatch()
  const { email } = useSelector((state) => state.auth)
  const [file, setFile] = useState(null)
  const { addTcin } = useSelector((state) => state.nationalBrand)
  const [loader, setLoader] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const [tcinResponseList, setTcinResponseList] = useState(null)
  const [trackFileName, setTrackFilename] = useState([])
  useEffect(() => {
    if (addTcin && addTcin[0] === 'error') {
      setLoader(false)
      setShowLink(false)
      setFile([])
      setAddTcinModal(false)
      dispatch(
        showNotification(
          true,
          'Something Went Wrong !! Please Try After Some Time',
          'error'
        )
      )
    }
    if (
      addTcin[0] !== 'error' &&
      addTcin.tcin_list &&
      addTcin.tcin_list.length > 0
    ) {
      setLoader(false)
      setShowLink(true)
      let failTcinCount = 0
      let succesTcinCount = 0
      addTcin.tcin_list.map((item) => {
        if (item.verification_status === 'FAILURE') {
          failTcinCount += 1
        }
        if (item.verification_status === 'SUCCESS') {
          succesTcinCount += 1
        }
      })
      setTcinResponseList({
        fail: failTcinCount,
        Success: succesTcinCount,
      })
    }
    // eslint-disable-next-line
  }, [addTcin, showLink])

  const csvClean = (csv) => {
    var lines = csv.split('\n')
    var result = []
    let regexMatch
    lines.map((item, index) => {
      if (index > 0) {
        let obj = {}
        let splits = item.split(',')
        let tcin = splits[0]?.replace(/\s/g, '')
        let input_type = splits[2]?.replace(/\s/g, '')
        let service_type = splits[1]?.replace(/\s/g, '')
        console.log({ tcin, input_type, service_type })
        if (tcin !== '') {
          regexMatch = new RegExp('^\\d+$').test(tcin)
          if (regexMatch === true && tcin > 8) {
            obj['tcin'] = tcin
            obj['input_type'] = input_type
            obj['service_type'] = service_type
            result.push(obj)
          } else {
            dispatch(
              showNotification(
                true,
                'File Structure Wrong!! Try Aagin',
                'error'
              )
            )
            dispatch(resetState('addTcin'))
          }
        }
      }
      return result
    })
    if (result.length > 0) {
      dispatch(
        uploadAddTcin(projectId, email, nbrVendorConfirm.vendor_id, result)
      )
    } else {
      setLoader(false)
      setFile([])
      setAddTcinModal(false)
      setShowLink(false)
      dispatch(
        showNotification(true, 'File Structure Wrong!! Try Aagin', 'error')
      )
      dispatch(resetState('addTcin'))
    }
  }

  const handleUpload = (e) => {
    e.preventDefault()
    if (e.target.files[0]) {
      setLoader(true)
      setFile(e.target.files[0])
      setTcinResponseList({})
      setTrackFilename([...trackFileName, e.target.files[0]])
    }
    var name = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      /* Update state */
      csvClean(data)
    }
    reader.readAsBinaryString(name)
  }

  // Proceed will able to close modal if user upload file
  const handleClickProceed = () => {
    if (
      tcinResponseList !== null &&
      tcinResponseList['fail'] === 0 &&
      tcinResponseList['Success'] > 0
    ) {
      dispatch(
        showNotification(true, `All Tcin Successfully Uploaded`, 'success')
      )
    }
    if (
      tcinResponseList !== null &&
      tcinResponseList['fail'] > 0 &&
      tcinResponseList['Success'] > 0
    ) {
      dispatch(
        showNotification(
          true,
          `Tcin Successfully Added : ${tcinResponseList['Success']} and Failed to Add : ${tcinResponseList['fail']}`,
          'success'
        )
      )
    }
    setAddTcinModal(false)
    setFile([])
    setShowLink(false)
    dispatch(resetState('addTcin'))
    fetchList()
    setTrackFilename([])
    setTcinResponseList(null)
  }

  // to generate CSV file which they upload
  const conditionForCSV =
    addTcin &&
    !loader &&
    showLink &&
    addTcin[0] !== 'error' &&
    addTcin.tcin_list &&
    addTcin.tcin_list.length > 0

  // putting condition if uploading start and user try to close modal
  const handleCloseAddTcin = () => {
    if (loader) {
      setAddTcinModal(true)
    } else {
      dispatch(resetState('addTcin'))
      setAddTcinModal(false)
      setShowLink(false)
      setFile([])
      setTrackFilename([])
    }
    if (
      tcinResponseList !== null &&
      tcinResponseList['fail'] > 0 &&
      tcinResponseList['Success'] === 0
    ) {
      dispatch(
        showNotification(
          true,
          `Failed To Add Tcin : ${tcinResponseList['fail']}`,
          'error'
        )
      )
    }
    setTcinResponseList(null)
  }

  return (
    openAddTcin && (
      <ReModal open={openAddTcin}>
        <Box sx={style} className="Add-Tcin-modal">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4px 1rem',
              borderBottom: '1px solid #CBCBCB',
            }}
          >
            <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
              Add TCIN(s) to project {`(${title})`}
            </Typography>
            <IconButton
              onClick={handleCloseAddTcin}
              style={{ padding: '2px' }}
              disabled={
                tcinResponseList && tcinResponseList['Success'] !== 0
                  ? true
                  : false
              }
            >
              <Clear />
            </IconButton>
          </div>
          <Grid container>
            <Grid
              item
              xs={6}
              style={{
                borderRight: '1px solid #CBCBCB',
                padding: '12px 16px 12px 16px',
              }}
            >
              <div className="upload-view-link">
                <p style={{ fontSize: '14px', lineHeight: '18px' }}>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: 'black',
                    }}
                  >
                    STEP 1:
                  </span>{' '}
                  Download&nbsp;
                  <a
                    href={sample}
                    download="template"
                    style={{ color: 'rgb(69 112 210' }}
                  >
                    Template.file
                  </a>{' '}
                  &nbsp;to add TCIN(s) and select service type and input type
                  for each TCIN.
                </p>
              </div>
              <div style={{ padding: '8px 0' }}>
                <p>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: 'black',
                    }}
                  >
                    STEP 2:
                  </span>{' '}
                  Upload Template file
                </p>
                <form
                  method="POST"
                  id="uploadForm"
                  name="theUploadForm"
                  encType="multipart/form-data"
                  className="upload-Button"
                >
                  <input
                    type="file"
                    name="fileInput"
                    id="fileInput"
                    onChange={handleUpload}
                    hidden
                    accept=".xlsx,.xls"
                  />
                  <label htmlFor="fileInput">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AddCircleOutline />
                      <span>Upload .xlsx/xls file</span>
                    </div>
                    <p>click here to upload</p>
                  </label>
                </form>
                <div className="upload-file-Names">
                  <div>{file && file.name} </div>{' '}
                  {loader && <CircularProgress />}
                  {conditionForCSV && (
                    <div className="upload-with-icon">
                      <p className="upload-complete">upload complete</p>{' '}
                      <img src={TrickWithUnder} alt="TrickWithUnderline" />
                    </div>
                  )}
                </div>
                <div className="proceed-btn">
                  {conditionForCSV && tcinResponseList !== null && (
                    <div className="result-tcin-report">
                      <div
                        className={
                          tcinResponseList['fail'] > 0
                            ? 'tcin-list download-tcin-list'
                            : 'tcin-list'
                        }
                      >
                        <p>
                          <span>Successfully Added Tcin : </span>
                          <span className="success">
                            ({tcinResponseList['Success']})
                          </span>
                        </p>

                        <div className="fail-container">
                          <span>
                            Failed to Add Tcin :{' '}
                            <span className="fail">
                              ({tcinResponseList['fail']})
                            </span>
                          </span>

                          {tcinResponseList['fail'] > 0 && (
                            <CSVLink
                              data={
                                addTcin.tcin_list.length > 0
                                  ? addTcin.tcin_list
                                  : ''
                              }
                              target="_blank"
                              filename={`${title}Tcin_Report.csv`}
                            >
                              Download Fail Tcin Report
                            </CSVLink>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  className={
                    addTcin?.tcin_list?.length > 0 &&
                    tcinResponseList['Success'] > 0
                      ? 'btn-upload btn-active'
                      : 'btn-upload'
                  }
                  disabled={
                    tcinResponseList !== null &&
                    tcinResponseList.hasOwnProperty('Success') &&
                    tcinResponseList['Success'] > 0
                      ? false
                      : true
                  }
                  onClick={handleClickProceed}
                >
                  Proceed
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ backgroundColor: '#E6E7EA', padding: '.6rem 0 0 0' }}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: '500',
                    fontSize: '14px',
                    padding: '6px 0 10px 1rem',
                    borderBottom: '1px solid #CBCBCB',
                  }}
                >
                  3D Services Offered by Target Plus
                </Typography>
                {serviceTypes.map((item) => {
                  return (
                    <Box className="service-type-box">
                      <div className="service-type-details">
                        <p style={{ fontSize: '15px', fontWeight: '500' }}>
                          {item.title}
                        </p>
                        <p
                          style={{
                            backgroundColor: item.price !== '' && '#C9D9F8',
                            marginLeft: '5px',
                            borderRadius: '50px',
                            color: '#000',
                            fontSize: '12px',
                            fontWeight: 400,
                            textAlign: 'center',
                            padding: '5px',
                            border: item.price !== '' && '1px solid #8B8F98',
                          }}
                        >
                          {item.price}
                        </p>
                      </div>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '12px',

                          color: '#676B74',
                        }}
                      >
                        {item.details}
                      </p>
                    </Box>
                  )
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ReModal>
    )
  )
}

export default NBrAddTcin
