import {
  CREATE_PROJECT,
  FETCH_COMMENTS,
  FETCH_PROJECT,
  FETCH_TCIN_LIST,
  NATIONAL_BRAND,
  RESET_STATE,
  UPLOAD_TCIN_ADD,
  UPDATE_TCIN_STATUS,
  INITIATE_FETCH_TCIN_LIST,
  FETCH_TCIN_LIST_ERROR,
  VERIFICATION_TCIN_RESPONSE,
  VERIFICATION_TCIN_FAILURE,
  NBR_DASHBOARD_FETCH,
  FECTH_PROJECT_DETAILS,
  INTIAL_STATE_TYPE,
} from './ActionType'

const initialState = {
  nbrVendorConfirm: { nbrVendorAPIStatus: INTIAL_STATE_TYPE },
  createdProject: [],
  tcinComments: [],
  loader: false,
  projectList: [],
  tcinList: [],
  totalTcins: 0,
  addTcin: [],
  tcinLoading: false,
  verficationTcinList: {},
  showVerificationList: false,
  nbrDashboardData: {},
  projectDetails: {},
}

export default function NationalBrandReducers(state = initialState, action) {
  switch (action.type) {
    case NATIONAL_BRAND: {
      return {
        ...state,
        nbrVendorConfirm: action.payload,
      }
    }
    case 'FETCH_PROJECT_INITIAL': {
      return {
        ...state,
        projectList: [],
        loader: true,
      }
    }
    case FETCH_PROJECT: {
      return {
        ...state,
        projectList: [action.payload],
        loader: false,
      }
    }
    case CREATE_PROJECT:
      return {
        ...state,
        createdProject: [...state.createdProject, action.payload],
      }
    case FETCH_COMMENTS:
      return {
        ...state,
        tcinComments: [...action.payload],
      }

    case INITIATE_FETCH_TCIN_LIST: {
      return {
        ...state,
        tcinLoading: true,
      }
    }
    case FETCH_TCIN_LIST: {
      const {
        addData,
        payload: { search_response, total_count },
      } = action
      let data = []
      if (addData) {
        data = [...state.tcinList, ...search_response]
      } else {
        data = [...search_response]
      }
      return {
        ...state,
        tcinList: [...data],
        totalTcins: total_count,
        tcinLoading: false,
      }
    }
    case FETCH_TCIN_LIST_ERROR: {
      return {
        ...state,
        tcinLoading: false,
      }
    }
    case UPLOAD_TCIN_ADD: {
      return {
        ...state,
        addTcin: action.payload,
      }
    }
    case RESET_STATE: {
      // need to make
      // const resetToState = state[action.payload]
      return {
        ...state,
        addTcin: [],
      }
    }
    case UPDATE_TCIN_STATUS: {
      const {
        payload: { status, tcin },
      } = action
      let list = JSON.parse(JSON.stringify(state.tcinList))
      const index = list.findIndex(
        (data) => data?.multi_task_tcin_listing_pk?.tcin === tcin
      )
      if (index > -1) {
        let statusIndex = list[index]?.production_phase?.findIndex(
          (task) => task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA'
        )
        if (statusIndex > -1) {
          list[index].production_phase[statusIndex].status_name = status
        }
      }
      return {
        ...state,
        tcinList: [...list],
      }
    }
    case VERIFICATION_TCIN_RESPONSE: {
      return {
        ...state,
        verficationTcinList: action.payload,
        showVerificationList: action.showVerificationList,
      }
    }
    case VERIFICATION_TCIN_FAILURE: {
      return {
        ...state,
        verficationTcinFailed: action.payload,
        showVerificationList: false,
      }
    }
    case NBR_DASHBOARD_FETCH: {
      return {
        ...state,
        nbrDashboardData: action.payload,
      }
    }
    case 'AIL_DASHBOARD_RES': {
      return {
        ...state,
        nbrDashboardFail: action.payload,
      }
    }
    case FECTH_PROJECT_DETAILS: {
      return {
        ...state,
        projectDetails: action.payload,
      }
    }
    default:
      return state
  }
}
