import auth from '../Auth/AuthReducer'
import { combineReducers } from 'redux'
import layout from '../Layout/LayoutReducer'
import notification from '../Notifications/NotificationReducer'
import { reducer as formReducer } from 'redux-form'
import errorMessages from '../../helpers/ErrorMessaging/ErrorMessageReducer'
import signInPrompt from '../SignInPrompt/SignInPromptReducer'
//
import NationalBrandReducers from './NationalBrand/reducers'

const rootReducer = combineReducers({
  auth,
  layout,
  notification,
  form: formReducer,
  errorMessages,
  signInPrompt,
  nationalBrand: NationalBrandReducers,
})

export default rootReducer
