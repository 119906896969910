import styled from 'styled-components'
import { ListItem, Toolbar, AppBar, List } from '@material-ui/core'
// import AppBar from '@material-ui/core/AppBar'
// import List from '@material-ui/core/ListItem'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import StorageIcon from '@material-ui/icons/Storage'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import { ReactComponent as NationalBrand } from '../assets/images/NationalBrand.svg'
import { ReactComponent as DashBoardIcon } from '../assets/images/DashBoardIcon.svg'

export const ListWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`
export const BrandLogoWrapper = styled.div`
  width: 60px;
  display: inline-block;
  max-height: 60px;
`
export const BrandLogo = styled.img`
  top: 10px;
  left: 20px;
  width: 60px;
  border: 0;
  position: absolute;
  vertical-align: middle;
`
export const StyledListItem = styled(ListItem)`
  // display: block;
  padding: 6px 28px;
  width: auto;
`
export const StyledLogoutWrapper = styled(List)`
  top: -7px;
`
export const NavTaskIcon = styled(AssignmentTurnedInIcon)`
  font-size: 32px;
  color: #fff;
  margin-left: 6px;
  padding: 0 2px;
`
export const StorageGridIcon = styled(StorageIcon)`
  font-size: 36px;
  color: #fff;
`
export const LogoutIcon = styled(ExitToAppIcon)`
  font-size: 32px;
  color: #fff;
  margin-left: 10px;
  padding: 0 2px;
`
export const MainContent = styled.main`
  flex-grow: 1;
  // padding: 0.75rem;
  width: 100%;
`
export const StyledLayoutHeader = styled(AppBar)`
  color: #ffffff;
  background-color: #ac0000;
  height: 84px;
  z-index: 1101;
`
export const VendorPipelineIcon = styled(PeopleAltIcon)`
  font-size: 32px;
  color: #fff;
  margin-left: 10px;
  padding: 0 2px;
`
export const NationalBrandIcon = styled(NationalBrand)`
  margin: 6px 0 6px 13px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

export const NBRDashboardIcon = styled(DashBoardIcon)`
  margin: 6px 0 6px 27px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`
export const StyledToolbar = styled(Toolbar)`
  // display: flex;
  // justify-content: flex-end;
`
