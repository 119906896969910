export const FETCH_TCIN_LIST = 'FETCH_TCIN_LIST'
export const FETCH_TCIN_LIST_ERROR = 'FETCH_TCIN_LIST_ERROR'

// Check National Brand
export const NATIONAL_BRAND = 'NATIONAL_BRAND'

// Fetch  Project ---- To fetch project from API in National Brand case
export const FETCH_PROJECT = 'FETCH_PROJECT'

// Create Project  ---- To create project for national brand
export const CREATE_PROJECT = 'CREATE_PROJECT'

// Post comments ----  To Post commenst
export const POST_COMMENTS = 'POST_COMMENTS'

// Fetch Comments  ---  To get all Comments
export const FETCH_COMMENTS = 'FETCH_COMMENTS'

// Upload Tcin
export const UPLOAD_TCIN_ADD = 'UPLOAD_TCIN_ADD'

// Reset state of add tcins
export const RESET_STATE = ' RESET_STATE'

// donwload file tcin wise
export const DOWNLOAD_FILE_ASSETS = 'DOWNLOAD_FILE_ASSETS'

// Verification of tcins
export const VERIFICATION_TCIN_RESPONSE = 'VERIFICATION_TCIN_RESPONSE'
export const VERIFICATION_TCIN_FAILURE = 'VERIFICATION_TCIN_FAILURE'
export const FECTH_PROJECT_DETAILS = 'FECTH_PROJECT_DETAILS'

export const UPDATE_TCIN_STATUS = 'UPDATE_TCIN_STATUS'
export const INITIATE_FETCH_TCIN_LIST = 'INITIATE_FETCH_TCIN_LIST'

//Dashboard
export const NBR_DASHBOARD_FETCH = 'NBR_DASHBOARD_FETCH'

//Initial state
export const INTIAL_STATE_TYPE = 'INTIAL_STATE'
