import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination } from '@material-ui/lab'
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core'
import noResultsImage from '../assets/images/No-results.svg'
import usePagination from './Pagination'
import { fetchProject } from '../store/NationalBrand/ActionCreator'
import _ from 'lodash'
//style
import './NbrStyles/Style.scss'
import { alerterDate } from '../../components/Sandbox/Helpers'
// import { isTPlus } from '../Auth/Auth'

const NBrProjectCard = (props) => {
  const dispatch = useDispatch()
  // loader for project list
  const [loaderForList, setLoaderForList] = useState(true)
  const { projectList, loader } = useSelector((state) => state.nationalBrand)
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const itemsPerPage = 15
  const _DATA = usePagination(projectList, loaderForList, itemsPerPage)
  const [countProject, setCountProject] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [projects, setProjects] = useState([])
  //loading initial data list

  useEffect(() => {
    if (nbrVendorConfirm && nbrVendorConfirm.message === 'error in login') {
      setLoaderForList(false)
      setIsLoading(false)
    }
    if (
      nbrVendorConfirm &&
      nbrVendorConfirm.nbrVendorAPIStatus === true &&
      props.currentTab
    ) {
      setPage(1)
      getProjectList(1)
    }
    // eslint-disable-next-line
  }, [nbrVendorConfirm, nbrVendorConfirm.message, props.currentTab])

  const handleViewProject = useCallback(
    (project) => {
      if (project) {
        setPage(1)
        props.history.push(
          `projects/${project.project_uuid}?activeTab=${props.currentTab}`
        )
      }
    },
    [props.history, props.currentTab]
  )

  useEffect(() => {
    if (
      props.currentTab === 'ACTIVE' &&
      projectList?.length &&
      projectList[0]?.search_response?.length &&
      projectList[0]?.search_response[0]?.status === 'ACTIVE'
    ) {
      setProjects([])
      setIsLoading(false)
      handleViewProject(projectList[0].search_response[0])
    }
  }, [projectList, props.currentTab, handleViewProject])

  const getProjectList = (newPage) => {
    if (nbrVendorConfirm && nbrVendorConfirm.vendor_id) {
      setIsLoading(true)
      let payload = {
        count: itemsPerPage,
        page: newPage || page,
        projectType: 'Non Tcin',
        sortBy: 'project_create_date',
        sortOrder: 'desc',
        vendorId: nbrVendorConfirm.vendor_id,
        status: props.currentTab,
      }
      setProjects([])
      dispatch(fetchProject(payload))
    }
  }

  useEffect(() => {
    setCountProject(projectList)
    if (countProject.length > 0) {
      setLoaderForList(false)
    }
    if (projectList.length <= 0) {
      setIsLoading(true)
    }
    if (page === 1) {
      setCount(
        projectList.length > 0 && nbrVendorConfirm.message !== 'error in login'
          ? Math.ceil(projectList[0]?.total_count / itemsPerPage)
          : 1
      )
    }
    if (
      loaderForList === false &&
      Object.keys(projectList).length > 0 &&
      countProject[0] &&
      projectList[0] !== 'error' &&
      countProject[0]?.search_response
    ) {
      setProjects(countProject[0]?.search_response)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [projectList, countProject, loader, page, loaderForList, props.currentTab])

  //for changing pages
  const handleChange = (e, p) => {
    props.setNewProject(false)
    setPage(p)
    //to collect data and populate
    setCountProject([])
    setProjects([])
    setLoaderForList(true)
    getProjectList(p)
    //For going to one to another
    _DATA.jump(p)
  }
  return (
    <>
      <Box className="loader-linear">
        {loaderForList || isLoading ? (
          <CircularProgress
            size={55}
            className="project-list-progress-bar"
            thickness={2.8}
          />
        ) : (
          <></>
        )}
      </Box>
      <Grid className="project-card-container" container tabIndex="0">
        {projects?.map((item, index) => {
          return (
            <div className="project-card" key={index}>
              <div className="project-card-inner">
                <div className="project-card-inner-structure">
                  <span className="project-name-inner">Project name :</span>
                  <Tooltip
                    title={item?.project_name}
                    aria-label="add"
                    placement="top"
                  >
                    <span style={{ marginLeft: '15px' }}>
                      {_.truncate(item?.project_name, { length: 25 })}
                    </span>
                  </Tooltip>
                </div>
                <div>
                  <span className="project-name-inner">Created date :</span>
                  <span style={{ marginLeft: '15px' }}>
                    {alerterDate(new Date(item?.project_create_date))}
                  </span>
                </div>
              </div>
              <div className="project-tcin-view-project">
                <p>{item?.total_tcins_count} TCINs added </p>
                <Button
                  onClick={() => {
                    handleViewProject(item)
                  }}
                  style={{ textTransform: 'none' }}
                >
                  View Project
                </Button>
              </div>
            </div>
          )
        })}
        {projectList[0] === 'error' && (
          <Grid className="dashboard-noResult">
            <img src={noResultsImage} alt="Project" height="56" />
            <Typography>No Results</Typography>
            <Typography>Please Try After Some Time</Typography>
          </Grid>
        )}

        {nbrVendorConfirm && nbrVendorConfirm.message === 'error in login' && (
          <Grid className="dashboard-noResult">
            <img src={noResultsImage} alt="Project" height="56" />
            <Typography>Not Available</Typography>
          </Grid>
        )}

        {projects.length > 0 && projectList[0]?.total_count === 0 && (
          <Grid className="dashboard-noResult">
            <img src={noResultsImage} alt="Project" height="56" />
            <Typography>No Results</Typography>
            <Typography>
              You haven't created a project yet, Go ahead!
            </Typography>
          </Grid>
        )}
      </Grid>
      <Pagination
        count={count}
        size="large"
        page={props.newProjectCreate ? 1 : page}
        variant="outlined"
        shape="rounded"
        className="pagination-project"
        onChange={handleChange}
        disabled={
          nbrVendorConfirm && nbrVendorConfirm.message === 'error in login'
        }
      />
    </>
  )
}

export default NBrProjectCard
