/* eslint-disable */
import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  getContractStatus,
  acceptTnc,
} from '../store/NationalBrand/ActionCreator'

export default function LegalWarningModal({ handleClose, logout = () => {} }) {
  const [open, setOpen] = useState(false)
  const [version, setVersion] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const [verbiage, setVerbiage] = useState(
    `By submitting digital images ("Assets") on this platform, you are providing a license to Target to create 3D representations of the Assets and you agree that such creation and related services are governed by, and subject to, the Target Platform Services Agreement between you and Target.`
  )
  const nbrVendor = useSelector(
    ({ nationalBrand: { nbrVendorConfirm } }) => nbrVendorConfirm
  )

  useEffect(() => {
    if (nbrVendor?.vendor_id) {
      //get the details about previous confirmation and aggrement text

      getContractStatus()
        .then((res) => {
          const { data } = res
          onApproval(data)
        })
        .catch((e) => {
          console.log(e)
          onApproval({})
        })
    }
  }, [nbrVendor.vendor_id])

  const acceptConditions = () => {
    setSubmitting(true)
    acceptTnc({
      vendor_name: nbrVendor?.vendor_name,
      vendor_id: nbrVendor?.vendor_id,
      agreement_status: 'ACCEPTED',
      contract_verbiage: verbiage,
      contract_version: version,
    })
      .then((res) => {
        onApproval(res?.data)
      })
      .catch((e) => {
        console.log('error in approving TnC')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const rejectConditions = () => {
    return acceptTnc({
      vendor_name: nbrVendor?.vendor_name,
      vendor_id: nbrVendor?.vendor_id,
      agreement_status: 'REJECTED',
      contract_verbiage: verbiage,
      contract_version: version,
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((e) => {
        console.log('error in rejecting TnC')
      })
      .finally(() => {
        logout()
      })
  }

  const onApproval = (data) => {
    //if status is accepted, then close the modal window
    const { agreement_status, contract_verbiage, contract_version } = data
    if ('ACCEPTED' !== agreement_status) {
      // if (contract_verbiage && contract_verbiage !== '') {
      //   setVerbiage(contract_verbiage)
      // }
      setOpen(true)
      setVersion(contract_version)
    } else {
      setOpen(false)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Notice</DialogTitle>
      <Divider />
      <DialogContent>
        By submitting digital images ("Assets") on this platform, you are
        providing a license to Target to create 3D representations of the Assets
        and you agree that such creation and related services are governed by,
        and subject to, the Target Platform Services Agreement between you and
        Target.
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={submitting}
          onClick={rejectConditions}
        >
          Reject
        </Button>

        <Button
          color="primary"
          variant="outlined"
          disabled={submitting}
          onClick={acceptConditions}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
