/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { CssBaseline, debounce, Drawer, Grid, Paper } from '@material-ui/core'
import {
  Clear as ClearIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons'
import {
  ActionContainer,
  DrawerContent,
  CloseButton,
  MainContent,
} from './VendorSidePanelStyle'
import TcinDetail from './TcinDetail'
import VendorComment from './VendorComment'
import { getTcinDetail, getCommentList } from './VendorActionCreator'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import UploadFilesContainerVendorPipeline from './UploadFilesContainerVendorPipeline'
import FilesTable from './FilesTable'
import { decodeHtmlCharCodes } from '../../helpers/utils'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import { showNotification } from '../Notifications/NotificationActionCreator'
import { useDispatch } from 'react-redux'

const drawerWidth = '86%'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '157px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  rounded: {
    color: '#fff',
    backgroundColor: '#7429HH',
    width: '150px',
    height: '150px',
    border: '1px solid #eee',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    margin: '0 0 20px 0',
  },
  infoTitle: {
    textAlign: 'center',
    color: '#333',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '-28px',
  },
}))

const TcinInfoSidePanel = (props) => {
  const { window, openDrawer, closeDrawer, rowData, userId, email } = props
  const { id, tcin, prodDesc, vendorId } = rowData
  const [fileData, setFileData] = useState([])
  const [filesTableLoading, setFilesTableLoading] = useState([])
  const dispatch = useDispatch()
  const [filesTableDeterminateLoading, setFilesTableDeterminateLoading] =
    useState(false)
  const classes = useStyles()
  const theme = useTheme()

  const deleteFile = (id) => {
    const key = apiConfig.apiKey
    const url = `${apiConfig.pipeline.fileDelete}${id}?key=${key}`
    setFilesTableLoading(true)
    return axios
      .delete(url)
      .then(() => {
        setFilesTableLoading(false)
        updateFilesTable()
      })
      .catch(() => {
        setFilesTableLoading(false)
        updateFilesTable()
      })
  }

  const updateFilesTable = (delay = 0, tcin) => {
    setFilesTableLoading(true)
    if (delay > 2000) setFilesTableDeterminateLoading(true)
    else setFilesTableLoading(true)
    debounce(
      () =>
        getTcinDetail(id)
          .then((response) => {
            setFileData(_.flatMap(response.data.assets))
            if (filesTableDeterminateLoading) {
              dispatch(
                showNotification(
                  true,
                  `List of files updated for tcin: ${tcin}`,
                  'success'
                )
              )
            }
            setFilesTableLoading(false)
            setFilesTableDeterminateLoading(false)
          })
          .catch((err) => {
            setFilesTableLoading(false)
            setFilesTableDeterminateLoading(false)
            console.log(err)
          }),
      delay
    )()
  }

  useEffect(() => {
    getCommentList(tcin, vendorId)
    updateFilesTable()
  }, [id, tcin])
  const drawer = () => {
    return (
      <DrawerContent>
        <ActionContainer>
          <CloseButton
            size="small"
            onClick={() => {
              closeDrawer()
            }}
          >
            <ClearIcon />
          </CloseButton>
          <p className={classes.infoTitle}>
            {' '}
            {tcin} - {decodeHtmlCharCodes(prodDesc.props.title)}{' '}
          </p>
        </ActionContainer>
        {/* {taskSummaryLoader ? (
          <LoaderStyle>
            <CircularProgress size={60} />
          </LoaderStyle>
        ) : ( */}
        <MainContent>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={5}>
              <Paper className={classes.paper}>
                <TcinDetail rowData={rowData} />
              </Paper>
              <Paper className={classes.paper}>
                <VendorComment
                  rowData={rowData}
                  userId={userId}
                  emailOfUser={email}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Paper className={classes.paper}>
                <UploadFilesContainerVendorPipeline
                  item={rowData}
                  updateFilesTable={updateFilesTable}
                  regex={''}
                  email={email}
                  deleteFile={deleteFile}
                />
              </Paper>
              <FilesTable
                filesData={fileData}
                loading={filesTableLoading}
                loadingDeterminate={filesTableDeterminateLoading}
                deleteFile={deleteFile}
              />
            </Grid>
          </Grid>
        </MainContent>
        {/* )} */}
      </DrawerContent>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        BackdropProps={{ invisible: true }}
        variant="persistent"
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={() => {}}
        open={openDrawer}
      >
        {drawer()}
      </Drawer>
    </div>
  )
}

TcinInfoSidePanel.propTypes = {
  window: PropTypes.func,
}

export default TcinInfoSidePanel
