/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useAuth } from '@praxis/component-auth'
import {
  Breadcrumbs,
  Typography,
  Link,
  LinearProgress,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useVendorApis from './useVendorApis'
import {
  StyledHeader,
  StyledToolbar,
  TableWrapper,
  StyledToolbarHeader,
  RightSideContentWrapper,
  NoDataSection,
} from './VendorStyle'
import { forEach } from 'lodash'
import { VendorPipelineHeadCells } from '../../helpers/TableHeaders'
import TcinInfoSidePanel from './TcinInfoSidePanel'
import VendorDatatable from './VendorDatatable'
import VendorSearch from './VendorSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}))

function handleClick(event) {
  event.preventDefault()
}

const VendorPipelineLanding = (props) => {
  const auth = useAuth()
  const theme = useTheme()
  const classes = useStyles()
  const { session } = auth || {}
  const [params, setParams] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [taskDetails, setTaskDetails] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)

  const [commentsOnLoad, setCommentsOnLoad] = useState(false)
  const [selectedFiltersList, setSelectedFiltersList] = useState({})
  const [currentRowItem, setCurrentRowItem] = useState({})
  const [selectedTr, setSelectedTr] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortOrderBy, setSortOrderBy] = useState('')

  const { vendorTask, loading, getVendorsList, setVendorTask } = useVendorApis(
    params,
    pageNum,
    setPageNum
  )

  let filterBadgeCount = 0
  if (selectedFiltersList) {
    forEach(selectedFiltersList, (filters) => {
      forEach(filters, (o) => {
        filterBadgeCount++
      })
    })
  }

  const handleRowClick = (event, row) => {
    const targetEle = event.target
    if (targetEle.parentNode.tagName !== 'TR') return
    highlightRow(targetEle.parentNode)
    setOpenDrawer(true)
    setCurrentRowItem(row)
    setTaskDetails({})
  }

  const highlightRow = (tr) => {
    if (selectedTr) {
      selectedTr.classList.remove('Mui-selected')
    }
    setSelectedTr(tr)
    tr.classList.add('Mui-selected')
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
    if (commentsOnLoad) {
      setVendorTask([])
      setParams({
        loginId: session && session.userInfo.lanId,
        searchQuery: searchValue,
        sortOrder: sortOrder,
        sortBy: sortOrderBy,
      })
    }
  }

  const searchHandler = (e) => {
    setSearchValue(e.target.value)
  }

  const removeSearch = () => {
    setSearchValue('')
  }

  const search = (rows) => {
    return rows.filter(
      (row) =>
        row.tcin.indexOf(searchValue.trim()) > -1 ||
        row.dueDate.indexOf(searchValue.trim()) > -1 ||
        row.lastUpdatedAt.indexOf(searchValue.trim()) > -1 ||
        row.launchDate.indexOf(searchValue.trim()) > -1
    )
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, vendorTask.length - pageNum * rowsPerPage)

  useEffect(() => {
    setParams({
      searchQuery: searchValue,
      filters: selectedFiltersList,
      sortOrder: sortOrder,
      sortBy: sortOrderBy,
    })
  }, [sortOrder, sortOrderBy])

  const columns = useMemo(() => VendorPipelineHeadCells, [])

  return (
    <>
      <StyledHeader position="fixed">
        {loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
        <StyledToolbarHeader>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" href="/" onClick={handleClick}>
              Vendor Pipeline
            </Link>
            <Typography
              color="textPrimary"
              style={{ textTransform: 'capitalize' }}
            >
              {'Tcin List'.replace('_', ' ').toLowerCase()}
            </Typography>
          </Breadcrumbs>
          <RightSideContentWrapper>
            <VendorSearch
              placeholder="Search by TCIN,dates in dd/mm/yyyy format"
              searchValue={searchValue}
              handleChange={searchHandler}
              handleButtonClick={removeSearch}
            />
          </RightSideContentWrapper>
        </StyledToolbarHeader>
      </StyledHeader>

      <StyledToolbar />
      {
        <div
          style={{
            width: '100%',
            marginTop: '-45px',
          }}
        >
          <TableWrapper>
            {vendorTask && vendorTask.length > 0 ? (
              <VendorDatatable
                tableheaders={columns}
                tablebody={search(vendorTask)}
                onRowClick={handleRowClick}
                searchValue={searchValue || ''}
              />
            ) : loading === false && vendorTask.length === 0 ? (
              <NoDataSection>
                Currently, There is no tcin assigned
              </NoDataSection>
            ) : (
              'Loading, Please wait....'
            )}
            {currentRowItem && openDrawer ? (
              <TcinInfoSidePanel
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                rowData={currentRowItem}
                userId={session && session.userInfo.lanId}
                email={session && session.userInfo.email}
              />
            ) : null}
          </TableWrapper>
        </div>
      }
    </>
  )
}

export default VendorPipelineLanding
