/* eslint-disable */
import { useState, useEffect, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Select, MenuItem, Table } from '@mui/material'
import { jsPDF } from 'jspdf'
// import { CSVLink } from 'react-csv'
import html2canvas from 'html2canvas'
import { StyledHeader } from '../../Task/TaskStyle'
//style
import '../NbrStyles/NBrDashboardStyle.scss'
import { BarChartBar, NBrMonthStatusCard } from '../NbrStyles/NBrStyle'
//Chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
//image
import { ReactComponent as DownloadDash } from '../../assets/images/DonwloadDash.svg'
//helper
import { month } from '../../../helpers/utils'
//Action
import { nbrDashboard } from '../../store/NationalBrand/ActionCreator'

ChartJS.register(ArcElement, Tooltip, Legend)

const initialState = {
  allData: {},
  months: [],
  selectYear: new Date().getFullYear(),
  selectYearData: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'allData': {
      return { ...state, allData: action.payload }
    }
    case 'timeOfProjectMonth': {
      return { ...state, months: action.payload }
    }
    case 'selectedYears': {
      return {
        ...state,
        selectYear: action.payload,
        selectYearData: state.allData[action.payload],
      }
    }
    default:
      return state
  }
}

const NBRDashboard = () => {
  const dispatch = useDispatch()
  const [state, dispatchStateAction] = useReducer(reducer, initialState)
  const nbrVendorConfirm = useSelector(
    (state) => state.nationalBrand.nbrVendorConfirm
  )
  const nbrDashboardData = useSelector(
    (state) => state.nationalBrand.nbrDashboardData
  )
  const printRef = useRef(null)
  //Pie chart
  const [pieData, setPieData] = useState([])

  //For Active project status
  const [activeProjectStatus, setActiveProjectStatus] = useState({
    'Total TCINs': 0,
    Unassigned: 0,
    Assigned: 0,
    'In Review': 0,
    Approved: 0,
    'Review Rejected': 0,
  })

  //Overall tcin status
  const [overAllStatus, setOverAllStatus] = useState({
    'Total TCINs': 0,
    'In Progress': 0,
    Approved: 0,
    // 'On Hold': 0,
    'In Review by Partner': 0,
  })

  useEffect(() => {
    if (nbrVendorConfirm && nbrVendorConfirm.vendor_id) {
      dispatch(nbrDashboard(nbrVendorConfirm.vendor_id))
    }
  }, [nbrVendorConfirm])

  useEffect(() => {
    if (nbrDashboardData && nbrDashboardData.active_project_status) {
      const {
        Total = 0,
        UNASSIGNED = 0,
        ASSIGNED = 0,
        APPROVED = 0,
        Review_Rejected = 0,
        AWAITING_REVIEW = 0,
      } = nbrDashboardData.active_project_status

      setActiveProjectStatus({
        ...activeProjectStatus,
        'Total TCINs': Total,
        Unassigned: UNASSIGNED,
        Assigned: ASSIGNED,
        'In Review': AWAITING_REVIEW,
        Approved: APPROVED,
        'Review Rejected': Review_Rejected,
      })
    }
    if (nbrDashboardData && nbrDashboardData.over_all_tcin_status) {
      const {
        APPROVED = 0,
        ASSIGNED = 0,
        AWAITING_REVIEW = 0,
        Total = 0,
      } = nbrDashboardData.over_all_tcin_status
      setOverAllStatus({
        ...overAllStatus,
        'Total TCINs': Total,
        'In Progress': ASSIGNED,
        Approved: APPROVED,
        'In Review by Partner': AWAITING_REVIEW,
      })
    }
    if (nbrDashboardData && nbrDashboardData.total_projects_breakup) {
      const { ACTIVE, CARRY_FORWARD, COMPLETED } =
        nbrDashboardData.total_projects_breakup
      let pieArray = []
      ;(pieArray[0] = ACTIVE),
        (pieArray[1] = CARRY_FORWARD),
        (pieArray[2] = COMPLETED)
      setPieData(pieArray)
    }
    if (
      nbrDashboardData.tcin_by_time_line &&
      Object.keys(nbrDashboardData.tcin_by_time_line).length > 0
    ) {
      dispatchStateAction({
        type: 'allData',
        payload: nbrDashboardData.tcin_by_time_line,
      })
      dispatchStateAction({
        type: 'timeOfProjectMonth',
        payload: Object.keys(nbrDashboardData.tcin_by_time_line),
      })
      dispatchStateAction({
        type: 'selectedYears',
        payload: new Date().getFullYear(),
      })
    }
  }, [nbrDashboardData])

  let pieChart = {
    labels: ['Active', 'Carry Forward', 'Complete'],
    datasets: [
      {
        label: '# of Votes',
        data: pieData,
        backgroundColor: ['#D5BBFE', ' #FFE599', '#7BD0C8'],
        borderColor: ['#fff'],
        borderWidth: 5,
      },
    ],
  }

  const findValueOrNot = (item) => {
    return item ? item : 0
  }

  const handleDownloadDashboardReport = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element)
    const data = canvas.toDataURL('image/png')

    const pdf = new jsPDF()
    const imgProperties = pdf.getImageProperties(data)
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
    pdf.save(nbrVendorConfirm.vendor_name)
  }

  return (
    <div ref={printRef}>
      {/*------------------------ Header ------------------------------- */}
      <StyledHeader
        position="fixed"
        style={{
          height: '55px',
          boxShadow:
            '0px 1px 4px -1px rgb(0 0 0 / 20%), 0px 2px 5px 0px rgb(0 0 0 / 14%), 0px 3px 10px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 3rem',
          }}
        >
          <Grid item sx={{ color: 'black' }} className="header-title">
            {nbrVendorConfirm && nbrVendorConfirm.vendor_name}
          </Grid>
          <Grid item className="download-report">
            <DownloadDash />
            <a onClick={handleDownloadDashboardReport}> Download</a>
          </Grid>
        </Grid>
      </StyledHeader>

      {/*------------------------ bar chart ---------------------------- */}
      <Grid container className="active-project-status" p="34px 42px 17px 42px">
        <Grid item xs={6} md={9} lg={9} xl={9} className="active-project-bar">
          <Grid item xs={12} className="active-project-bar-chart">
            <p>Active project status</p>
            <Grid container className="active-bar-chart-chart-a">
              <Table id="q-graph">
                <tbody>
                  {Object.keys(activeProjectStatus).map((item, index) => (
                    <BarChartBar
                      title={item}
                      value={activeProjectStatus[item]}
                      qVal={index + 1}
                      classHeader="active-bar-header"
                      height={
                        !activeProjectStatus[item] > 500
                          ? `${
                              Math.round(activeProjectStatus[item] / 50) * 5
                            }px`
                          : `${Math.round(activeProjectStatus[item] / 5) * 5}px`
                      }
                    />
                  ))}
                </tbody>
              </Table>
            </Grid>
          </Grid>
          {/* Overall status of TCINs */}
          <Grid item xs={12} className="active-project-bar-bar">
            <p className="header-over">Overall status of TCINs</p>
            <Grid container className="active-project-bar-cn" columnSpacing={1}>
              {Object.keys(overAllStatus).map((item, index) => {
                return (
                  <Grid
                    item
                    xs={5}
                    sm={4}
                    md={2}
                    key={index}
                    className="project-bar-status"
                  >
                    <p className="project-bar-one">{item}</p>
                    <p className="project-bar-two">{overAllStatus[item]}</p>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5}
          sm={5}
          md={2}
          lg={2}
          xl={2}
          ml={1}
          className="active-project-curve"
        >
          <p className="header-over">Total Projects breakup</p>
          <Grid item p={1}>
            <Pie
              data={pieChart}
              fallbackContent="Chart Unavailable"
              width="80%"
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                    align: 'middle',
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {/*------------------------ Main Cards --------------------------- */}
      <Grid container padding="21px 42px">
        {/* ---------Selection and heading--------- */}
        <Grid item xs={12} display="flex" columnGap={2}>
          <Grid item xs={2} borderRight="1px solid black">
            <p className="main-card-header">
              Overall status of TCINs by Timeline{' '}
            </p>
          </Grid>
          <Grid item xs={3} display="flex" height="30px">
            <p className="main-card-header">Select Year</p>
            <Select
              value={state.selectYear}
              displayEmpty
              sx={{ minWidth: 120, height: '30px', ml: '1rem', p: 0 }}
              onChange={(event) =>
                dispatchStateAction({
                  type: 'selectedYears',
                  payload: event.target.value,
                })
              }
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>select the value</em>
              </MenuItem>
              {state.months.map((years, index) => {
                return (
                  <MenuItem
                    selected={true}
                    value={years.substring(0, 4)}
                    key={index}
                  >
                    {years.substring(0, 4)}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>
        {/* ---------Cards------------- */}
        <Grid container mt={2} tabIndex={-1} className="month-card-container">
          {state.selectYearData &&
            Object.keys(state.selectYearData).map((item, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={2}
                  xl={2}
                  key={index}
                  className="month-card"
                >
                  <Grid container className="month-card-header">
                    <p>
                      {month[Number(item)]} {state.selectYear}
                    </p>
                  </Grid>
                  <Grid container mt="16px">
                    <NBrMonthStatusCard
                      xs={6}
                      title={'TCINs Initiated'}
                      value={findValueOrNot(state.selectYearData[item].Total)}
                      classHeader="header-header-sub"
                      classValue="header-value-sub"
                    />
                    <NBrMonthStatusCard
                      xs={6}
                      title="In Progress"
                      value={findValueOrNot(
                        state.selectYearData[item].ASSIGNED
                      )}
                      classHeader="header-header-sub"
                      classValue="header-value-sub"
                    />
                  </Grid>
                  <Grid container mt="16px">
                    <NBrMonthStatusCard
                      xs={6}
                      title="In Review"
                      value={findValueOrNot(
                        state.selectYearData[item].AWAITING_REVIEW
                      )}
                      classHeader="header-header-sub"
                      classValue="header-value-sub"
                    />
                    <NBrMonthStatusCard
                      xs={6}
                      title="Approved"
                      value={findValueOrNot(
                        state.selectYearData[item].APPROVED
                      )}
                      classHeader="header-header-sub"
                      classValue="header-value-sub"
                    />
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </div>
  )
}

export default NBRDashboard
