import React from 'react'
import { formatBytes } from '../Helpers'
import { Typography, withStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import axios from 'axios'
import { apiConfig } from '../Lib/config'
import { populateFiles } from '../Helpers'
const style = (theme) => ({
  root: {
    flexDirection: 'column',
  },
  fileDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsDataName: {
    wordBreak: 'break-word',
    '&:hover': {
      color: '#cc0000',
      cursor: 'pointer',
    },
  },
  error: {
    backgroundColor: '#cc0000',
  },
})

class Files extends React.Component {
  downloadFile = (e, name, url) => {
    const { showNotification } = this.props
    let _self = this
    e.preventDefault()
    //URL to be modified once API confirmed
    showNotification(true, 'File download Initiated', 'success')
    axios
      .get(
        apiConfig.threeDViewer.downloadFiles + url + '?key=' + apiConfig.apiKey,
        { responseType: 'blob' }
      )
      .then((response) => {
        return response.status === 200 ? new Blob([response.data]) : ''
      })
      .then((blob) => {
        if (blob !== '') {
          _self.downloadBlob(name, blob)
          showNotification(true, 'File Download Complete', 'success')
        } else {
          //error notification
          showNotification(true, 'Unable to Download File', 'error')
        }
      })
  }

  downloadBlob(name, blob) {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = name
    a.click()
  }

  render() {
    const { classes, data } = this.props
    const formattedFileList = populateFiles(data)
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Files</Typography>
        </AccordionSummary>
        {formattedFileList.map((formatFile) => (
          <>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ fontWeight: 500 }}
            >
              {formatFile.name}
            </Typography>
            <AccordionDetails className={classes.root}>
              {formatFile.assets.length !== 0 ? (
                formatFile.assets.map((file) => (
                  <div className={classes.fileDetails}>
                    <div
                      className={classes.detailsDataName}
                      onClick={(e) =>
                        this.downloadFile(e, file.name, file.url.file_url)
                      }
                    >
                      {file.name}
                    </div>
                    <div>{formatBytes(file.url.file_size)}</div>
                  </div>
                ))
              ) : (
                <div className={classes.error}>No files</div>
              )}
            </AccordionDetails>
          </>
        ))}
      </Accordion>
    )
  }
}

export default withStyles(style)(Files)
