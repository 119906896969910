import React, { useState } from 'react'
import '@cgi/model-viewer'
import GVMPanner from '../Lib/Panning'
import { apiConfig } from '../Lib/config'

const GltfViewer = ({
  asset,
  isDrag = false,
  inInches,
  showgrid = true,
  showpivot = true,
  wireframe = false,
  dimensions = true,
  light = true,
  onAssetLoaded = () => {},
  update = 0,
  materialList,
  comments,
  annotationMode,
  activeComment,
  autoFocus,
  selectComment,
  onNewHotspot,
  activeHotspot,
  removeHotspot,
  serviceType,
  ...props
}) => {
  const [modelUrl, setModelUrl] = React.useState('')
  const [tri, setTri] = React.useState(0)
  const [assetLoaded, setAssetLoaded] = useState(false)
  const [selectedHotspot, setSelectedHotspot] = useState('')
  const [hotspotCounter, setHotspotCounter] = useState(0)
  const annotationModeRef = React.useRef(annotationMode)
  const [showAnnotaions, setShowAnnotations] = useState(annotationMode)
  const [current, setCurrent] = useState(null)

  const viewer = React.useRef(null)
  const {
    model: { name },
  } = asset

  React.useEffect(() => {
    if (name !== '') {
      setModel()
    }
    // eslint-disable-next-line
  }, [name])

  React.useEffect(() => {
    if (update > 0) {
      materialList.forEach(({ alphaCutoff, name, doubleSided, type }) => {
        updateMaterialData(type, alphaCutoff, doubleSided, name)
      })
    }
    // eslint-disable-next-line
  }, [update])

  const updateMaterialData = (type, alphaCutoff, doubleSided, name) => {
    if (
      assetLoaded &&
      viewer.current.model &&
      (viewer.current.model.materials || []).length
    ) {
      viewer.current.model.materials.forEach((item) => {
        if (item.name === name) {
          item.setAlphaMode(type)
          item.setDoubleSided(doubleSided)
          if (alphaCutoff && alphaCutoff !== null) {
            item.setAlphaCutoff(Number(alphaCutoff))
          }
        }
      })
    }
  }

  React.useEffect(() => {
    GVMPanner(viewer.current)
    setCurrent(viewer.current)
    // viewer.current.addEventListener('load', () => {
    //   //const endTime = new Date().getTime()
    //   // postEvent({
    //   //   eventType: 'Load Asset',
    //   //   startTime: this.startTime,
    //   //   endTime,
    //   //   data: { assetName: this.assetName }
    //   // })
    // })

    viewer.current.addEventListener('scene-graph-ready', (resp) => {
      materialList.forEach(({ alphaCutoff, name, doubleSided, type }) => {
        updateMaterialData(type, alphaCutoff, doubleSided, name)
      })
      const materials = viewer?.current?.model.materials.map((item) => {
        return {
          name: item.name,
          type: item.alphaMode,
          alphaCutoff: item.alphaCutoff || '0.0',
          meshNames: [item.name],
          doubleSided: item.doubleSided,
        }
      })

      if (!inInches) {
        viewer?.current?.convertDimensions()
      }
      setAssetLoaded(true)
      onAssetLoaded(materials)
      setTimeout(() => {
        countTriangles(viewer?.current?.getThreeObject())
      }, 500)
      viewer?.current?.addEventListener('click', createAnnotation)
    })
    return () => {
      if (current) current.removeEventListener('click', createAnnotation)
    }
    // eslint-disable-next-line
  }, [])

  const applyEnvironmentSettings = () => {
    if (showpivot) {
      viewer.current.showPivot()
    } else {
      viewer.current.hidePivot()
    }
    if (showgrid) {
      viewer.current.showGrid()
    } else {
      viewer.current.hideGrid()
    }

    if (dimensions) {
      viewer.current.showDimensions()
    } else {
      viewer.current.dismissDimensions()
    }
  }

  React.useEffect(applyEnvironmentSettings, [showpivot, showgrid, dimensions])

  React.useEffect(() => {
    if (assetLoaded && viewer.current.model.materials.length) {
      viewer.current.model.materials.forEach((item) => {
        item.setWireframe(wireframe)
      })
    }
    // eslint-disable-next-line
  }, [wireframe])

  const countTriangles = (model) => {
    let triangles = 0
    if (model) {
      for (var i = 0, l = model.children.length; i < l; i++) {
        var object = model.children[i]
        // eslint-disable-next-line no-loop-func
        object.traverse((object) => {
          if (object.isMesh) {
            let geometry = object.geometry
            if (geometry.isGeometry) {
              triangles += geometry.faces.length
            } else if (geometry.isBufferGeometry) {
              if (geometry.index !== null) {
                triangles += geometry.index.count / 3
              } else {
                triangles += geometry.attributes.position.count / 3
              }
            }
          }
        })
      }
      setTri(triangles)
    }
  }

  const setModel = () => {
    // this.loaded = false

    const urls = asset.getFileTable(isDrag)
    const ModelViewerElement = customElements.get('model-viewer')
    var accessToken = localStorage.getItem('access_token')
    var idToken = localStorage.getItem('id_token')
    ModelViewerElement.setRequestHeaders({
      Authorization: accessToken,
      'x-api-key': apiConfig.apiKey,
      'x-id-token': idToken,
    })

    if (urls.gltf) {
      ModelViewerElement.mapURLs((url = '') => {
        // if URL is valid, continue
        if (
          Object.entries(urls).find(([, path]) => {
            if (Array.isArray(path)) {
              return path.find((p) => url === p)
            } else {
              return url === path
            }
          })
        ) {
          return url
        }

        // if URL has matching key from url/file-table
        const entry = Object.entries(urls).find(([key]) => url.includes(key))
        if (!entry) return Promise.reject(`Invalid GLTF Inject Param: ${url}`)
        if (Array.isArray(entry[1])) {
          if (!asset.siblings) {
            return Promise.reject(`Invalid GLTF siblings files: ${url}`)
          }
          //find the element which has the same name as of
          let name = url.split('/').slice(-1)[0] // Get the name of the URl to match in list of siblings
          let baseUrl = !isDrag
            ? `${url.split('/').slice(0, -2).join('/')}`
            : ''
          let mappedUrl = asset.siblings.find(
            (file) => file.name === name
          ).objectUrl
          return `${baseUrl}${mappedUrl}`
        } else {
          return entry[1]
        }
      })
    }
    const { gltf: gltfUrl = '', glb: glburl = '' } = urls
    const modelUrl = gltfUrl !== '' ? gltfUrl : glburl !== '' ? glburl : ''
    setAssetLoaded(false)
    // const newUrl =
    //   'https://stage-api.target.com/cgi_assets/v1/cgisandbox/renditions/14953533/VED5uvyIV+GpcqLqyD2NPhLYa+YtTSntJWTU6R8OKiE='
    setModelUrl(modelUrl)
  }

  const anchorStyle = {
    position: 'absolute',
    color: '#666',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    pointerWvents: 'none',
    top: '1rem',
    left: '-2rem',
  }

  const select = (target, event, id, autoFocus = true) => {
    let hotspot = target || event.target
    const viewerElement = viewer.current
    setSelectedHotspot(id)

    for (let i = 0; i < viewerElement.children.length; i++) {
      const element = viewerElement.children[i]
      if (element.id !== 'new') {
        element.classList.remove('selected')
      }
      if (!hotspot && element.slot === 'hotspot-' + id) {
        hotspot = element
      }
    }
    if (hotspot) {
      hotspot.classList.add('selected')
      if (autoFocus) {
        viewerElement.cameraOrbit = '0deg 75deg 105%'
        viewerElement.cameraTarget = 'auto auto auto'
        viewerElement.dispatchEvent(
          new CustomEvent('camera-change', {
            detail: { source: 'user-interaction' },
          })
        )
        viewerElement.cameraOrbit = hotspot.dataset.cameraOrbit
        viewerElement.cameraTarget = hotspot.dataset.cameraTarget

        viewerElement.dispatchEvent(
          new CustomEvent('camera-change', {
            detail: { source: 'user-interaction' },
          })
        )
      }
    }
    if (!id) {
      selectComment(id)
    }
    //if (!id) this.$emit('on-hotspot-selected', hotspot)
  }

  React.useEffect(() => {
    annotationModeRef.current = annotationMode
    setShowAnnotations(annotationMode)
    enableClickEvent(false)
    setTimeout(() => {
      if (annotationModeRef.current === true) {
        enableClickEvent(true)
      }
    }, 1000)
    // eslint-disable-next-line
  }, [annotationMode])

  const createAnnotation = (event) => {
    if (
      event.target.tagName === 'BUTTON' ||
      annotationModeRef.current === false
    ) {
      console.log('click returned')
      return
    }

    const viewerElement = viewer.current
    const rect = viewerElement.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    const positionAndNormal = viewerElement.positionAndNormalFromPoint(x, y)
    if (positionAndNormal == null) {
      console.log('no hit result: mouse = ', x, ', ', y)
      return
    }
    const { position, normal } = positionAndNormal
    const hotspot = document.createElement('button')
    const hotspotCount = hotspotCounter + 1
    setHotspotCounter(hotspotCount)
    hotspot.slot = `hotspot-${hotspotCount}`
    hotspot.classList.add('hotspot')
    hotspot.dataset.position = position.toString()
    if (normal != null) {
      hotspot.dataset.normal = normal.toString()
    }
    hotspot.id = 'new'
    hotspot.dataset.id = 'new'
    hotspot.dataset.cameraOrbit = viewerElement.getCameraOrbit().toString()
    hotspot.dataset.cameraTarget = viewerElement.getCameraTarget().toString()
    viewerElement.appendChild(hotspot)
    select(hotspot, undefined, hotspotCount, false)
    hotspot.addEventListener('click', (e) => {
      select(hotspot, e, hotspotCount)
    })
    hotspot.addEventListener('mouseover', (e) => {
      selectComment('new')
    })
    hotspot.addEventListener('mouseleave', (e) => {
      selectComment(null)
    })
    //this.$emit('on-hotspot-created', hotspot)
    annotationModeRef.current = false

    enableClickEvent(false)
    onNewHotspot(hotspot)
  }

  const enableClickEvent = (value) => {
    const viewerElement = viewer.current

    if (value === true) {
      viewerElement.shadowRoot
        .querySelector('.userInput')
        .classList.add('crosshaircursor')
    } else {
      onNewHotspot(undefined)
      //viewer.current.removeEventListener('click', createAnnotation)

      //
      viewerElement.shadowRoot
        .querySelector('.userInput')
        .classList.remove('crosshaircursor')
    }
  }

  React.useEffect(() => {
    select(undefined, {}, activeHotspot, autoFocus)
    // eslint-disable-next-line
  }, [activeHotspot, autoFocus])
  React.useEffect(() => {
    select(undefined, {}, activeComment, false)
    // eslint-disable-next-line
  }, [activeComment])

  React.useEffect(() => {
    if (assetLoaded && removeHotspot !== undefined) {
      viewer.current.removeChild(removeHotspot)
      onNewHotspot(undefined)
      enableClickEvent(true)
      annotationModeRef.current = true
      setShowAnnotations(false)
      setTimeout(() => {
        setShowAnnotations(true)
      }, 200)
    }
    // eslint-disable-next-line
  }, [removeHotspot])
  return (
    <>
      <div
        onContextMenu={(e) => e.preventDefault()}
        style={{ position: 'relative' }}
      >
        <model-viewer
          style={{
            width: '100%',
            height: 'Calc(100vh - 150px)',
            margin: '0px auto',
            border: ' 1px solid #ccc',
          }}
          alt={asset.model.name}
          src={modelUrl}
          shadow-intensity={1}
          shadow-softness={0.3}
          environment-image={light === true ? 'neutral' : ''}
          exposure="0.8"
          camera-controls
          ref={viewer}
        >
          {showAnnotaions
            ? comments.map((comment) => {
                return comment.annotation_data !== null ? (
                  <button
                    slot={'hotspot-' + comment.id}
                    className={
                      comment.id === selectedHotspot
                        ? 'hotspot selected'
                        : 'hotspot'
                    }
                    data-id={comment.id}
                    onMouseOver={() => {
                      selectComment(comment.id)
                    }}
                    onMouseLeave={() => {
                      selectComment(null)
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      select(undefined, e, comment.id)
                    }}
                    data-position={comment.annotation_data.position}
                    data-normal={comment.annotation_data.normal}
                    data-camera-orbit={comment.annotation_data.camera_orbit}
                    data-camera-target={comment.annotation_data.camera_target}
                  ></button>
                ) : (
                  ''
                )
              })
            : ''}
          {/* <button v-for="comment in this.annotations" :key="comment.id" 
       @click.stop.prevent="select(undefined, $event)"
       @mouseover="highlight($event, true)"
       @mouseleave="highlight(undefined, false)"
       :slot="'hotspot-'+comment.id"
       :class="annotationMode ? 'hotspot' : 'hotspot hide'"
       :data-id="comment.id"
       :data-position="comment.annotation_data.position"
       :data-normal="comment.annotation_data.normal"
       :data-camera-orbit="comment.annotation_data.camera_orbit" 
       :data-camera-target="comment.annotation_data.camera_target">
      </button> */}
        </model-viewer>

        {dimensions && assetLoaded ? (
          <>
            <div style={anchorStyle} id="dimensionX"></div>
            <div style={anchorStyle} id="dimensionY"></div>
            <div style={anchorStyle} id="dimensionZ"></div>
          </>
        ) : null}
        {showpivot && assetLoaded ? (
          <>
            <div style={anchorStyle} id="pivotX">
              X
            </div>
            <div style={anchorStyle} id="pivotY">
              Y
            </div>
            <div style={anchorStyle} id="pivotZ">
              Z
            </div>
          </>
        ) : null}
        <div style={{ position: 'absolute', bottom: 10, left: 20 }}>
          {tri} triangles
        </div>
      </div>
    </>
  )
}

export default GltfViewer
