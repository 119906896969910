import {
  apiConfig,
  getEnvUrl,
  getLoadableImages,
  getLoadableMisc,
  getLoadableModels,
  getLoadableTextures,
} from './config'

const environment = {
  key: apiConfig?.apiKey,
  // assets: 'https://stage-api.target.com/cgi_assets/v1/cgisandbox/renditions', //convert this to config env for ext gga url
  assets: apiConfig?.threeDViewer?.renditions,
}

const validFormats = getLoadableModels()
const validImages = getLoadableImages()
const validMisc = getLoadableMisc()
const validTexture = getLoadableTextures()

/*
  Every Model / Sibling file needs the following:
    name,
    objectUrl,
    relativePath
*/

export default class Asset {
  static GetAssetObject(model) {
    return { model, siblings: [] }
  }

  static ParseFileTable(fileTable) {
    let assets = Asset.SearchFiles(fileTable).map(
      ({ model, siblings }) => new Asset(model, siblings)
    )
    return Asset.SortByFormat(assets)
  }

  static SortByFormat(assets) {
    let orderedAssets = []
    validFormats.forEach((format) => {
      let asset = Asset.getAssetByFormat(assets, format)
      if (asset !== undefined) orderedAssets.push(...asset)
    })
    return orderedAssets
  }

  static getAssetByFormat(assets, format) {
    return assets.filter((a) => a.model.name.toLowerCase().includes(format))
  }

  static ParseUrlTable(urlTable) {
    let assets = Asset.SearchStrings(urlTable).map(
      ({ model, siblings }) => new Asset(model, siblings)
    )
    return Asset.SortByFormat(assets)
  }

  static SearchFiles(obj, _results = []) {
    const assets = []
    const siblings = []
    Object.entries(obj).map(([, entry]) => {
      if (!(entry instanceof File)) {
        Asset.SearchFiles(entry, _results)
      } else if (
        validFormats.some((f) => entry.name.toLowerCase().includes(f))
      ) {
        assets.push(Asset.GetAssetObject(entry))
      } else {
        siblings.push(entry)
      }
      return ''
    })
    assets.forEach((asset) => asset.siblings.push(...siblings))
    if (assets.length > 0) {
      _results.push(...assets)
    }
    return _results
  }

  static SearchStrings(obj, path = '', _results = []) {
    const assets = []
    const siblings = []
    Object.entries(obj).map(([key, entry]) => {
      if (typeof entry !== 'string') {
        Asset.SearchStrings(entry, `${path}/${key}`, _results)
      } else if (validFormats.some((f) => key.toLowerCase().includes(f))) {
        entry = entry + '?key=' + environment.key
        assets.push(
          Asset.GetAssetObject({
            name: key,
            objectUrl: entry,
            relativePath: path,
          })
        )
      } else {
        siblings.push({ name: key, objectUrl: entry })
      }
      return ''
    })
    assets.forEach((asset) => asset.siblings.push(...siblings))
    if (assets.length > 0) _results.push(...assets)
    return _results
  }

  constructor(model, siblings = []) {
    this.model = model
    this.siblings = siblings
    ;[this.model, ...this.siblings].forEach((asset) => {
      if (!asset.objectUrl) asset.objectUrl = URL.createObjectURL(asset)
    })
  }

  getExt(str, toLowerCase = true) {
    let temp = (toLowerCase ? str.toLowerCase() : str).split('.')
    return temp[temp.length - 1]
  }

  getFileTable(isDrag) {
    const modelExt = this.getExt(this.model.name)
    // get the format type
    const table = {
      [modelExt]: (!isDrag ? environment.assets : '') + this.model.objectUrl,
    }
    // if obj, get valid texture and misc based on format and type
    if (modelExt === 'gltf' || modelExt === 'obj') {
      // textures
      this.siblings
        .filter(
          (file) =>
            validImages.some((f) => file.name.toLowerCase().includes(f)) &&
            validTexture.some((f) => file.name.toLowerCase().includes(f))
        )
        .forEach((file) => {
          let type = this.getType(file.name, validTexture)
          // const formedURL =
          //   (!isDrag ? environment.assets : '') +
          //   file.objectUrl +
          //   (!isDrag ? '?key=' + environment.key : '')
          const formedURL = (!isDrag ? environment.assets : '') + file.objectUrl
          if (modelExt === 'gltf') {
            table[type] = table[type] || []
            table[type].push(formedURL)
          } else {
            table[type] = formedURL // In case of OBJ
          }
        })
      // misc
      this.siblings
        .filter((file) =>
          validMisc.some((f) => file.name.toLowerCase().includes(f))
        )
        .forEach((file) => {
          let type = this.getType(file.name, validMisc)
          const formedURL = (!isDrag ? environment.assets : '') + file.objectUrl
          if (modelExt === 'gltf') {
            table[type] = table[type] || []
            table[type].push(formedURL)
          } else {
            table[type] = formedURL //In case of Obj
          }
        })
    }

    // set default env map if not included
    if (!table.env) table.env = getEnvUrl()
    return table
  }

  getType(str, arr) {
    return arr.find((type) => str.includes(type))
  }
}
