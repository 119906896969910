import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/tgt/authorize/we2w/1',
    logoutPath: '/login/responses/logoff.html',
    popUpOptions: { width: 482, height: 680 },
    loginRedirect: `${window.location.origin}`, // Not sure loginRedirect should be used as new auth
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
}

const newBaseURLQA = 'https://stage-api.target.com/cgi_assets/v1/'
const newBaseURLProd = 'https://api.target.com/cgi_assets/v1/'
export const ACCESS_DENIED_URL =
  'https://logonservices.iam.target.com/login/responses/accessdenied.html'

const envConfigs = {
  dev: {
    auth: {
      host: 'https://oauth.iam.perf.partnersonline.com',
      logoutHost: 'https://oauth.iam.perf.partnersonline.com',
      clientId: 'cgivendor_pipeline_npe_im',
    },
    auth_tplus: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://oauth.iam.perf.target.com',
      appUrl: 'extgargantua.perf.target.com',
      authorizationPath: '/auth/oauth/v2/tgt/authorize/pol/1',
      // appUrl: 'localhost',
      tplusUrl: 'https://plus-portal.perf.target.com/',
    },
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    task: {
      taskListing: newBaseURLQA + `search_tasks`,
      downloadUrl: 'https://stage-api.target.com/cgi_uploads/v1/assets/details',
      fileDownload: 'https://stage-api.target.com/cgi_uploads/v1/assets',
      updateTask: newBaseURLQA + 'tasks',
      taskTcinDetails: newBaseURLQA + 'task_details/',
      addComment: newBaseURLQA + 'task_comments',
      getAllMembers: newBaseURLQA + 'users_type_ahead',
      uploadApi: 'https://stage-api.target.com/cgi_uploads/v1',
      downloadReport: 'https://stage-api.target.com/cgi_assets/v1/',
    },
    storage: {
      fileListing:
        'https://stage-api.target.com/cgi_assets/v1/uploadFile/listFiles',
      fileDownload:
        'https://stage-api.target.com/cgi_assets/v1/uploadFile/download/',
      uploadApi:
        'https://stage-api.target.com/cgi_assets/v1/uploadFile/chunk_uploads',
    },
    pipeline: {
      listVendorTcins:
        'https://stage-api.target.com/cgi_assets/v1/vendor_tcins',
      tcinDetail:
        'https://stage-api.target.com/cgi_assets/v1/vendor_tcin_details/',
      commentList:
        'https://stage-api.target.com/cgi_assets/v1/vendor_tcins/comments/',
      addComment: newBaseURLQA + 'task_comments',
      uploadApi: 'https://stage-api.target.com',
      getUploadedFilesData:
        'https://stage-api.target.com/cgi_assets/v1/vendor_group_assets/full/',
      fileDelete:
        'https://stage-api.target.com/cgi_assets/v1/cgi/delete_asset/',
    },
    nationalBrand: {
      vendorInfo: newBaseURLQA + 'get_vendor_info',
      createProject: newBaseURLQA + 'create_project',
      projectList: newBaseURLQA + 'get_national_brand_project',
      addTcin: newBaseURLQA + 'add_tcins',
      tcinList: newBaseURLQA + 'search_tcins',
      processFile: newBaseURLQA + 'bulk_upload/process',
      fetchComments: newBaseURLQA + 'comments/',
      addComment: newBaseURLQA + 'comments/add_comment',
      downloadFile: newBaseURLQA + 'project_tcin_details/',
      uploadHistory: 'https://stage-api.target.com/cgi_uploads/v1/',
      validateTcins: newBaseURLQA + 'validate_tcins',
      contract: {
        get: newBaseURLQA + 'user_contract',
        save: newBaseURLQA + 'user_contract',
      },
      dashBoard: newBaseURLQA + 'fetch_external_vendor_dashboard',
      fetchProjectDetails: newBaseURLQA + 'project_data',
    },
  },

  prod: {
    auth: {
      host: 'https://oauth.iam.partnersonline.com',
      logoutHost: 'https://oauth.iam.partnersonline.com',
      clientId: 'cgivendor_pipeline_prod_im',
    },
    auth_tplus: {
      host: 'https://oauth.iam.target.com',
      logoutHost: 'https://oauth.perf.target.com',
      appUrl: 'extgargantua.target.com',
      authorizationPath: '/auth/oauth/v2/tgt/authorize/pol/1',
      tplusUrl: 'https://plus-portal.target.com',
    },
    apiKey: '07323bd67d9a9a38c555a5ab00f68514b3d020b8', // Prod key might need change
    task: {
      taskListing: newBaseURLProd + `search_tasks`,
      downloadUrl: 'https://api.target.com/cgi_uploads/v1/assets/details',
      fileDownload: 'https://api.target.com/cgi_uploads/v1/assets',
      updateTask: newBaseURLProd + 'tasks',
      taskTcinDetails: newBaseURLProd + 'task_details/',
      addComment: newBaseURLProd + 'task_comments',
      getAllMembers: newBaseURLProd + 'users_type_ahead',
      uploadApi: 'https://api.target.com/cgi_uploads/v1',
      downloadReport: 'https://api.target.com/cgi_assets/v1/',
    },
    pipeline: {
      listVendorTcins: 'https://api.target.com/cgi_assets/v1/vendor_tcins',
      tcinDetail: 'https://api.target.com/cgi_assets/v1/vendor_tcin_details/',
      commentList:
        'https://api.target.com/cgi_assets/v1/vendor_tcins/comments/',
      addComment: newBaseURLQA + 'task_comments',
      uploadApi: 'https://api.target.com',
      getUploadedFilesData:
        'https://api.target.com/cgi_assets/v1/vendor_group_assets/full/',
      fileDelete: 'https://api.target.com/cgi_assets/v1/cgi/delete_asset/',
    },
    nationalBrand: {
      vendorInfo: newBaseURLProd + 'get_vendor_info',
      createProject: newBaseURLProd + 'create_project',
      projectList: newBaseURLProd + 'get_national_brand_project',
      addTcin: newBaseURLProd + 'add_tcins',
      tcinList: newBaseURLProd + 'search_tcins',
      processFile: newBaseURLProd + 'bulk_upload/process',
      fetchComments: newBaseURLProd + 'comments/',
      addComment: newBaseURLProd + 'comments/add_comment',
      downloadFile: newBaseURLProd + 'project_tcin_details/',
      uploadHistory: 'https://api.target.com/cgi_uploads/v1/',
      validateTcins: newBaseURLProd + 'validate_tcins',
      contract: {
        get: newBaseURLProd + 'user_contract',
        save: newBaseURLProd + 'user_contract',
      },
      dashBoard: newBaseURLProd + 'fetch_external_vendor_dashboard',
      fetchProjectDetails: newBaseURLProd + 'project_data',
    },
  },
}

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

const apiConfig = merge(commonConfig, config)

export default apiConfig
