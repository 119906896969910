import { useRef, useEffect } from 'react'
import CommentListItem from './CommentItem'

const CommentList = ({ data = [], activeComment, ...rest }) => {
  const refArray = useRef({})
  useEffect(() => {
    const element = refArray.current[activeComment]
    if (element) {
      element.scrollIntoView()
    }
  }, [activeComment])
  return (
    <div>
      {data.map((item, i) => {
        return (
          <div
            ref={(ref) => {
              refArray.current[item.id] = ref // took this from your guide's example.
            }}
            key={i}
          >
            <CommentListItem
              data={item}
              activeComment={activeComment}
              {...rest}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CommentList
