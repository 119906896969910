import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  PROMPT_SIGN_IN,
  DECLINE_SIGN_IN,
  ADD_PERMISSON,
} from './AuthActionType'

import { formatUserInfo } from './AuthContainer'

export const initialState = {
  auth: {},
  isAuthorized: false,
  popupCount: 0,
  popupType: null,
  authModalIsShown: false,
}

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        popupType: 'login',
        popupCount: state.popupCount + 1,
      }

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        ...formatUserInfo(action.payload),
        isAuthorized: true,
        authModalIsShown: false,
      }

    case SIGN_OUT:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        popupType: 'logout',
        popupCount: state.popupCount + 1,
      }

    case PROMPT_SIGN_IN:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        authModalIsShown: true,
      }

    case DECLINE_SIGN_IN:
      return {
        ...state,
        ...formatUserInfo(),
        isAuthorized: false,
        authModalIsShown: false,
      }

    case ADD_PERMISSON:
      return {
        ...state,
        permission: action.payload.permission,
      }

    default:
      return state
  }
}
