import React from 'react'
import { Fab, CircularProgress, Tooltip } from '@material-ui/core'
import ThreeDRotationOutlinedIcon from '@material-ui/icons/ThreeDRotationOutlined'
import styled from 'styled-components'

const NormalFab = styled(Fab)`
  background-color: #fff;
`

const ViewButton = ({
  onClick = () => {},
  disableBtn = false,
  color = 'gray',
  progress = 0,
  loading = false,
}) => {
  const getButton = (buttonElement) => {
    return (
      <React.Fragment>
        {buttonElement}
        {loading ? (
          <CircularProgress />
        ) : (
          <CircularProgress
            style={{ color: color }}
            variant="static"
            value={progress}
          />
        )}
      </React.Fragment>
    )
  }
  return (
    <div>
      <Tooltip title={'View in 3D'}>
        <div>
          <NormalFab
            style={{ color: color }}
            size="small"
            disabled={disableBtn}
            onClick={onClick}
          >
            {getButton(
              <ThreeDRotationOutlinedIcon style={{ position: 'absolute' }} />
            )}
          </NormalFab>
        </div>
      </Tooltip>
    </div>
  )
}

export default ViewButton
