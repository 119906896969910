import { useEffect, useState } from 'react'
import { Link, Button, IconButton } from '@material-ui/core'
import ReplyIcon from '@material-ui/icons/Reply'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import Axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { showNotification } from '../../../app/Notifications/NotificationActionCreator'

import CommentInput from './CommentInput'
import { apiConfig } from '../Lib/config'

const wapperStyles = makeStyles((theme) => ({
  commentWrapper: {
    border: '1px solid #ccc',
    marginTop: 15,
    padding: '0 10px',
  },
  replyWrapper: { padding: '5px 10px' },
  replybutton: { fontSize: 12, textTransform: 'none', margin: '5px 0' },
  chlidrenComments: {
    paddingLeft: 25,
  },
  showAllReplies: {
    fontSize: 14,
    marginTop: 5,
    display: 'block',
    textTransform: 'uppercase',
  },
  activeComment: { border: '2px solid #ac0000' },
}))

const commentStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0 0',
    color: '#666',
    fontSize: 12,
    alignItems: 'center',
    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  description: { color: '#333', fontSize: 16, paddingBottom: 0 },
  editComment: {
    padding: '5px 0',
  },
  editButton: {
    fontSize: 12,
    visibility: 'hidden',
  },
}))

const getAttachments = (attachments) => {
  if (attachments !== null) {
    return Object.keys(attachments).map((item) => {
      return {
        link: apiConfig.threeDViewer.downloadCommentAttachment + item,
        name: item.split('/').pop(),
      }
    })
  } else {
    return []
  }
}

const downloadBlob = (name, blob) => {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = name
  a.click()
}

const formatDate = (date) =>
  new Date(date)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, ' ')

const CommentDetailComponent = ({
  data: {
    created_date = new Date(),
    id = '',
    text = '',
    email = '',
    attachments = null,
    is_edited = false,
  },
  tcin = '',
  parentId,
  onNewComment,
  showNotification,
  auth: { email: userEmail },
  ...rest
}) => {
  const [edit, setEdit] = useState(false)

  const classes = commentStyles()
  const attachmentList = getAttachments(attachments)

  const downloadAttachment = (file) => (e) => {
    e.preventDefault()
    showNotification(true, 'File download Initiated', 'success')

    Axios.get(file.link, { responseType: 'blob' })
      .then((response) => {
        return response.status === 200 ? new Blob([response.data]) : ''
      })
      .then((blob) => {
        if (blob !== '') {
          downloadBlob(file.name, blob)
          showNotification(true, 'File Download Complete', 'success')
        } else {
          //error notification
          showNotification(true, 'Unable to Download File', 'error')
        }
      })
  }

  return (
    <div>
      {edit ? (
        <div className={classes.editComment}>
          <CommentInput
            onNewComment={onNewComment}
            tcin={tcin}
            onCancel={() => {
              setEdit(false)
            }}
            attachmentList={attachmentList}
            isEdit
            id={id}
            parentId={parentId}
            text={text}
          />
        </div>
      ) : (
        <>
          <div className={classes.header}>
            <span>
              {email} | {formatDate(created_date)}
              {is_edited ? ' | (Edited)' : null}
            </span>
            {userEmail === email ? (
              <IconButton
                className={classes.editButton}
                onClick={() => {
                  setEdit(true)
                }}
                size={'small'}
                aria-label="edit"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            ) : null}
          </div>
          <div className={classes.description}>{text}</div>

          {attachmentList.map((item) => {
            return (
              <div>
                <Link
                  href="#"
                  onClick={downloadAttachment(item)}
                  variant="body2"
                >
                  {item.name}
                </Link>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

const CommentDetail = connect((state) => state, { showNotification })(
  CommentDetailComponent
)

const CommentListItem = ({
  data,
  tcin,
  onNewComment,
  annotationMode,
  activeComment: selectedComment,
  selectHotspot,
  ...rest
}) => {
  const classes = wapperStyles()
  const childrenComments =
    data.reply_comments !== null ? data.reply_comments : []
  const [reply, setReply] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [activeComment, setActiveComment] = useState(false)
  const displayedChildcommments = showAll
    ? childrenComments
    : childrenComments.slice(-2)
  const selectHotspotCB = (id, focus) => {
    if (annotationMode && data.annotation_data != null) {
      if (id) {
        selectHotspot(id, focus)
        setActiveComment(true)
        // selectComment(id, focus)
      } else {
        selectHotspot(undefined, focus)
        setActiveComment(false)
        // selectComment(id, focus)
      }
    }
  }

  useEffect(() => {
    setActiveComment(selectedComment === data.id)
  }, [selectedComment, data.id])
  const wrapperClass = activeComment
    ? `${classes.commentWrapper} ${classes.activeComment}`
    : classes.commentWrapper
  return (
    <div
      className={wrapperClass}
      onMouseEnter={() => {
        selectHotspotCB(data.id, false)
      }}
      onMouseLeave={() => {
        selectHotspotCB(null, false)
      }}
      onClick={() => {
        selectHotspotCB(data.id, true)
      }}
    >
      <CommentDetail tcin={tcin} onNewComment={onNewComment} data={data} />
      <div className={classes.chlidrenComments}>
        {childrenComments.length > 2 ? (
          <Link
            onClick={() => {
              setShowAll(!showAll)
            }}
            href="#"
            size="small"
            color="secondary"
            className={classes.showAllReplies}
          >
            {showAll
              ? 'Hide Replies'
              : `${childrenComments.length - 2} More replies`}
          </Link>
        ) : (
          ''
        )}
        {displayedChildcommments.map((item) => {
          return (
            <CommentDetail
              onNewComment={onNewComment}
              tcin={tcin}
              parentId={data.id}
              data={item}
            />
          )
        })}
      </div>

      {reply ? (
        <div className={classes.replyWrapper}>
          <CommentInput
            onNewComment={onNewComment}
            tcin={tcin}
            parentId={data.id}
            isReply
            onCancel={() => {
              setReply(false)
            }}
          />
        </div>
      ) : (
        <Button
          onClick={() => {
            setReply(true)
          }}
          size="small"
          color="secondary"
          className={classes.replybutton}
          startIcon={<ReplyIcon fontSize="small" />}
        >
          Leave a reply
        </Button>
      )}
    </div>
  )
}

export default CommentListItem
