/*
export const getEnvUrl = () =>
  'https://firebasestorage.googleapis.com/v0/b/cgisandbox.appspot.com/o/env.jpg?alt=media&token=5cd908e0-4d3c-4487-b57e-0cf1167e9299'
*/

export const getEnvUrl = () => ''

export const getExperiences = () => [
  'android',
  'ios',
  'ios_quicklook',
  'unity',
  'see_it_in_your_space',
  'spinner',
]

export const getLoadableImages = () => ['.jpg', '.png']

export const getLoadableMisc = () => ['.bin']

export const getLoadableModels = () => ['.glb', '.gltf', '.fbx']

export const getLoadableTextures = () => [
  'alpha',
  'ao',
  'arm',
  'diffuse',
  'emissive',
  'env',
  'height',
  'metalness',
  'normal',
  'roughness',
]

export const getSorts = () => [
  'assignment',
  'availability',
  'tcin',
  'rework',
  'status',
  'street',
  'type',
]

export const getStatuses = () => [
  'Missing Assets',
  'To Do',
  'Processing',
  'Pipeline',
  'QA',
  'QA Approved',
  'Live',
  'Processing Error',
  'Pipeline Error',
  'Preapproved',
  'Rework',
  'Total',
]

const newBaseURLQA = 'https://stage-api.target.com/cgi_assets/v1/'
const newBaseURLProd = 'https://api.target.com/cgi_assets/v1/'

const configs = {
  dev: {
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    threeDViewer: {
      assets: newBaseURLQA + 'assets',
      renditions: newBaseURLQA + 'cgisandbox/renditions',
      getComments: newBaseURLQA + 'assets/comments',
      saveComments: (assetId) => `${newBaseURLQA}assets/${assetId}/comments`,
      attachComments: (assetId) =>
        `${newBaseURLQA}assets/${assetId}/comment/attachment`,
      downloadCommentAttachment: `${newBaseURLQA}cgisandbox/comment/attachments/`,
      deleteComment: (assetId, commentID, fileName) =>
        `${newBaseURLQA}${assetId}/${commentID}/${fileName}/`,
      downloadFiles: `${newBaseURLQA}cgisandbox/downloads`,
      reviewTask: `${newBaseURLQA}review_task`,
    },
  },
  prod: {
    apiKey: '07323bd67d9a9a38c555a5ab00f68514b3d020b8', // Prod key might need change
    threeDViewer: {
      assets: newBaseURLProd + 'assets',
      renditions: newBaseURLProd + 'cgisandbox/renditions',
      getComments: newBaseURLProd + 'assets/comments',
      saveComments: (assetId) => `${newBaseURLProd}assets/${assetId}/comments`,
      attachComments: (assetId) =>
        `${newBaseURLProd}assets/${assetId}/comment/attachment`,
      downloadCommentAttachment: `${newBaseURLProd}comment/attachments/`,
      deleteComment: (assetId, commentID, fileName) =>
        `${newBaseURLProd}${assetId}/${commentID}/${fileName}/`,
      downloadFiles: `${newBaseURLProd}cgisandbox/downloads`,
      reviewTask: `${newBaseURLProd}review_task`,
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
export const apiConfig = configs[appEnv]
