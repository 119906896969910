import {
  NATIONAL_BRAND,
  FETCH_PROJECT,
  FETCH_COMMENTS,
  FETCH_TCIN_LIST,
  FETCH_TCIN_LIST_ERROR,
  UPLOAD_TCIN_ADD,
  RESET_STATE,
  UPDATE_TCIN_STATUS,
  INITIATE_FETCH_TCIN_LIST,
  DOWNLOAD_FILE_ASSETS,
  VERIFICATION_TCIN_RESPONSE,
  VERIFICATION_TCIN_FAILURE,
  NBR_DASHBOARD_FETCH,
  FECTH_PROJECT_DETAILS,
} from './ActionType'
import axios from 'axios/index'
import apiConfig from '../../../config/apiConfig'
import { showNotification } from '../../Notifications/NotificationActionCreator'
import { isTPlus } from '../../Auth/Auth'
// const axiosCancelToken = axios.CancelToken

const isTargetVendor = `is_target_plus_vendor=${isTPlus}`
const isTargetVendorObj = { is_target_plus_vendor: isTPlus }

//  vendorInfo -- to get national brand vendor Info
export const vendorInfo = (email) => {
  return function (dispatch) {
    axios
      .get(
        `${apiConfig.nationalBrand.vendorInfo}?email_id=${email}&${isTargetVendor}`
      )
      .then((res) => {
        let nbrVendorAPIStatus = false
        if (res.data.vendor_id) {
          nbrVendorAPIStatus = true
        }
        dispatch({
          type: NATIONAL_BRAND,
          payload: { ...res.data, nbrVendorAPIStatus },
        })
      })
      .catch((err) => {
        dispatch({
          type: NATIONAL_BRAND,
          payload: { nbrVendorAPIStatus: false, message: 'error in login' },
        })
        console.log(err)
      })
  }
}

// Fetch  Project ---- to fetch project from API in National Brand case
export const fetchProject = (payload) => async (dispatch) => {
  dispatch({ type: 'FETCH_PROJECT_INITIAL' })
  let { count, page, projectType, sortBy, sortOrder, vendorId, status } =
    payload
  let params = `project_type=${projectType}&page=${page}&count=${count}&sort_by=${sortBy}&sort_order=${sortOrder}&status=${status}`
  const urlWithParam = `${apiConfig.nationalBrand.projectList}?${params}`
  let response = await axios.post(urlWithParam, {
    vendor_id: vendorId,
    ...isTargetVendorObj,
  })
  if (response.status === 200) {
    dispatch({
      type: FETCH_PROJECT,
      payload: response.data,
      currentTab: status,
    })
  } else {
    dispatch(
      showNotification(
        true,
        'Something went wrong while getting projects',
        'error'
      )
    )
    dispatch({ type: FETCH_PROJECT, payload: 'error' })
  }
}

// Create Project  ---- To create project for national brand
export const createProject = (projectName, lanId, callbackSuccess) => {
  return function (dispatch) {
    let payload = {
      // to trim spaces
      project_name: projectName.replace(/\s/g, ''),
      ext_project_initiator_id: lanId,
      project_type: 'shoppable',
      ...isTargetVendorObj,
    }
    axios
      .post(apiConfig.nationalBrand.createProject, payload)
      .then((res) => {
        let payload = {
          count: 15,
          page: 1,
          projectType: 'Non Tcin',
          sortBy: 'project_create_date',
          sortOrder: 'desc',
          vendorId: lanId,
        }
        dispatch(fetchProject(payload))
        dispatch(
          showNotification(
            true,
            `Project Created ${res.data.project_name} Successfully`,
            'success'
          )
        )
        callbackSuccess()
      })
      .catch((err) => {
        if (
          err.response.data &&
          err.response.data.message.includes('exists having name')
        ) {
          dispatch(showNotification(true, err.response.data.message, 'error'))
        } else {
          dispatch(showNotification(true, `Something went wrong`, 'error'))
        }
      })
  }
}

// Fetch comments ----- to get user stored comments
export const fetchComments = (tcinId, projectId) => {
  return function (dispatch) {
    var commentListUrl =
      apiConfig.nationalBrand.fetchComments +
      tcinId +
      '/' +
      projectId +
      `?${isTargetVendor}`
    axios
      .get(commentListUrl)
      .then((res) => {
        if (res.data.length === 0) {
          dispatch({ type: FETCH_COMMENTS, payload: ['empty'] })
        } else {
          dispatch({ type: FETCH_COMMENTS, payload: res.data })
        }
      })
      .catch((err) => {
        dispatch(
          showNotification(
            true,
            'Something went wrong while getting comments',
            'error'
          )
        )
        dispatch({ type: FETCH_COMMENTS, payload: ['error'] })
      })
  }
}

// Post Comments ----- Post comments by user then hit API
export const PostComments = (Comment, tcinId, projectId) => {
  return function (dispatch) {
    var cgiAddCommentUrl = `${apiConfig.nationalBrand.addComment}?key=${apiConfig.apiKey}`
    let payload = {
      tcin: tcinId,
      comments: Comment,
      project_type: 'CGI TCIN Cycle',
      tcin_phase: 'VENDOR_INPUT',
      comment_reference_ids: [],
      project_id: projectId,
      ...isTargetVendorObj,
    }
    axios
      .post(cgiAddCommentUrl, payload)
      .then((res) => {
        dispatch(fetchComments(tcinId, projectId))
        dispatch(
          showNotification(true, `Comment Store Successfully !!`, 'success')
        )
      })
      .catch((err) => {
        dispatch(showNotification(true, 'Something went wrong', 'error'))
      })
  }
}

// Upload Tcin ---
export const uploadAddTcin = (
  project_uuid,
  created_by,
  vendor_id,
  tcin_list
  // success
) => {
  return function (dispatch) {
    const url = apiConfig.nationalBrand.addTcin
    let payload = {
      project_uuid: project_uuid,
      created_by: created_by,
      vendor_id: vendor_id,
      tcin_list: tcin_list,
      ...isTargetVendorObj,
    }
    axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((rs) => {
        dispatch({ type: UPLOAD_TCIN_ADD, payload: rs.data })
        // success(rs)
      })
      .catch((err) => {
        dispatch({ type: UPLOAD_TCIN_ADD, payload: ['error'] })
      })
  }
}

export const fetchTcins = (postData, addData) => {
  return function (dispatch) {
    dispatch({ type: INITIATE_FETCH_TCIN_LIST })
    axios
      .post(apiConfig?.nationalBrand?.tcinList, {
        ...postData,
        ...isTargetVendorObj,
      })
      .then((res) => {
        dispatch({ type: FETCH_TCIN_LIST, payload: res.data, addData })
      })
      .catch((err) => {
        dispatch({ type: FETCH_TCIN_LIST_ERROR, payload: [], error: err })
      })
  }
}

//reset State
export const resetState = (state) => {
  return function (dispatch) {
    dispatch({ type: RESET_STATE, payload: state })
  }
}

export const updateTcinStatus = (data) => {
  return function (dispatch) {
    try {
      dispatch({ type: UPDATE_TCIN_STATUS, payload: data })
    } catch (err) {
      console.log('err: ', err)
    }
  }
}

// donwload files
export const downloadFile = (tcinId, projectId, succesReponse) => {
  return function (dispatch) {
    axios
      .get(
        `${apiConfig.nationalBrand.downloadFile}${tcinId}/${projectId}?${isTargetVendor}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: DOWNLOAD_FILE_ASSETS, payload: res.data })
          succesReponse(true, res)
        }
      })
      .catch((err) => {
        console.log(err)
        succesReponse(false)
      })
  }
}

//download file
export const fileUploaded = (projectId, success) => {
  return function (dispatch) {
    const url = apiConfig.nationalBrand.uploadHistory
    axios
      .get(`${url}${projectId}/GGA_PROJECT_ID?${isTargetVendor}`)
      .then((re) => {
        success(re)
      })
      .catch((err) => {
        success(err.message)
        console.log(err)
      })
  }
}

// verification of tcins
export const tcinsVerification = (tcins, projectId) => {
  return function (dispatch) {
    if (tcins.length < 1) {
      dispatch({
        type: VERIFICATION_TCIN_RESPONSE,
        payload: [],
        showVerificationList: false,
      })
      return false
    }
    const url = apiConfig.nationalBrand.validateTcins
    let postData = {
      tcins,
      project_uuid: projectId,
      ...isTargetVendorObj,
    }
    axios
      .post(url, postData)
      .then((res) => {
        dispatch({
          type: VERIFICATION_TCIN_RESPONSE,
          payload: res.data,
          showVerificationList: true,
        })
      })
      .catch((err) => {
        dispatch({ type: VERIFICATION_TCIN_FAILURE, payload: err })
      })
  }
}

//get user aggrement Info

export const getContractStatus = () => {
  return axios.get(`${apiConfig.nationalBrand.contract.get}?${isTargetVendor}`)
}

export const acceptTnc = (data) => {
  return axios.put(`${apiConfig.nationalBrand.contract.save}`, data)
}

//User Dashboard
export const nbrDashboard = (vendorId) => {
  return function (dispatch) {
    axios
      .post(apiConfig.nationalBrand.dashBoard, {
        vendor_id: vendorId,
        ...isTargetVendorObj,
      })
      .then((res) => {
        dispatch({ type: NBR_DASHBOARD_FETCH, payload: res.data })
      })
      .catch((err) => {
        dispatch({ type: 'FAIL_DASHBOARD_RES', payload: err })
      })
  }
}
export const fetchProjectDetails = (projectId) => {
  return (dispatch) => {
    axios
      .get(
        `${apiConfig?.nationalBrand?.fetchProjectDetails}/${projectId}?${isTargetVendor}`
      )
      .then((res) => {
        dispatch({
          type: FECTH_PROJECT_DETAILS,
          payload: res.data,
        })
      })
      .catch(() => {
        dispatch({
          type: FECTH_PROJECT_DETAILS,
          payload: {
            projectName: 'N/A',
            projectDate: '',
            status: null,
          },
        })
      })
  }
}
