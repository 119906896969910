export const VendorPipelineHeadCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'tcin',
    numeric: true,
    disablePadding: false,
    label: 'Tcin',
    sortable: true,
  },
  {
    id: 'prodDesc',
    numeric: false,
    disablePadding: false,
    label: 'Product Description',
    sortable: true,
  },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: 'Brand',
    sortable: true,
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: false,
    label: 'Vendor Name',
    sortable: true,
  },
  {
    id: 'lastUpdatedAt',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated By',
    sortable: true,
  },
  {
    id: 'dueDate',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
    sortable: true,
  },
  {
    id: 'launchDate',
    numeric: true,
    disablePadding: false,
    label: 'Launch Date',
    sortable: true,
  },
]

export const headCells = [
  {
    id: 'tcin',
    numeric: true,
    disablePadding: true,
    label: 'Tcin',
    sortable: false,
  },
  {
    id: 'project',
    numeric: true,
    disablePadding: false,
    label: 'Project',
    sortable: false,
  },
  {
    id: 'files',
    numeric: true,
    disablePadding: false,
    label: 'Files',
    sortable: false,
  },
  {
    id: 'taskstatus',
    numeric: true,
    disablePadding: false,
    label: 'Task Status',
    sortable: false,
  },
  {
    id: 'assigned_date',
    numeric: true,
    disablePadding: false,
    label: 'Assigned Date',
    sortable: true,
  },
  {
    id: 'priority',
    numeric: true,
    disablePadding: false,
    label: 'Priority',
    sortable: false,
  },
  {
    id: 'due_date',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
    sortable: true,
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
    sortable: false,
  },
  {
    id: 'itemtype',
    numeric: true,
    disablePadding: false,
    label: 'Item type',
    sortable: false,
  },
  {
    id: 'comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
    sortable: false,
  },
]

export const approvedHeadCells = [
  {
    id: 'tcin',
    numeric: true,
    disablePadding: true,
    label: 'Tcin',
    sortable: false,
  },
  {
    id: 'project',
    numeric: true,
    disablePadding: false,
    label: 'Project',
    sortable: false,
  },
  {
    id: 'files',
    numeric: true,
    disablePadding: false,
    label: 'Files',
    sortable: false,
  },
  {
    id: 'taskstatus',
    numeric: true,
    disablePadding: false,
    label: 'Task Status',
    sortable: false,
  },
  {
    id: 'updated_date',
    numeric: true,
    disablePadding: false,
    label: 'Approved Date',
    sortable: true,
  },
  {
    id: 'recount',
    numeric: true,
    disablePadding: false,
    label: 'No. of Rework',
    sortable: false,
  },
  {
    id: 'itemtype',
    numeric: true,
    disablePadding: false,
    label: 'Item type',
    sortable: false,
  },
  {
    id: 'comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
    sortable: false,
  },
]
