import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { TypoReuse } from './NbrStyles/NBrStyle'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { showNotification } from '../Notifications/NotificationActionCreator'
import { usePrevious } from '../../helpers/utils'
import {
  PostComments,
  fetchComments,
} from '../store/NationalBrand/ActionCreator'
import { IconButton } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { StyledChip } from './NBrTable'

const commentStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px',
  },
  title: {
    color: '#666',
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '20.5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: '15px',
  },
  commentTest: {
    color: '#333',
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '20.5px',
  },
}))

const NBrTcinDetailsModal = ({
  tcinId,
  open,
  setOpen,
  selectedRow,
  projectId,
}) => {
  const dispatch = useDispatch()
  const { tcinComments } = useSelector((state) => state.nationalBrand)
  const [comments, setComments] = useState('')
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const [commentLoader, setcommentLoader] = useState(true)
  const [getComments, setGetComments] = useState([])
  const prevID = usePrevious(tcinId)

  const styles = commentStyles()
  useEffect(() => {
    dispatch(fetchComments(tcinId, projectId))
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tcinId])

  useEffect(() => {
    if (Object.keys(tcinComments).length > 0) {
      setGetComments(tcinComments)
      setcommentLoader(false)
    }
    if (prevID !== tcinId) {
      setGetComments([])
      setcommentLoader(true)
    }
  }, [commentLoader, tcinComments, tcinId, selectedRow])

  const handleComments = (event) => {
    event.preventDefault()
    if (comments.length > 0 && nbrVendorConfirm && nbrVendorConfirm.vendor_id) {
      dispatch(PostComments(comments, tcinId, projectId))
    } else {
      dispatch(
        showNotification(true, 'Please Check Comments might be null', 'error')
      )
    }
    setComments('')
  }

  const handleCancelButton = () => {
    setComments('')
  }

  const formatDate = (date) =>
    new Date(date)
      .toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(/ /g, ' ')

  const tcinCommentCondiiton =
    tcinComments &&
    tcinComments.length > 0 &&
    tcinComments[0] !== 'empty' &&
    tcinComments[0] !== 'error'

  const status = selectedRow[0]?.production_phase?.filter(
    (task) => task.phase_id === 'RTA' || task.phase_id === 'AGENCY_RTA'
  )

  const statusName = status && status.length ? status[0].status_name : ''

  return (
    open && (
      <div className={open ? 'panelWrapOpen' : 'panelWrap'}>
        <IconButton onClick={() => setOpen(false)}>
          <Clear />
        </IconButton>
        <div className="taskPanel">
          <Typography className="typo-tcin">
            Tcin / {tcinId} / Overview
          </Typography>
          {/* /Tcin Details --------/ */}
          <Grid container className="panel-second">
            <Grid item xs={6}>
              <Grid item xs={12}>
                <TypoReuse
                  heading="TCIN added on"
                  subHeading={selectedRow[0].production_assigned_date}
                />
                <TypoReuse
                  heading="TCIN added by"
                  subHeading={selectedRow[0].vendor_name}
                />
                <TypoReuse
                  heading="RTA Status"
                  subHeading={<StyledChip label={statusName} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TypoReuse
                heading="Input Type:"
                subHeading={selectedRow[0].input_type}
              />
              {/* <TypoReuse heading="Additional URLs:" /> */}
            </Grid>
          </Grid>

          {/* <Grid container className="upload-modal-section ">
            <p className="panel-second-typo">Uploaded Files</p>
            {IsdownloadFile && repsonseDownload !== null ? (
              <NBrUploaded reponse={repsonseDownload.data} />
            ) : null}
          </Grid> */}

          {/* Comments */}
          <div className="comments-section">
            <p className="panel-second-typo">Comments</p>
            <form className="text-commnets" onSubmit={handleComments}>
              <TextField
                placeholder="Type your comments here"
                variant="outlined"
                id="full-width-text-field"
                fullWidth
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
              <Button type="submit">Post</Button>
              <Button
                type="button"
                onClick={handleCancelButton}
                className="comment-cancel"
              >
                Cancel
              </Button>
            </form>
            {commentLoader && <CircularProgress />}
            {tcinCommentCondiiton &&
              getComments.map((item, index) => {
                return (
                  <Box className={styles.wrapper} key={index}>
                    <span className={styles.title}>
                      {item.created_by} | {formatDate(item.created_at)}
                    </span>
                    <span className={styles.commentText}>{item.comments}</span>
                  </Box>
                )
              })}
          </div>
        </div>
      </div>
    )
  )
}
export default NBrTcinDetailsModal
