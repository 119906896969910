import {
  Asset,
  Anchors,
  Dimensions,
  Rotate,
  Script,
  THREE,
} from '@cgi/threescripts'

import { convertToInches } from '../../../components/Sandbox/Helpers'
import OrbitControls from './OrbitControls'
import { apiConfig } from './config'
const {
  AmbientLight,
  Box3,
  Color,
  DirectionalLight,
  DoubleSide,
  FrontSide,
  Object3D,
  PerspectiveCamera,
  Scene,
  GridHelper,
  AxesHelper,
  WebGLRenderer,
  Vector3,
} = THREE
export default class TScene {
  constructor(domElement) {
    this.domElement = domElement
    this.running = true
    this.object3D = null
    this.dimensions = null
    this.controls = null
    this.pivot = null
    Rotate.extend([new Object3D(), new Scene()])
    Script.extend([new Object3D(), new Scene()])
    this.bootstrapScene()
  }
  bootstrapScene() {
    this.scene = new Scene()
    this.scene.background = new Color(0xffffff)
    this.cameraFov = 45
    this.aspect = this.domElement.offsetWidth / this.domElement.offsetHeight
    this.camera = new PerspectiveCamera(this.cameraFov, this.aspect, 0.1, 1000)
    this.directionalLight = new DirectionalLight(0xffffff, 0.7)
    this.directionalLight.position.set(0, 6, 5)
    this.scene.add(this.directionalLight)
    this.ambientLight = new AmbientLight(0xffffff, 0.4)
    this.scene.add(this.ambientLight)
    this.renderer = new WebGLRenderer({ alpha: false, antialias: true })
    this.renderer.setClearColor(0xdfdfdf, 1)
    this.renderer.shadowMap.enabled = false
    this.renderer.setSize(
      this.domElement.offsetWidth,
      this.domElement.offsetHeight
    )
    this.anchors = new Anchors({
      onUpdate: (anchor) => this.onAnchors(anchor),
    })

    this.scene.attach(this.anchors)
    this.containerObj = new Object3D()
    this.scene.add(this.containerObj)
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.screenSpacePanning = true
    this.camera.position.set(0, 20, 100)
    this.controls.addEventListener('change', () => {
      this.directionalLight.position.copy(this.camera.position)
    })
    this.controls.update()

    this.domElement.append(this.renderer.domElement)
    this.renderLoop()
  }
  stop() {
    this.running = false
    this.anchors.enabled = false
    cancelAnimationFrame(this.requestId)
  }
  setShadows(val) {
    this.directionalLight.castShadow = val
  }
  renderLoop() {
    if (!this.running) return
    this.requestId = requestAnimationFrame(() => this.renderLoop())
    this.renderer.render(this.scene, this.camera)
  }
  rotateIfNeeded() {
    //   const size = new Box3().expandByObject(this.object3D).getSize()
    //   const height = size.y
    //   const max = Math.max(size.x, size.z)
    //   const ratio = height / max
    //   if (ratio > 0.45) return
    //   this.object3D.rotation.x = Math.PI / 8
    //   this.rotate.phi = Math.PI / 8
  }
  resize() {
    const width = this.domElement.offsetWidth
    const height = this.domElement.offsetHeight
    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }
  setAlphaTest(val, materialName, meshNames, isOBJAsset) {
    if (!this.object3D) return
    this.object3D.getMeshes().forEach((mesh) => {
      if (isOBJAsset && !mesh.name.includes('Dimension')) {
        meshNames.forEach((name) => {
          if (name === mesh.name) {
            mesh.material.alphaTest = val === 'null' ? 0 : val
            mesh.material.needsUpdate = true
          }
        })
      } else if (
        !mesh.name.includes('Dimension') &&
        mesh.material.name === materialName
      ) {
        mesh.material.alphaTest = val === 'null' ? 0 : val
        mesh.material.needsUpdate = true
      }
    })
  }

  setAlphaType(val, materialName, meshNames, isOBJAsset) {
    if (!this.object3D) return
    this.object3D.getMeshes().forEach((mesh) => {
      if (isOBJAsset && !mesh.name.includes('Dimension')) {
        meshNames.forEach((name) => {
          if (name === mesh.name) {
            if (val === 'BLEND') {
              mesh.material.transparent = true
              mesh.material.depthWrite = false
            }
            if (val === 'OPAQUE') {
              mesh.material.transparent = false
              mesh.material.depthWrite = true // default
            }
            if (val === 'MASK') {
              mesh.material.transparent = false
              mesh.material.depthWrite = true // default
              mesh.material.alphaTest =
                mesh.material.alphaTest !== undefined
                  ? mesh.material.alphaTest
                  : 0.5
            }
            mesh.material.needsUpdate = true
          }
        })
      }
    })
  }

  setWireframe(val) {
    this.object3D.traverse((mesh) => {
      if (mesh.type !== 'Mesh' || mesh.name.includes('Dimension')) return
      mesh.material.wireframe = val
    })
  }
  setInjector(lamda) {
    Asset.setInjector((url) => lamda(url))
  }

  setSided(val, materialName, meshNames, isOBJAsset) {
    const sided = val ? DoubleSide : FrontSide
    this.object3D.getMeshes().forEach((mesh) => {
      if (isOBJAsset && !mesh.name.includes('Dimension')) {
        meshNames.forEach((name) => {
          if (name === mesh.name) {
            mesh.material.side = sided
            mesh.material.needsUpdate = true
          }
        })
      } else if (
        !mesh.name.includes('Dimension') &&
        mesh.material.name === materialName
      ) {
        mesh.material.side = sided
        mesh.material.needsUpdate = true
      }
    })
  }
  onAnchors(anchor) {
    const el = document.getElementById(anchor.name)
    if (!el) return
    if (anchor.text !== null) {
      const text = isNaN(anchor.text) ? anchor.text : anchor.text.toFixed(2)
      if (el.innerText !== text) el.innerText = text
    }
    el.style.opacity = anchor.visible ? 1 : 0
    el.style.transform = `translate3d(${anchor.left}px, ${anchor.top}px, 0)`
  }
  toggleGrid(val) {
    if (this.gridObj) {
      this.gridObj.visible = val
    }
  }
  dispose() {
    cancelAnimationFrame(this.requestId)
    this.scene.detach()
    this.remove()
    while (this.scene.children.length > 0) {
      const child = this.scene.children[0]
      this.scene.remove(child)
      Asset.dispose(child)
    }
    this.domElement.removeChild(this.renderer.domElement)
    this.renderer.forceContextLoss()
    this.renderer.context = null
    this.renderer.domElement = null
    this.renderer = null
    this.domElement = null
    this.scene = null
    this.camera = null
    this.directionalLight = null
    this.ambientLight = null
    this.anchors = null
    this.dimensions = null
  }
  remove() {
    if (!this.object3D) return
    this.object3D.detach()
    this.scene.remove(this.object3D)
    Asset.dispose(this.object3D)
    this.object3D = undefined
  }
  addCenteredParent(object) {
    const box = new Box3()
    box.expandByObject(object)
    // object.position.y = -box.getCenter().y
    this.gridObj = new GridHelper(
      Math.max(box.getSize().x, box.getSize().z) + 40,
      40,
      0xfefefe,
      0xf0f0f0
    )
    this.gridObj.position.set(0, box.min.y - 0.5, 0)
    this.scene.add(this.gridObj)
    const pivot = new Object3D()
    pivot.add(object)
    return pivot
  }
  add(urls, isDrag, siblings, inInches) {
    this.isGlbGltf = false
    this.inInches = inInches
    if (this.object3D) this.remove()
    // reset to defaults
    // this.renderer.gammaFactor = 2.0
    this.renderer.gammaOutput = false

    // map env keys to environment key
    urls.apiKey = apiConfig.apiKey
    // urls.env = urls.diffuse
    urls.isDrag = isDrag

    var accessToken = localStorage.getItem('access_token')
    var idToken = localStorage.getItem('id_token')
    Asset.setRequestHeader({
      Authorization: accessToken,
      'x-api-key': apiConfig.apiKey,
      'x-id-token': idToken,
    })

    return Asset.fetch(urls).then((object) => {
      object.traverse((node) => {
        if (!node.type.toLowerCase().includes('light')) return
        node.parent.remove(node)
      })
      if (!inInches && this.isGlbGltf) {
        object.scale.multiplyScalar(convertToInches(1))
      }
      const pivot = new Object3D()
      pivot.name = 'cs-origin-point'
      pivot.text = null
      object.add(pivot)
      this.anchors.add(pivot)

      this.addPivot(object)

      const asset = this.addCenteredParent(object)
      this.object3D = this.containerObj
      this.dimensions = new Dimensions({
        anchors: this.anchors,
        color: 0x666666,
      })
      asset.attach(this.dimensions)

      this.containerObj.add(asset)
      this.fitAssetToCamera(asset)
      this.scene.add(this.containerObj)
      this.resize()
    })
  }

  addPivot() {
    this.pivot = new AxesHelper(15)

    var colors = this.pivot.geometry.attributes.color

    colors.setXYZ(0, 180, 0, 0)
    colors.setXYZ(1, 180, 0, 0) // x
    colors.setXYZ(2, 0, 180, 0)
    colors.setXYZ(3, 0, 180, 0) // y
    colors.setXYZ(4, 0, 0, 180)
    colors.setXYZ(5, 0, 0, 180) // z

    this.scene.add(this.pivot)
  }

  fitAssetToCamera(asset) {
    const box = new Box3()
    box.expandByObject(asset)

    const fov = this.camera.fov * (Math.PI / 180)
    var size = box.getSize()
    var objectSize = Math.max(size.x, size.z + size.z / 12)
    const height = size.y
    const max = Math.max(size.x, size.z)
    const ratio = height / max
    let xposition = 0
    if (ratio < 0.45) {
      xposition = 4
    }

    this.camera.position.set(
      0,
      box.getCenter().y + xposition,
      Math.abs(objectSize / Math.sin(fov / 2)) - xposition
    )
    this.controls.minDistance = objectSize / 1.6
    this.controls.maxDistance = objectSize * 15
    this.controls.target = new Vector3(0, box.getCenter().y, 0)
    this.controls.update()
  }
  togglePivot(value) {
    if (this.pivot instanceof THREE.Object3D) {
      this.pivot.visible = value
    }
  }
  setDimensions(val) {
    this.dimensions.meshX.material.opacity = val ? 1 : 0
    this.dimensions.meshY.material.opacity = val ? 1 : 0
    this.dimensions.meshZ.material.opacity = val ? 1 : 0
    this.dimensions.meshX.visible = val
    this.dimensions.meshY.visible = val
    // causes alpha rendering error - will need to look at in future version of threejs
    this.dimensions.meshZ.visible = val
  }

  getMaterials() {
    return this.object3D.getMeshes().map((mesh) => {
      return { matName: mesh.material.name, meshName: mesh.name }
    })
  }
}
