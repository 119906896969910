export const convertToInches = (val) => val * 39.3701

export const formatBytes = function (bytes, decimals = 2) {
  if (!bytes) {
    return ''
  }
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

//used to show date in proper manner
export const alerterDate = (newValue) => {
  let newDatePeriod = ''
  if (newValue) {
    var dd = newValue.getDate()
    var mm = newValue.getMonth() + 1

    var yyyy = newValue.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return dd + '/' + mm + '/' + yyyy
  }
  return newDatePeriod
}

export const populateFiles = (fileList = {}) => {
  let files = []
  Object.entries(fileList).forEach(([i, det]) => {
    files.push({ name: i, assets: [], type: 'header' })
    Object.entries(det).forEach(([name, url]) => {
      files.map((file) =>
        file && file.name === i
          ? file.assets.push({ type: 'file', name, url, variation: i })
          : file
      )
    })
  })
  let filesData = files
  if (files.length === 1) {
    filesData[0].assets = files[0].assets.sort((a, b) =>
      a.name === b.name ? 0 : a.name < b.name ? -1 : 1
    )
  } else {
    filesData = files.sort((a, b) => {
      a.assets.sort((c, d) =>
        c.name === d.name ? 0 : c.name < d.name ? -1 : 1
      )
      b.assets.sort((c, d) =>
        c.name === d.name ? 0 : c.name < d.name ? -1 : 1
      )
      return a.name === b.name ? 0 : a.name < b.name ? -1 : 1
    })
  }
  return filesData
}
