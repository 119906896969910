import { useState, useCallback, useEffect } from 'react'
import { Box, Breadcrumbs, Typography, Button } from '@material-ui/core'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { StyledHeader } from '../Task/TaskStyle'
//Components
import SearchInput from '../../components/SearchInput'
import NBrHeader from './NBrHeader'
import NBrTable from './NBrTable'
import NBrAddTcin from './NBrAddTcin'
import NBrAddAssets from './NBrAddAssets'
// style
import { useStyles } from './NbrStyles/NBrStyle'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProjectDetails } from '../store/NationalBrand/ActionCreator'
import { getFormattedDate } from '../../helpers/utils'
import { Add, ArrowUpwardOutlined } from '@material-ui/icons'

export const NBrProjectDetails = (props) => {
  const dispatch = useDispatch()
  // const { projectList } = useSelector((state) => state.nationalBrand)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
  const [addTcin, setAddTcin] = useState(false)
  const [addAssests, setAddAssets] = useState(false)
  const [refreshList, setRefreshList] = useState(null)
  const { totalTcins } = useSelector((state) => state.nationalBrand)
  const { projectId } = useParams()
  const styles = useStyles()

  const tab = new URLSearchParams(props?.history?.location?.search).get(
    'activeTab'
  )

  const { projectDetails } = useSelector((state) => state.nationalBrand)

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value)
    debounceHandleSearch(value)
  }

  // eslint-disable-next-line
  const debounceHandleSearch = useCallback(
    _.debounce((value) => {
      setDebouncedSearchValue(value)
    }, 1000),
    []
  )

  useEffect(() => {
    dispatch(fetchProjectDetails(projectId))
    //eslint-disable-next-line
  }, [])

  const fetchList = () => {
    setRefreshList((fecth) => !fecth)
  }

  const HEADER_DETAILS = [
    {
      label: 'Project name',
      value: projectDetails.project_name,
    },
    {
      label: 'Created date',
      value: getFormattedDate(projectDetails.project_create_date),
    },
    {
      label: 'Total TCINs',
      value: totalTcins,
    },
  ]

  const getCurrentTab = (newTab, isFirst) => {
    if (newTab !== tab && !isFirst) {
      props.history.push(`/projects?activeTab=${newTab}`)
    }
  }

  return (
    <Box className={styles.backGroundBox}>
      <NBrHeader getCurrentTab={getCurrentTab} activeTab={tab} />
      <Box className={styles.headerWrapper}>
        <StyledHeader className={styles.header}>
          <Box className={styles.taskBarBox}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item xs={6}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  style={{ marginBottom: '20px' }}
                >
                  <Typography
                    color="textPrimary"
                    onClick={() => {
                      props.history.push(
                        '/projects?activeTab=' + projectDetails.status
                      )
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {projectDetails.status === 'CARRY_FORWARD'
                      ? 'CARRY FORWARD'
                      : projectDetails.status}
                  </Typography>
                  <Typography color="inherit">
                    {projectDetails.project_name || 'N/A'}
                  </Typography>
                </Breadcrumbs>

                <Box className={styles.headerDetailsWrapper}>
                  {HEADER_DETAILS.map((header) => (
                    <Box className={styles.headerDetails} key={header.label}>
                      <Typography className={styles.headerLabel}>
                        {header.label}:
                      </Typography>
                      <Typography
                        className={[
                          styles.headerLabel,
                          styles.headerValue,
                        ].join(' ')}
                      >
                        {header.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'end',
                }}
              >
                <SearchInput
                  searchValue={searchValue}
                  handleChange={handleSearch}
                />
                <Box className={styles.actionButtons}>
                  <Button
                    className={styles.addTcinDetails}
                    onClick={() => {
                      setAddTcin(true)
                    }}
                    style={{ textTransform: 'none' }}
                    disabled={projectDetails.status !== 'ACTIVE'}
                  >
                    <Add />
                    Add TCINs
                  </Button>
                  <Button
                    className={`${styles.addTcinDetails}  ${styles.uploadAssets}`}
                    onClick={() => {
                      setAddAssets(true)
                    }}
                    style={{ textTransform: 'none', marginLeft: '10px' }}
                    disabled={
                      totalTcins === 0 || projectDetails.status === 'COMPLETED'
                    }
                  >
                    <ArrowUpwardOutlined />
                    Upload Assets
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </StyledHeader>
      </Box>

      <Box>
        <NBrTable
          projectId={projectId}
          searchValue={debouncedSearchValue}
          refreshList={refreshList}
        />
      </Box>

      <NBrAddTcin
        openAddTcin={addTcin}
        setAddTcinModal={setAddTcin}
        title={projectDetails.project_name}
        projectId={projectId}
        fetchList={fetchList}
      />
      {addAssests && (
        <NBrAddAssets
          openAddAssets={addAssests}
          setAddAssetsModal={setAddAssets}
          title={projectDetails.project_name}
          projectId={projectId}
          fetchList={fetchList}
        />
      )}
    </Box>
  )
}
