import React from 'react'
import { withStyles } from '@material-ui/core'
import classnames from 'classnames'

const style = (theme) => ({
  root: {
    margin: 10,
    height: 75,
  },
  text: {
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
  },
  tagRoot: {
    border: '1px solid #ccc',
    padding: '0.5rem',
    margin: 10,
    textAlign: 'center',
  },
  hover: {
    '&:hover': {
      backgroundColor: '#CC0000',
      color: '#fff',
      cursor: 'pointer',
    },
  },
})

class Tag extends React.Component {
  render() {
    const { classes, tag = {}, currentUser, deleteTag } = this.props
    const isUser = currentUser === tag.user
    return (
      <div
        className={classnames(classes.tagRoot, { [classes.hover]: isUser })}
        onClick={(e) => deleteTag(e, tag)}
      >
        <div className={classes.text}>{tag.tag}</div>
        <div>
          {tag.user && <div>{tag.user}</div>}
          {tag.time && <div>{new Date(tag.time).toLocaleString()}</div>}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(Tag)
