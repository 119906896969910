/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Notifications from '../Notifications/Notifications'
import TaskLanding from '../Task/TaskLanding'
import VendorPipelineLanding from '../Vendor/VendorPipelineLanding'
import NotFound from '../../components/NotFoundPage/NotFoundPage'
import Auth, { isTPlus } from '../Auth/Auth'
import { MainContent } from './LayoutStyle'
import { TaskContextProvider } from '../Task/TaskContext'
import { VendorContextProvider } from '../Vendor/VendorContext.js'
import LayoutHeader from './LayoutHeader'
import { signInSuccess } from '../Auth/AuthActionCreator'
import NBrProjectList from '../NationalBrand/NBrProjectList'
import { NBrProjectDetails } from '../NationalBrand/NBrProjectDetails'
//Dashboard
import NBrDashboard from '../NationalBrand/NBrDashboard/NBrDashboard'
import { vendorInfo } from '../store/NationalBrand/ActionCreator'
import LegalWarningModal from '../NationalBrand/NbrLegalWarning'
import { INTIAL_STATE_TYPE } from '../store/NationalBrand/ActionType.js'
import { ACCESS_DENIED_URL } from '../../config/apiConfig.js'
const drawerWidth = 100

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  // },
  // toolbar: {
  //   paddingRight: 24, // keep right padding when drawer closed
  // },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      background: '#fff',
      color: '#333',
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: '#fff',
      color: '#333',
      // height: '95px',
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: '#fff',
      color: '#333',
      // height: '95px',
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    background: '#fff',
    color: '#333',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#AC0000',
    color: '#fff',
  },
  navLink: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    color: '#fff',
    // '&:hover': {
    //   backgroundColor: 'rgba(213,220,224,0.3)',
    // },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  layoutHeader: {
    color: '#ffffff',
    backgroundColor: '#AC0000',
    height: '112px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  caution: {
    backgroundColor: '#eee',
    border: '1px solid #999',
    padding: '20px',
  },
  margin: {
    margin: '8px 8px 8px 0',
  },
}))

const Layout = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { nbrVendorConfirm } = useSelector((state) => state.nationalBrand)
  const { session, location, isAuthorized, logout } = props
  const [userAccess, setUserAccess] = useState(false)
  useEffect(() => {
    if (session.userInfo.accessToken) {
      dispatch(signInSuccess(session.userInfo))
      dispatch(vendorInfo(session.userInfo.email))
    }
  }, [dispatch, location, session, isAuthorized])

  useEffect(() => {
    if (
      isTPlus &&
      !nbrVendorConfirm.nbrVendorAPIStatus &&
      nbrVendorConfirm.nbrVendorAPIStatus !== INTIAL_STATE_TYPE
    ) {
      //Non authorized user
      window.location.href = ACCESS_DENIED_URL
    } else if (nbrVendorConfirm && nbrVendorConfirm.vendor_id !== undefined) {
      setUserAccess(true)
    }
  }, [nbrVendorConfirm])

  return (
    <div className={classes.root}>
      <Helmet defaultTitle="Gargantua" titleTemplate="%s - Gargantua" />
      <Auth
        accessToken={session && session.userInfo.accessToken}
        session={session}
        {...props}
      />
      <Notifications />
      <CssBaseline />
      <LayoutHeader
        logout={logout}
        {...props}
        nbrVendorConfirm={userAccess === true || isTPlus}
      />
      <MainContent>
        <Switch>
          {!isTPlus ? (
            <Route
              exact
              path="/"
              render={(props) => (
                <TaskContextProvider>
                  <TaskLanding {...props} />
                </TaskContextProvider>
              )}
            />
          ) : null}
          {!isTPlus ? (
            <Route
              exact
              path="/pipeline"
              render={(props) => (
                <VendorContextProvider>
                  <VendorPipelineLanding {...props} />
                </VendorContextProvider>
              )}
            />
          ) : null}

          {isTPlus ? (
            <Route exact path="/" render={() => <Redirect to="/projects" />} />
          ) : null}

          {/* /National Brand route /  */}
          {userAccess === true || isTPlus ? (
            <Route
              exact
              path="/projects"
              render={(props) => <NBrProjectList {...props} />}
            />
          ) : null}
          {userAccess === true || isTPlus ? (
            <Route
              exact
              path="/projects/:projectId"
              render={(props) => <NBrProjectDetails {...props} />}
            />
          ) : null}

          {/* /National Brand Route / */}
          {userAccess === true || isTPlus ? (
            <Route exact path="/dashboard" render={() => <NBrDashboard />} />
          ) : null}

          <Route component={NotFound} />
        </Switch>
      </MainContent>
      {isTPlus && <LegalWarningModal logout={logout} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    displayName: state.auth.displayName,
    company: state.auth.company,
    isSignedIn: state.auth.isAuthorized,
  }
}

export default connect(mapStateToProps)(Layout)

// const { window } = props
// const [anchorEl, setAnchorEl] = useState(null)

// const isMenuOpen = Boolean(anchorEl)
// const handleProfileMenuOpen = (event) => {
//   setAnchorEl(event.currentTarget)
// }

// const handleMenuClose = () => {
//   setAnchorEl(null)
// }

// const menuId = 'primary-search-account-menu'

// const container =
//   window !== undefined ? () => window().document.body : undefined
// const renderMenu = (
//   <Menu
//     anchorEl={anchorEl}
//     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//     id={menuId}
//     keepMounted
//     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//     open={isMenuOpen}
//     onClose={handleMenuClose}
//   >
//     <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
//     <MenuItem onClick={handleMenuClose}>My account</MenuItem>
//   </Menu>
// )

// const tabsMetaData = [
//   {
//     statusId: 'TASK',
//     serverId: '',
//     label: 'Tasks',
//     status: 'Tasks',
//   },
//   {
//     statusId: 'PIPELINE',
//     serverId: '',
//     label: 'Pipeline',
//     status: 'Pipeline',
//   },
// ]
