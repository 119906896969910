/* eslint-disable */
import { AuthProvider } from '@praxis/component-auth'
import { signIn, signOut } from './AuthActionCreator'
import { apiConfig } from '../../config/index'
import { connect } from 'react-redux'
import React from 'react'
import Interceptor from './Interceptor'
import { getRedirectUrl } from '../../helpers/utils'
import { authConfig } from '../../constants/config'

const host = window.location.hostname
export const isTPlus = host.includes(apiConfig?.auth_tplus?.appUrl)

export const Auth = () => {
  const accessToken = localStorage.getItem('access_token')
  let config = { ...authConfig }
  config.authorizationUrl = isTPlus
    ? apiConfig.auth_tplus.host + apiConfig.auth_tplus.authorizationPath
    : apiConfig.auth.host + apiConfig.auth.authorizationPath
  config.logoutUrl = isTPlus
    ? getRedirectUrl() || apiConfig.auth_tplus.tplusUrl
    : `${apiConfig.auth.logoutHost}${apiConfig.auth.logoutPath}`

  return (
    <div>
      <AuthProvider {...config} />
      <Interceptor accessToken={accessToken} />
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth: auth,
  popupType: auth.popupType,
  popupCount: auth.popupCount,
  isAuthorized: auth.isAuthorized,
})

const mapDispatchToProps = {
  signIn,
  signOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
