const DefaultState = {
  ERR_FILENAME: 'Filename must use TCIN naming conventions.',
  ERR_FILEFORMAT: 'File type must be .jpg or .jpeg. or .png or .tif or .psd',
  ERR_FILESIZESMALL: 'File size must be at least 5kB.',
  ERR_FILESIZEBIG: 'File size cannot exceed 100MB.',
  ERR_FILEDUPLICATE: 'File with this name has already been added.',
  ERR_DOUBLEPERIOD: 'This filename has more than one period.',
  MSG_NO_VARIATIONS: 'No variation parents match the conditions.',
  MSG_SAVE_RULE: 'Rule saved successfully.',
  MSG_SAVE_FILTER: 'Search saved successfully.',
  MSG_DELETE_FILTER: 'Saved search successfully deleted.',
  MSG_DELETE_RULE: 'Rule deleted successfully.',
  MSG_PUBLISH_ITEM_IMAGES:
    'Changes saved successfully and may take up to 30 minutes to be published.',
  MSG_PUBLISH_SYNC: 'Item sync in progress.',
  MSG_IMAGE_REVIEW: 'Image review saved successfully.',
  MSG_IMAGE_REVIEW_UPDATE: 'Image review successfully updated.',
  MSG_MAX_ALLOWED_TCINS: 'Maximum TCINS allowed are ',
  MSG_UNSAVED_DIALOG:
    'You have unsaved changes. Do you want to leave this page and discard your changes or stay?',
  MSG_VIDEO_UPLOAD: 'Video group uploaded successfully.',
  MSG_VIDEO_UPLOAD_IN_PROGRESS:
    'File uploads are currently in progress and will be lost if you leave this page now. Are you sure you want to leave?',
  MSG_COPY_ADD: 'Copy added to items successfully.',
  MSG_COPY_REMOVE: 'Copy removed from items successfully.',
  ERR_SAVE_RULE:
    'Unable to save rule. Please contact support if this issue persists.',
  ERR_SAVE_FILTER: 'Unable to save search. ',
  ERR_UPDATE_RULE:
    'Unable to update rule. Please contact support if this issue persists.',
  ERR_DELETE_RULE:
    'Unable to delete rule. Please contact support if this issue persists.',
  ERR_LOGIN: 'The LAN ID or Password you entered is incorrect.',
  ERR_PUBLISH_ITEM_IMAGES:
    'Unable to publish changes at this time. Please contact support if this issue persists.',
  ERR_ITEM_IMAGES_SEARCH:
    'Error searching for TCINs. Please contact support if this issue persists.',
  ERR_GENERIC:
    'An error has occurred. Please contact support if this issue persists.',
  ERR_INVALID_FILTERS:
    "You've attempted to add an invalid filter. Please try again or contact support.",
  ERR_VIDEO_UPLOAD:
    'Video group failed to upload. Please contact support if this issue persists.',
  ERR_IMG_FILENAME: 'Filename must use TCIN naming conventions.',
  ERR_IMG_FILEFORMAT: 'File type must be .jpg or .jpeg.',
  ERR_IMG_FILESIZESMALL: 'File size must be at least 5kB.',
  ERR_IMG_FILESIZEBIG: 'File size cannot exceed 100MB.',
  ERR_IMG_FILEDUPLICATE: 'File with this name has already been added.',
  WARN_PERMISSIONS: 'Your permissions do not allow you to edit to this content',
  WARN_DUPLICATE_FILTERS:
    "You've attempted to add duplicate filters. The duplicate filters have been removed.",
  WARN_MAX_FILTERS:
    'Max number of allowed filters for this type was reached and some filters were not applied.  Max: ',
  MSG_CLIPBOARD_SUCCESS: 'Copied.',
  MSG_CLIPBOARD_FAILURE: 'Copy link not available.',
  MSG_DELETED_FILE: 'File deleted successfully.',
  IMG_REJECTION_REASONS: [
    'Not a photo',
    'Poor quality',
    'Text on image',
    'Style guide violation',
    'Does not match item description',
    'Other',
  ],
}
export { DefaultState }
